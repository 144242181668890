import { Routes, Route } from "react-router-dom";
import { Login } from "../pages/auth/login";
import { LoginSupportUser } from "../pages/auth/loginSupportUser";
import { RecoverPassword } from "../pages/auth/recoverPassword";
import { ChangePasswordFT } from "../pages/auth/changePasswordFT";
import { ValidateCredentials } from "../pages/auth/validateCredentials";
import ProtectedRoute from "./protectedRoutes";
import { AdminHome } from "../pages/admin/home";

import Layout from "../components/layout/layout";

import { ListUsers } from "../pages/admin/listUsers";

import { UserDetail } from "../pages/admin/userDetail";
import ModalCreateOptions from "../components/common/modalCreateOptions";
import ModalEditOptions from "../components/common/modalEditOptions";

import { ListMenuOptions } from "../pages/admin/listMenuOptions";
import Dashboard from "../pages/admin/dashboard";
import NotFound from "../pages/common/notfound";
import { ListRoles } from "../pages/admin/listRoles";
import { HomeNewCharts } from "../pages/user/homeNewCharts";
import { BillFiscalCEN } from "../pages/user/fiscalCEN/billFiscalCEN";
import { LoadingPage } from "../pages/common/loading";
import { PayrollFiscalCEN } from "../pages/user/fiscalCEN/payrollFiscalCEN";
import AdminGeographies from "../pages/admin/geographies/adminGeographies";
import CreateGeography from "../pages/admin/geographies/CRUD/createGeography";
import { SalesForce } from "../pages/user/salesForce/salesForce";
import CatalogueUnified from "../pages/user/catalogue/catalogueUnified";
import SalesIndicators from "../pages/user/accessSalesIndicators/salesIndicators";
import { Stores } from "../pages/user/stores/stores";
import { Masters } from "../pages/user/masters/masters";
import { CreateDynamicPeriodicReport } from "../pages/user/dynamicPeriodicReport/createDynamicPeriodicReport";
import { ListDynamicPeriodicReports } from "../pages/user/dynamicPeriodicReport/listDynamicPeriodicReports";
import InventoriesIndicators from "../pages/user/inventoriesIndicators/inventoriesIndicators";
import AccessReturnsIndicators from "../pages/user/inventoriesIndicators/returnsIndicators";
import AccessBudgetIndicators from "../pages/user/inventoriesIndicators/budgetIndicators";
import AdminNotifications from "../pages/user/notifications/adminNotifications";
import DeleteAndReload from "../pages/user/deleteAndReload/deleteAndReload";
import { EditDynamicPeriodicReport } from "../pages/user/dynamicPeriodicReport/editDynamicPeriodicReport";
import ApplicationDeleteAndReload from "../pages/user/deleteAndReload/applicationDeleteAndReload";
import AdminCompanies from "../pages/admin/adminCompanies";
import AdminBusinessRules from "../pages/user/businessRules/adminBusinessRules";
import AdminIndicators from "../pages/user/indicators/adminIndicators";
import AdminSpecialFilesClient from "../pages/user/specialFiles/adminSpecialFiles";
import CreateSpecialFileClient from "../pages/user/specialFiles/createSpecialFile";
import AdminSpecialFilesAdmin from "../pages/admin/adminSpecialFiles";
import EditSpecialFileClient from "../pages/user/specialFiles/editSpecialFile";
import ConsultRoles from "../pages/user/adminUsers/consultRoles";
import AdminClientUsers from "../pages/user/adminUsers/adminClientUsers";
import AdminIndicatorCards from "../pages/admin/adminIndicatorCards";
import AdminContacts from "../pages/user/contacts/adminContacts";
import AdminTransmittedValue from "../pages/admin/adminTransmittedValue";
import ManageBusinessGroup from "../pages/admin/manageBusinessGroup/manageBusinessGroup";
import AdminSendAllies from "../pages/admin/adminSendAllies";
import ReloadAllies from "../pages/user/allies/reloadAllies";
import RequestAllies from "../pages/user/allies/requestAllies";
import BillIndicators from "../pages/user/inventoriesIndicators/billIndicators";
import PayrollIndicators from "../pages/user/inventoriesIndicators/payrollIndicators";
import RateMaster from "../pages/user/rateMaster/rateMaster";
import UnifiedCatalogTraders from "../pages/user/traders/catalog/unifiedCatalog";
import UnifiedMastersTraders from "../pages/user/traders/masters/unifiedMasters";
import FillRateIndicator from "../pages/user/inventoriesIndicators/fillRateIndicator";
import RequestsPurchaseOrders from "../pages/user/purchaseOrders/requestsPurchaseOrders";
import AdminPurchaseOrders from "../pages/user/purchaseOrders/adminPurchaseOrders";
import AdminPurchaseOrderCommerce from "../pages/user/purchaseOrders/adminPurchaseOrderCommerce";
import ExternalFiles from "../pages/user/externalFiles/externalFiles";
import FieldLoadMapping from "../pages/user/loadMapping/FieldLoadMapping";
import TraceabilityExternal from "../pages/user/traceabilityExternal/traceabilityExternal";
import { EmissionCENFiscalHome } from "../pages/user/cenFiscalCO/emission/emissionCENFiscalHome";
import { ReceptionCENFiscalHome } from "../pages/user/cenFiscalCO/reception/receptionCENFiscalHome";
import EmissionIndicators from "../pages/user/cenFiscalCO/emission/emissionIndicators";
import ReceptionIndicators from "../pages/user/cenFiscalCO/reception/receptionIndicators";
import HelpCenter from "../pages/common/helpCenter";
import AdminReprocess from "../pages/user/reprocess/adminReprocess";
import ListPeriodicReportCEN from "../pages/user/periodicReportCEN/listPeriodicReportCEN";
import CreatePeriodicReportCEN from "../pages/user/periodicReportCEN/createPeriodicReportCEN";
import EditPeriodicReportCEN from "../pages/user/periodicReportCEN/editPeriodicReportCEN";
import RequestBILayers from "../pages/user/BILayer/requestBILayer";
import { BILayers } from "../pages/user/BILayer/BILayers";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/support" element={<LoginSupportUser />} />
      <Route path="/recoverPassword" element={<RecoverPassword />} />
      <Route path="/changePasswordFT" element={<ChangePasswordFT />} />

      <Route
        path="/validateCredentials/email/:email"
        element={<ValidateCredentials />}
      />

      {/* Modales */}
      <Route path="/modalCreateOptions" element={<ModalCreateOptions />} />
      <Route path="/modalEditOptions" element={<ModalEditOptions />} />

      {/** Help Center */}
      <Route path="user" element={<Layout />}>
        <Route path="helpCenter" element={<HelpCenter />} />
      </Route>

      <Route path="admin" element={<Layout />}>
        <Route path="helpCenter" element={<HelpCenter />} />
      </Route>

      {/* Admin Routes */}

      <Route element={<ProtectedRoute requiredRole={"admin"} />}>
      
        {/* <Route path="/support" element={<LoginSupportUser />} /> */}

        <Route path="admin" element={<Layout />}>
          <Route path="home" element={<AdminHome />} />
          <Route path="helpCenter" element={<HelpCenter />} />

          <Route path="listUsers" element={<ListUsers />} />
          <Route
            path="listUsers/:country/:companyId"
            element={<UserDetail />}
          />
          <Route path="listMenuOptions" element={<ListMenuOptions />} />
          <Route path="listRoles" element={<ListRoles />} />
          <Route path="adminGeographies" element={<AdminGeographies />} />
          <Route path="uploadGeography" element={<CreateGeography />} />
          <Route path="adminCompanies" element={<AdminCompanies />} />
          <Route path="stores" element={<Stores />} />
          <Route path="loadMapping" element={<FieldLoadMapping />} />
          <Route
            path="adminSpecialFiles"
            element={<AdminSpecialFilesAdmin />}
          />
          <Route
            path="adminSpecialFilesClient"
            element={<AdminSpecialFilesClient />}
          />
          <Route path="deleteAndReload" element={<DeleteAndReload />} />
          <Route
            path="deleteAndReload/request"
            element={<ApplicationDeleteAndReload />}
          />
          <Route path="adminIndicatorCards" element={<AdminIndicatorCards />} />
          <Route
            path="adminTransmittedValue"
            element={<AdminTransmittedValue />}
          />
          <Route path="adminBusinessRules" element={<AdminBusinessRules />} />
          <Route path="adminSendAllies" element={<AdminSendAllies />} />
          <Route path="manageBusinessGroup" element={<ManageBusinessGroup />} />
          <Route path="adminIndicators" element={<AdminIndicators />} />
          <Route path="reloadAllies" element={<ReloadAllies />} />
          <Route path="reloadAllies/request" element={<RequestAllies />} />
          <Route path="adminReprocess" element={<AdminReprocess />} />
          <Route path="BILayers/request" element={<RequestBILayers />} />
          <Route path="BILayers" element={<BILayers />} />
        </Route>
      </Route>

      {/* AdminCTYS Routes */}

      {/* User Route home */}

      <Route element={<ProtectedRoute requiredRole={"user"} path="home" />}>
        <Route path="user" element={<Layout />}>
          <Route path="home" element={<HomeNewCharts />} />
        </Route>
      </Route>

      {/* User Route home CEN Bill */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="homeBillCEN" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="homeBillCEN" element={<BillFiscalCEN />} />
        </Route>
      </Route>

      {/* User Route home CEN Payroll */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="homePayrollCEN" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="homePayrollCEN" element={<PayrollFiscalCEN />} />
        </Route>
      </Route>

      {/* User Route home CEN Emission */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="homeEmissionCEN" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="homeEmissionCEN" element={<EmissionCENFiscalHome />} />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="emissionIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="emissionIndicators" element={<EmissionIndicators />} />
        </Route>
      </Route>

      {/* User Route home CEN Reception */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="homeReceptionCEN" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="homeReceptionCEN" element={<ReceptionCENFiscalHome />} />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="receptionIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="receptionIndicators" element={<ReceptionIndicators />} />
        </Route>
      </Route>

      {/* User Route catalog */}

      <Route element={<ProtectedRoute requiredRole={"user"} path="catalog" />}>
        <Route path="user" element={<Layout />}>
          <Route path="catalog" element={<CatalogueUnified />} />
        </Route>
      </Route>

      {/* User Route SalesForce */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="salesForce" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="salesForce" element={<SalesForce />} />
        </Route>
      </Route>

      {/* User Route masters */}

      <Route element={<ProtectedRoute requiredRole={"user"} path="masters" />}>
        <Route path="user" element={<Layout />}>
          <Route path="masters" element={<Masters />} />
        </Route>
      </Route>

      {/* User Route delete and reload */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="deleteAndReload" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="deleteAndReload" element={<DeleteAndReload />} />
          <Route
            path="deleteAndReload/request"
            element={<ApplicationDeleteAndReload />}
          />
        </Route>
      </Route>

      {/* User Route dynamic report */}

      <Route
        element={
          <ProtectedRoute
            requiredRole={"user"}
            path="listDynamicPeriodicReports"
          />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="listDynamicPeriodicReports"
            element={<ListDynamicPeriodicReports />}
          />
          <Route
            path="createDynamicPeriodicReport"
            element={<CreateDynamicPeriodicReport />}
          />
          <Route
            path="editDynamicPeriodicReport/:pk/:sk"
            element={<EditDynamicPeriodicReport />}
          />
        </Route>
      </Route>

      {/* User Route Periodic Report CEN */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="listPeriodicReportCEN" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="listPeriodicReportCEN/:type"
            element={<ListPeriodicReportCEN />}
          />
          <Route
            path="createPeriodicReportCEN/:type"
            element={<CreatePeriodicReportCEN />}
          />
          <Route
            path="editPeriodicReportCEN/:type/:pk/:sk"
            element={<EditPeriodicReportCEN />}
          />
        </Route>
      </Route>

      {/* User Route dashboard */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="dashboard" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
      </Route>

      {/* User Route salesIndicators */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="salesIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="salesIndicators" element={<SalesIndicators />} />
        </Route>
      </Route>

      {/* User Route FillRateIndicators */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="fillRateIndicator" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="fillRateIndicator" element={<FillRateIndicator />} />
        </Route>
      </Route>

      {/* User Route inventoriesIndicators */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="inventoriesIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="inventoriesIndicators"
            element={<InventoriesIndicators />}
          />
        </Route>
      </Route>

      {/** User Route returnIndicadors */}
      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="returnsIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="returnsIndicators"
            element={<AccessReturnsIndicators />}
          />
        </Route>
      </Route>

      {/* User Route budgetIndicators */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="budgetIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="budgetIndicators" element={<AccessBudgetIndicators />} />
        </Route>
      </Route>

      {/* User Route listUsers */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="listUsers" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="listUsers" element={<ListUsers />} />
          <Route
            path="listUsers/:country/:companyId"
            element={<UserDetail />}
          />
        </Route>
      </Route>


      {/**User Route adminCompanies */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="adminCompanies" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminCompanies" element={<AdminCompanies />} />
        </Route>
      </Route>

      {/**User Route businessRules */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="adminBusinessRules" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminBusinessRules" element={<AdminBusinessRules />} />
        </Route>
      </Route>

      {/* User Route stores */}

      <Route element={<ProtectedRoute requiredRole={"user"} path="stores" />}>
        <Route path="user" element={<Layout />}>
          <Route path="stores" element={<Stores />} />
        </Route>
      </Route>

      {/* User Route Allies information */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="infoAllies" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="infoAllies" element={<NotFound />} />
        </Route>
      </Route>

      {/* User Route Allies reload */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="reloadAllies" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="reloadAllies" element={<ReloadAllies />} />
          <Route path="reloadAllies/request" element={<RequestAllies />} />
        </Route>
      </Route>

      {/* User admin transmitted value */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="adminTransmittedValue" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="adminTransmittedValue"
            element={<AdminTransmittedValue />}
          />
        </Route>
      </Route>

      {/* User admin purchase order and requests */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="adminPurchaseOrder" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminPurchaseOrder" element={<AdminPurchaseOrders />} />
          <Route
            path="adminPurchaseOrder/request"
            element={<RequestsPurchaseOrders />}
          />
        </Route>
      </Route>

      {/* User admin purchase order commerce */}

      <Route
        element={
          <ProtectedRoute
            requiredRole={"user"}
            path="adminPurchaseOrderCommerce"
          />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="adminPurchaseOrderCommerce"
            element={<AdminPurchaseOrderCommerce />}
          />
        </Route>
      </Route>

      {/** User Route Special Files */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="configureSpecialFiles" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="configureSpecialFiles"
            element={<AdminSpecialFilesAdmin />}
          />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="adminSpecialFiles" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route
            path="adminSpecialFiles"
            element={<AdminSpecialFilesClient />}
          />
          <Route
            path="createSpecialFiles"
            element={<CreateSpecialFileClient />}
          />
          <Route
            path="editSpecialFiles/:pk/:sk"
            element={<EditSpecialFileClient />}
          />
        </Route>
      </Route>

      {/** User admin users client */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="adminUsers" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminUsers" element={<AdminClientUsers />} />
          <Route path="consultRoles" element={<ConsultRoles />} />
        </Route>
      </Route>

      {/** User admin contacts */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="adminContacts" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminContacts" element={<AdminContacts />} />
        </Route>
      </Route>

      {/* Manage Business Group */}
      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="manageBusinessGroup" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="manageBusinessGroup" element={<ManageBusinessGroup />} />
        </Route>
      </Route>

      {/** Admin Indicators */}

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="adminIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminNotifications" element={<AdminNotifications />} />
        </Route>
      </Route>

      {/** Traders  */}

      {/** Products catalog */}
      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="traders/catalog" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="traders/catalog" element={<UnifiedCatalogTraders />} />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="traders/masters" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="traders/masters" element={<UnifiedMastersTraders />} />
        </Route>
      </Route>
      {/** Load mapping */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="loadMapping" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="loadMapping" element={<FieldLoadMapping />} />
        </Route>
      </Route>
      {/* User Route delete and reload */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="traceExternal" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="user" element={<DeleteAndReload />} />
          <Route path="traceExternal" element={<TraceabilityExternal />} />
        </Route>
      </Route>

      {/* CEN Fiscal */}

      {/* Bill Indicators */}
      <Route
        element={<ProtectedRoute requiredRole={"user"} path="billIndicators" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="billIndicators" element={<BillIndicators />} />
        </Route>
      </Route>

      {/* Payroll Indicators */}
      <Route
        element={
          <ProtectedRoute requiredRole={"user"} path="payrollIndicators" />
        }
      >
        <Route path="user" element={<Layout />}>
          <Route path="payrollIndicators" element={<PayrollIndicators />} />
        </Route>
      </Route>

      {/* Rate Master */}
      <Route element={<ProtectedRoute requiredRole={"user"} path="rate" />}>
        <Route path="user" element={<Layout />}>
          <Route path="rate" element={<RateMaster />} />
        </Route>
      </Route>

      {/* External Files */}
      <Route
        element={<ProtectedRoute requiredRole={"user"} path="externalFiles" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="externalFiles" element={<ExternalFiles />} />
        </Route>
      </Route>

      {/* Admin Reprocess */}

      <Route
        element={<ProtectedRoute requiredRole={"user"} path="adminReprocess" />}
      >
        <Route path="user" element={<Layout />}>
          <Route path="adminReprocess" element={<AdminReprocess />} />
        </Route>
      </Route>

      {/* Not Found */}

      <Route path="*" element={<NotFound />} />

      {/* Loading page */}

      <Route path="/loading" element={<LoadingPage />} />
    </Routes>
  );
}
export default AppRoutes;

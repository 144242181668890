import { Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GenericCardAccess } from "../../../../components/common/AccessIndicators/genericCardAccess";
import CustomSkeleton from "../../../../components/common/CustomSkeleton";
import FilterBusinessGroup from "../../../../components/common/Filters/businessGroup/filterWithButtons";
import ModalInfo from "../../../../components/common/Modals/modalInfo";
import { CODES } from "../../../../consts/codes";
import { GetIndicators } from "../../../../services/user/salesIndicatorsService";

const EmissionIndicators = () => {
  /**
   * Const
   */

  const iconRoute = "/assets/insightAccess/";

  /**
   * Use Translation
   */

  const { t, i18n } = useTranslation();

  /**
   * Use State
   */

  const [emissionIndicators, setEmissionIndicators] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);

  /**
   * Handles
   */

  /**
   * Realiza la petición para traer las tarjetas de indicadores de emisión
   * @param {*} data información de la empresa
   */
  const handleRequestIndicators = async (data) => {
    try {
      const obj = {
        eanProvider: data.ean.eanCompany,
        indicatorType: "emission",
      };

      setIsLoading(true);

      const {
        status,
        data: { responseMessage, responseCode },
      } = await GetIndicators(obj);

      if (
        status === CODES.COD_RESPONSE_HTTP_OK &&
        responseCode === CODES.COD_RESPONSE_SUCCESS
      ) {
        setEmissionIndicators(responseMessage);
      } else {
        setIsOpenModalInfo(true);
        setEmissionIndicators([]);
      }

      setIsLoading(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Abre una nueva ventana hacia QlikView
   */
  const handleOpenQlikView = (qlikviewLink) => {
    window.open(qlikviewLink, "_blank");
  };

  return (
    <Grid container direction="column" rowSpacing={1} className="u-marginB">
      <Grid item className="u-marginT u-marginB">
        <p className="display-large__primary-one">
          {t("App.indicatorsEmission.title")}
        </p>
      </Grid>
      <Grid
        item
        sx={{
          width: "100%",
          marginTop: "0.5%",
          marginBottom: "2%",
        }}
      >
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title")}
          handleRequestIndicators={handleRequestIndicators}
          setIsLoading={setIsLoading}
          typeOption={8}
        />
      </Grid>
      <Grid item>
        {isLoading ? (
          <CustomSkeleton />
        ) : (
          <Grid container columnSpacing={2} rowSpacing={2}>
            {emissionIndicators.map((indicator) => (
              <Grid item lg={4} sm={10} xs={12}>
                <GenericCardAccess
                  sourceIcon={iconRoute + indicator.icon}
                  title={
                    i18n.language.includes("es")
                      ? indicator.name
                      : indicator.nameEnglish
                  }
                  text={
                    i18n.language.includes("es")
                      ? indicator.description
                      : indicator.descriptionEnglish
                  }
                  buttonText={t("App.salesIndicators.buttonText")}
                  handleClick={() => {
                    handleOpenQlikView(indicator.url);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          data: { responseMessage: t("App.validationMessages.systemError2") },
        }}
        open={isOpenModalInfo}
        onClose={() => {
          setIsOpenModalInfo(false);
        }}
      />
    </Grid>
  );
};

export default EmissionIndicators;

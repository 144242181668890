import {
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FilterBILayersRequest } from "../../../components/common/Filters/filterBILayersRequest";

import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import MUIDataTable from "mui-datatables";
import RequestState from "../../../components/common/requestState";
import ModalCancelRequest from "../../../components/common/modalCancelRequest";
import { PageSkeleton } from "../../../components/common/Loading/pageSkeleton";
import { DownloadLogs } from "../../../services/user/deleteAndReload/applicationDeleteAndReload";
import { CODES } from "../../../consts/codes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import StateComponent from "../../../components/common/stateComponent";

const RequestBILayers = () => {
  /**
   * Datos prueba
   */

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Configuración de la tabla
   */
  const columns = [
    {
      name: "user",
      label: t("App.applicationDeleteAndReload.table.user"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "date",
      label: t("App.applicationDeleteAndReload.table.applicationDate"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "supplier",
      label: t("App.BILayers.request.filter.company"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "app",
      label: t("App.BILayers.request.filter.app"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "stateAutomation",
      label: t("App.BILayers.request.filter.autoState"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value) => (
          <Grid className="table-BILayers__state">
            <RequestState value={value} />
          </Grid>
        ),
      },
    },
    {
      name: "stateQvds",
      label: t("App.BILayers.request.filter.QvdsState"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
        customBodyRender: (value) => (
          <Grid className="table-BILayers__state">
            <RequestState value={value} />
          </Grid>
        ),
      },
    },
  ];

  /**
   * Configuración de las opciones de la tabla
   */
  const options = {
    download: false,
    print: "false",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.BILayers.request.filter.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Configuraciones del skeleton
   */
  const optionsSkeleton = {
    download: false,
    print: "false",
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "standard",
    jumpToPage: true,
    searchPlaceholder: t("App.BILayers.request.filter.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
        jumpToPage: t("App.userDetail.jumpPage"),
      },
    },
  };

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use State
   */

  //Elementos de la view cuando no se muestra la tabla
  const [noContent, setNoContent] = useState(true);
  const [filterNotFound, setFilterNotFound] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  //Información de los chips
  const [chipsFilters, setChipsFilters] = useState({
    action: "",
  });

  const [filterApply, setFilterApply] = useState({
    country: "",
    companyName: "",
    companyId: "",
    eanCompany: "",
    status: "",
    companyType: "",
  });

  //Información del formulario
  const [formFilters, setFormFilters] = useState({
    action: "",
    requestStartDate: "",
    requestEndDate: "",
    state: "",
  });

  //Data de la tabla
  const [data, setData] = useState([]);

  //Modal error general
  const [openModalGeneralError, setOpenModalGeneralError] = useState(false);

  /**
   * Regresa a la view de Recarga de aliados
   */
  const handleBackAdminBILayers = () => {
    navigate(-1);
  };

  return (
    <Grid container direction="column" rowGap={4}>
      <Grid item sx={{ marginTop: "2%" }}>
        <p className="title-screen-text__primary-one">
          {" "}
          {t("App.BILayers.request.title")}
        </p>
      </Grid>
      <Grid item>
        <Grid container direction="row" columnSpacing={2}>
          <Grid item>
            <IconButton
              className="btn__filled__blue"
              disableRipple
              onClick={handleBackAdminBILayers}
              sx={{ borderRadius: "4px" }}
            >
              <ArrowBackIcon fontSize="medium" />
            </IconButton>
          </Grid>
          <Grid item className="col-start" xs={10} sx={{ cursot: "pointer" }}>
            <p
              className="back-text__blue-500"
              onClick={handleBackAdminBILayers}
            >
              {t("App.BILayers.request.goBack")}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="companies_info_container biLayers_container">
        <Grid container direction="column" rowSpacing={2}>
          <Grid item className="control-filters">
            <Grid container>
              <Grid item className="full-width">
                <FilterBILayersRequest
                  setData={setData}
                  setChipFilters={setChipsFilters}
                  setIsDataLoading={setIsDataLoading}
                  setFilterNotFound={setFilterNotFound}
                  setNoContent={setNoContent}
                  setFormFilters={setFormFilters}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              columnSpacing={2}
              rowSpacing={2}
              sx={{ marginTop: "0px" }}
            >
              {chipsFilters.action !== "" && (
                <Grid item>
                  <Chip
                    className="chip__filter"
                    label={`${t("App.BILayers.request.filter.actionChip")}: ${
                      chipsFilters.action
                    }`}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ width: "99%" }}>
            {!noContent ? (
              !filterNotFound ? (
                isDataLoading ? (
                  <Grid>
                    <MUIDataTable
                      className="dataTable__base"
                      data={data}
                      columns={columns}
                      options={optionsSkeleton}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <MUIDataTable
                      className="dataTable__base"
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </Grid>
                )
              ) : (
                <Grid>
                  <NoMatchImage />
                </Grid>
              )
            ) : (
              <NoSelectionComponent type="BILayersRequests" />
            )}
          </Grid>
        </Grid>
      </Grid>
      <PageSkeleton openLoading={isDataLoading} />
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          data: { responseMessage: t("App.validationMessages.systemError2") },
        }}
        open={openModalGeneralError}
        onClose={() => {
          setOpenModalGeneralError(false);
        }}
      />
    </Grid>
  );
};

export default RequestBILayers;

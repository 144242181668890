import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CardContent from "@mui/material/CardContent";
import { Line, Bar } from "react-chartjs-2";
import { IconButton } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
} from "chart.js";
import Box from "@mui/material/Box";
import Joyride, { STATUS } from "react-joyride";
import TourIcon from "@mui/icons-material/Tour";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getUser, getUserStatus } from "../../userSlice";
import { openQlikSense } from "../../../helpers/helpers";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import WelcomeHome from "../../../components/common/welcomeHome";
import NoInfoDashboard from "../../../components/common/noInfoDashboard";
import FilterBusinessGroup from "../../../components/common/Filters/businessGroup/filterWithButtons";
import {
  IndicatorsChartHomeCEN,
  ListTransmissionsTableCEN,
} from "../../../services/user/dashboard";
import { useNavigate } from "react-router";
import { IndicatorsCards } from "../indicatorsCards";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title
);

// PARA AGREGAR UN NUEVO HOME SE DEBE IR A IndicatorsCards.jsx Y
// AGREGAR LA INFORMACIÓN DEL NUEVO HOME
export const BillFiscalCEN = () => {
  const { t } = useTranslation();
  const chartColors = ["rgba(141, 81, 218, 1)", "rgba(252,176,69,1)"];
  const navigate = useNavigate();
  const [isOpenTour, setIsOpenTour] = useState(false);

  //Revisar la informaicion apuntada por redux,
  const userInfo = useSelector(getUser);
  /*
    const statusUserHomeDataTableCEN = useSelector(getuserHomeDataTableStatusCEN);
    const statusUserHomeBarChartsCEN = useSelector(getuserHomeChartsStatusCEN);
    */

  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;
  const emailUser = userInfo.email;

  const [facturaValorSatData, setFacturaValorSat] = useState(null);
  const [facturaValorEmpresaData, setFacturaValorEmpresa] = useState(null);
  const [facturaVariacionValorData, setFacturaVariacionValor] = useState(null);
  const [facturaDocumentosSatData, setFacturaDocumentosSat] = useState(null);
  const [facturaDocumentosEmpresaData, setFacturaDocumentosEmpresa] =
    useState(null);
  const [facturaVariacionDocumentosData, setFacturaVariacionDocumentos] =
    useState(null);
  const [vigenciaCertificadoData, setVigenciaCertificado] = useState(null);

  const [isQuantitySelected, setIsQuantitySelected] = useState(true);

  const [dataTableInfoCEN, setDataTable] = useState([]);
  const [chartsInfoCEN, setCharts] = useState([]);

  const userStatus = useSelector(getUserStatus);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingButtons, setIsLoadingButtons] = useState(true);

  const [isPhone, setIsPhone] = useState(window.innerWidth <= 420);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 768 && window.innerWidth > 420
  );

  const [
    formattedCantidadDocumentosPorTipo,
    setFormattedCantidadDocumentosPorTipo,
  ] = useState({});
  const [
    formattedCantidadDocumentosPorEstado,
    setFormattedCantidadDocumentosPorEstado,
  ] = useState({});
  const [
    formattedCantidadDocumentosPorMes,
    setFormattedCantidadDocumentosPorMes,
  ] = useState({});
  const [formattedDocumentosMesActual, setFormattedDocumentosMesActual] =
    useState({});
  const [formattedDocumentosAcumAnio, setFormattedDocumentosAcumAnio] =
    useState({});
  const [
    formattedCantidadDocumentosNoRelacionados,
    setFormattedCantidadDocumentosNoRelacionados,
  ] = useState({});

  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [dataTableRaws, setDataTableRaws] = useState([]);

  /**
   * Builds a new object for charts based on indicators types.
   * @param {*} data Indicators items
   * @returns Formatted indicators object
   */
  const separateIndicators = (data) => {
    const separatedData = data.reduce((acc, item) => {
      const key = item.sk.split("#")[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const factura_valor_sat = separatedData["factura_valor_sat"] || [];
    const factura_valor_empresa = separatedData["factura_valor_empresa"] || [];
    const factura_variacion_valor =
      separatedData["factura_variacion_valor"] || [];
    const factura_documentos_sat =
      separatedData["factura_documentos_sat"] || [];
    const factura_documentos_empresa =
      separatedData["factura_documentos_empresa"] || [];
    const factura_variacion_documentos =
      separatedData["factura_variacion_documentos"] || [];
    const Vigencia_Certificado =
      separatedData["factura_vigencia_certificado"] || [];

    return {
      factura_valor_sat,
      factura_valor_empresa,
      factura_variacion_valor,
      factura_documentos_sat,
      factura_documentos_empresa,
      factura_variacion_documentos,
      Vigencia_Certificado,
    };
  };

  /**
   * Agrupa los datos de los gráficos por tipo.
   * @param {Array} data - Los datos a agrupar.
   * @returns {Object} Un objeto con los datos agrupados por tipo de gráfico.
   */
  const groupCharts = (data) => {
    const separatedData = data.reduce((acc, item) => {
      const key = item.sk.split("#")[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const groups = {
      cantidadDocumentosPorTipo: {
        keys: ["factura_documentos_x_tipo"],
        data: [],
        indicator: 7,
      },
      cantidadDocumentosPorEstado: {
        keys: ["factura_comparacion_estado"],
        data: [],
        indicator: 8,
      },
      cantidadDocumentosPorMes: {
        keys: ["factura_documentos_mes"],
        data: [],
        indicator: 9,
      },
      documentosMesActual: {
        keys: ["factura_documentos_mes_actual"],
        data: [],
        indicator: 10,
      },
      documentosAcumAnio: {
        keys: ["factura_documentos_anio"],
        data: [],
        indicator: 11,
      },
      cantidadDocumentosNoRelacionados: {
        keys: ["factura_documentos_no_relacionados"],
        data: [],
        indicator: 12,
      },
    };

    Object.entries(separatedData).forEach(([key, value]) => {
      Object.values(groups).forEach((group) => {
        if (group.keys.includes(key)) {
          group.data.push(value);
        }
      });
    });

    const groupedCharts = {};

    Object.entries(groups).forEach(([key, group]) => {
      groupedCharts[key] = group.data;
    });

    Object.keys(groups).forEach((key) => {
      if (groupedCharts[key].length === 0) {
        groupedCharts[key] = [
          [
            {
              sk: `${groups[key].keys}#document_type#facturas#id_record#${groups[key].indicator}`,
              indicador: 0,
              country: "Vacio",
              dimensiones: "VACIO|2000|01",
              eanProvider: "Vacio",
              fecha: "Vacio",
              valor: "0.0",
            },
            {
              sk: `${groups[key].keys}#document_type#facturas#id_record#${groups[key].indicator}`,
              indicador: 0,
              country: "Vacio",
              dimensiones: "VACIO|2000|01",
              eanProvider: "Vacio",
              fecha: "Vacio",
              valor: "0.0",
            },
          ],
        ];
      }
    });

    return groupedCharts;
  };

  /**
   * Formatea un número para su visualización.
   * @param {number} value - El número a formatear.
   * @returns {string} El número formateado como una cadena.
   */
  const formatNumber = (value) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(1)}B`;
    } else if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${value.toFixed(1)}`;
    } else {
      return value.toFixed(1);
    }
  };

  /**
   * Formatea los indicadores para su visualización.
   * @param {Object} separatedIndicators - Los indicadores separados por tipo.
   * @returns {Object} Los indicadores formateados.
   */
  function formatIndicators(separatedIndicators) {
    const factura_valor_sat = separatedIndicators.factura_valor_sat.reduce(
      (acc, item) =>
        acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
      0
    );
    const factura_valor_empresa =
      separatedIndicators.factura_valor_empresa.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const factura_variacion_valor =
      separatedIndicators.factura_variacion_valor.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const factura_documentos_sat =
      separatedIndicators.factura_documentos_sat.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const factura_documentos_empresa =
      separatedIndicators.factura_documentos_empresa.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const factura_variacion_documentos =
      separatedIndicators.factura_variacion_documentos.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    let vigencia_Certificado;

    if (
      separatedIndicators.Vigencia_Certificado === undefined ||
      separatedIndicators.Vigencia_Certificado.length === 0
    ) {
      vigencia_Certificado = [
        {
          valor: "Certificado pendiente",
          fecha: "0",
        },
      ];
    } else {
      vigencia_Certificado = separatedIndicators.Vigencia_Certificado.reduce(
        (acc, item) => {
          const fechaOriginal = item.fecha;
          const fechaFormateada = `${fechaOriginal.substring(
            0,
            4
          )}-${fechaOriginal.substring(4, 6)}-${fechaOriginal.substring(6, 8)}`;
          acc.push({
            valor: item.valor,
            fecha: fechaFormateada,
          });
          return acc;
        },
        []
      );
    }

    const factura_valor_sat_formatted = formatNumber(factura_valor_sat);
    const factura_valor_empresa_formatted = formatNumber(factura_valor_empresa);
    const factura_variacion_valor_formatted = formatNumber(
      factura_variacion_valor
    );
    const factura_documentos_sat_formatted = formatNumber(
      factura_documentos_sat
    );
    const factura_documentos_empresa_formatted = formatNumber(
      factura_documentos_empresa
    );
    const factura_variacion_documentos_formatted = formatNumber(
      factura_variacion_documentos
    );

    return {
      facturaValorSat: factura_valor_sat_formatted,
      facturaValorEmpresa: factura_valor_empresa_formatted,
      facturaVariacionValor: factura_variacion_valor_formatted,
      facturaDocumentosSat: factura_documentos_sat_formatted,
      facturaDocumentosEmpresa: factura_documentos_empresa_formatted,
      facturaVariacionDocumentos: factura_variacion_documentos_formatted,
      vigenciaCertificado: vigencia_Certificado,
    };
  }

  /**
   * Formatea los datos de los gráficos para su visualización.
   * @param {Array} data - Los datos a formatear.
   * @returns {Object} Los datos formateados.
   */
  const formatCharts = (data) => {
    if (data[0]) {
      const separatedData = data[0].reduce((acc, item) => {
        const key = item.sk.split("#")[4];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push({
          dimensiones: item.dimensiones,
          fecha: item.fecha,
          valor: parseFloat(item.valor),
        });
        return acc;
      }, {});

      return separatedData;
    }
  };

  /**
   * Función para actualizar el estado  si es tablet o no
   */
  const updateMedia = () => {
    setIsPhone(window.innerWidth <= 420);
    setIsTablet(window.innerWidth <= 768 && window.innerWidth > 420);
  };
  /**
   * Actualizar de acuerdo al tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Procesa los datos de la tabla y los gráficos.
   * @param {Object} dataTableInfoCEN - La información de la tabla.
   * @param {Object} chartsInfoCEN - La información de los gráficos.
   */
  function processData(dataTableInfoCEN, chartsInfoCEN) {
    const separatedIndicators = separateIndicators(
      chartsInfoCEN.responseMessage
    );
    const formattedIndicators = formatIndicators(separatedIndicators);

    setFacturaValorSat(formattedIndicators.facturaValorSat);
    setFacturaValorEmpresa(formattedIndicators.facturaValorEmpresa);
    setFacturaVariacionValor(formattedIndicators.facturaVariacionValor);
    setFacturaDocumentosSat(formattedIndicators.facturaDocumentosSat);
    setFacturaDocumentosEmpresa(formattedIndicators.facturaDocumentosEmpresa);
    setFacturaVariacionDocumentos(
      formattedIndicators.facturaVariacionDocumentos
    );
    setVigenciaCertificado(formattedIndicators.vigenciaCertificado);

    const separatedCharts = groupCharts(chartsInfoCEN.responseMessage);
    const formattedCantidadDocumentosPorTipo = formatCharts(
      separatedCharts.cantidadDocumentosPorTipo
    );
    setFormattedCantidadDocumentosPorTipo(formattedCantidadDocumentosPorTipo);
    const formattedCantidadDocumentosPorEstado = formatCharts(
      separatedCharts.cantidadDocumentosPorEstado
    );
    setFormattedCantidadDocumentosPorEstado(
      formattedCantidadDocumentosPorEstado
    );
    const formattedCantidadDocumentosPorMes = formatCharts(
      separatedCharts.cantidadDocumentosPorMes
    );
    setFormattedCantidadDocumentosPorMes(formattedCantidadDocumentosPorMes);
    const formattedDocumentosMesActual = formatCharts(
      separatedCharts.documentosMesActual
    );
    setFormattedDocumentosMesActual(formattedDocumentosMesActual);
    const formattedDocumentosAcumAnio = formatCharts(
      separatedCharts.documentosAcumAnio
    );
    setFormattedDocumentosAcumAnio(formattedDocumentosAcumAnio);
    const formattedCantidadDocumentosNoRelacionados = formatCharts(
      separatedCharts.cantidadDocumentosNoRelacionados
    );
    setFormattedCantidadDocumentosNoRelacionados(
      formattedCantidadDocumentosNoRelacionados
    );

    setCharts(chartsInfoCEN.responseMessage);
  }

  /**
   * Maneja los filtros y realiza las solicitudes de datos.
   * @param {Object} data - Los datos de los filtros.
   */
  const handleFilters = async (data) => {
    try {
      const obj = {
        eanProvider: data.company.eanCompany,
        country: userInfo.company.country,
      };
      // revisar el funcionamiento
      setIsLoading(true);
      verificarYRedirigir(userInfo);

      const indicatorsChartService = await IndicatorsChartHomeCEN(obj);
      const listTransmissionsService = await ListTransmissionsTableCEN(obj);

      processData(listTransmissionsService.data, indicatorsChartService.data);

      if (
        listTransmissionsService.data.responseMessage.length === 0 ||
        listTransmissionsService == undefined
      ) {
        const defaultColumns = [
          {
            sk: "transmission#document_type#factura#table",
            indicador: 0,
            cantidad: 0,
            country: "Vacio",
            dimensiones: "Vacio|2000-00-00|2000-00-00",
            eanProvider: "Vacio",
            fecha: "0000-00-00",
            fechacarga: "0000-00-00",
            valor: "0",
          },
          {
            sk: "transmission#document_type#nomina#table",
            indicador: 0,
            cantidad: 0,
            country: "Vacio",
            dimensiones: "Vacio|2000-00-00|2000-00-00",
            eanProvider: "Vacio",
            fecha: "0000-00-00",
            fechacarga: "0000-00-00",
            valor: "0",
          },
        ];

        listTransmissionsService.data.responseMessage = defaultColumns;
      }

      const columns = buildDataTableColumns(
        listTransmissionsService.data.responseMessage
      );

      const rows = buildDataTableRows(
        listTransmissionsService.data.responseMessage
      );

      setDataTableColumns(columns);
      setDataTableRaws(rows);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Función que verifica la información del usuario y redirige según los servicios disponibles.
   * @param {Object} userInfo - Información del usuario.
   */
  const verificarYRedirigir = (userInfo) => {
    if (typeof userInfo === "object") {
      const lstMenuServices = userInfo.lstMenu;

      // Verificar si lstMenuServices contiene los servicios Ventas e Inventarios
      const hasVentasInventariosService = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "Ventas e Inventarios"
      );
      // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de factura"
      const hasCenFiscalInvoice = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "CEN Fiscal Factura"
      );
      // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de nómina"
      const hasCenFiscalPayroll = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "CEN Fiscal Nómina"
      );
      if (!hasCenFiscalInvoice && hasVentasInventariosService) {
        navigate("/user/home");
      } else if (
        hasCenFiscalPayroll &&
        !hasVentasInventariosService &&
        !hasCenFiscalInvoice
      ) {
        navigate("/user/homePayrollCEN");
      }
    }
  };

  /**
   * Transmitions datatable values
   */
  const optionsDataTable = {
    download: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    filter: false,
    rowHover: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: t("App.salesForce.placeholder"),
    elevation: 0,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: t("App.validationMessages.noMatchFilter"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Funcion que sirve para settear los valores de las columnas en la tabla de transmisiones
   * @param {obj} dataTable
   * @returns columnas de la tabla
   */
  const buildDataTableColumns = (dataTable) => {
    const columns = [];
    for (const item of dataTable) {
      const skSplited = ([] = item.sk?.split("#") ?? []);
      const documentTypeIndex = skSplited.indexOf("document_type");
      if (
        documentTypeIndex !== -1 &&
        skSplited[documentTypeIndex + 1] === "factura"
      ) {
        const dimensiones =
          item?.dimensiones !== null || item?.dimensiones !== undefined
            ? item?.dimensiones
            : "Vacio|2000-00-00|2000-00-00";
        const fechacarga =
          item?.fechacarga === null || item?.fechacarga === undefined
            ? "0000-00-00"
            : item?.fechacarga;
        const fecha =
          item?.fecha !== null || item?.fecha !== undefined
            ? item?.fecha
            : "0000-00-00";
        const cantidad = item?.cantidad !== undefined ? item?.cantidad : 0;
        const valor = item?.valor !== undefined ? item?.valor : 0;

        const obj = {
          dimensiones: dimensiones[0],
          fechacarga: fechacarga,
          fecha: fecha,
          cantidad: cantidad,
          valor: valor,
        };
        columns.push(obj);
      } else {
        // console.log("No existe tabla para CEN Fiscal factura", columns);
        columns.push(1);
      }
    }

    setDataTable(columns);

    return [
      {
        name: "dimensiones",
        label: t("App.CENDashboard.billCENClient.table.columns.column1"),
        options: {
          sort: true,
        },
      },
      {
        name: "fechacarga",
        label: t("App.CENDashboard.billCENClient.table.columns.column2"),
        options: {
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            const lastTransmission = (
              <div>
                <h4>
                  {t("App.CENDashboard.billCENClient.table.columns.column2")}
                </h4>
                <h5 className="subheading-datatable">
                  {t("App.CENDashboard.billCENClient.table.columns.formatDate")}
                </h5>
              </div>
            );

            return lastTransmission;
          },
        },
      },
      {
        name: "cantidad",
        label: t("App.CENDashboard.billCENClient.table.columns.column3"),
        options: {
          sort: true,
        },
      },
      {
        name: "valor",
        label: t("App.CENDashboard.billCENClient.table.columns.column4"),
        options: {
          sort: true,
        },
      },
      {
        name: "dateAll",
        label: t("App.CENDashboard.billCENClient.table.columns.column5"),
        options: {
          sort: true,
          hint: t("App.CENDashboard.billCENClient.table.columns.toolTip"),
          customBodyRender: (value) => {
            return (
              <Accordion
                disableGutters
                className="custom-accordion-summary__dates"
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  className="custom-accordion-summary"
                  sx={{
                    "& .MuiAccordionSummary-content.MuiAccordionSummary-content":
                      {
                        justifyContent: "center",
                      },
                  }}
                  expandIcon={
                    value.length > 1 ? (
                      <ExpandMoreIcon
                        fontSize="large"
                        className="custom-accordion-summary__icon"
                      />
                    ) : (
                      <></>
                    )
                  }
                >
                  <label className="label-Primary">{value[0]}</label>
                </AccordionSummary>

                <AccordionDetails className="custom-accordion-detail">
                  {value.map((item, idx) => {
                    if (idx <= 4) {
                      return (
                        <label
                          key={idx}
                          className={
                            idx === 0
                              ? "custom-accordion-detail__hidden label-Primary"
                              : "label-Primary"
                          }
                        >
                          {item}
                        </label>
                      );
                    }
                  })}
                  {value.length > 4 && (
                    <>
                      <MoreHorizIcon
                        sx={{ color: "#8c8d9d" }}
                        fontSize="large"
                      />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          },
          customHeadLabelRender: (columnMeta) => {
            const upDates = (
              <div>
                <h4>
                  {t("App.CENDashboard.billCENClient.table.columns.column5")}
                </h4>
                <h5 className="subheading-datatable">
                  {t("App.CENDashboard.billCENClient.table.columns.formatDate")}
                </h5>
              </div>
            );
            return upDates;
          },
        },
      },
    ];
  };

  /**
   * Ordena una lista de fechas en formato 'YYYYMMDD' y elimina las duplicadas.
   *
   * @param {Array} list - La lista de fechas a ordenar.
   * @param {string} fecha - Una fecha adicional a agregar a la lista.
   * @param {string} fechacarga - Otra fecha adicional a agregar a la lista.
   * @returns {Array} La lista de fechas ordenadas y sin duplicados.
   */

  const sortDates = (list, fecha, fechacarga) => {
    list.push(
      moment(fecha, "YYYYMMDD").format("YYYY-MM-DD"),
      moment(fechacarga, "YYYYMMDD").format("YYYY-MM-DD")
    );

    let orderedDates = Array.from(new Set(list.map((date) => date)));

    orderedDates = orderedDates.sort((a, b) =>
      moment(b, "YYYY-MM-DD").diff(moment(a, "YYYY-MM-DD"))
    );

    return orderedDates;
  };

  const currFormatter = (value) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("es", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .format(value)
        ?.replace(",", ".");
    }

    return value;
  };

  /**
   * Funcon que sirve para settear los valores de las filas de la tabla
   * @param {obj} dataTable
   * @returns filas de la tabla de
   */
  function buildDataTableRows(dataTable) {
    let uniqueData = [];

    for (const item of dataTable) {
      const skSplited = ([] = item.sk?.split("#") ?? []);
      const documentTypeIndex = skSplited.indexOf("document_type");
      if (
        documentTypeIndex !== -1 &&
        skSplited[documentTypeIndex + 1] === "factura"
      ) {
        const empresaDimension = item.dimensiones ?? "";
        const employeee = empresaDimension?.split("|") ?? "";

        const _eanProvider = item.ean_provider ?? item.eanProvider;

        if (
          uniqueData.some(
            (unique) =>
              unique.dimensiones === employeee[0] &&
              unique.ean_provider === _eanProvider &&
              unique.country === item.country &&
              unique.indicador === item.indicador
          )
        ) {
          uniqueData = uniqueData.map((unique) => {
            if (
              unique.dimensiones === employeee[0] &&
              unique.ean_provider === _eanProvider &&
              unique.country === item.country &&
              unique.indicador === item.indicador
            )
              return {
                ...unique,
                cantidad: item.cantidad + unique.cantidad,
                valor: formatNumber(
                  parseFloat(item.valor) + parseFloat(unique.valor)
                ),
                dateAll: sortDates(unique.dateAll, item.fecha, item.fechacarga),
              };
            else return unique;
          });
        } else {
          uniqueData.push({
            ean_provider: _eanProvider,
            country: item.country,
            indicador: item.indicador,
            fechacarga: item.fechacarga,
            fecha: item.fecha,
            cantidad: item.cantidad,
            valor: formatNumber(parseFloat(item.valor)),
            dateAll: sortDates([], item.fecha, item.fechacarga),
            dimensiones: employeee[0],
          });
        }
      }
    }

    setDataTableRaws(uniqueData);
    return uniqueData;
  }

  /**
   * Triggers actions based on users selection into the joyride
   * @param {*} data
   */
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsOpenTour(false);
    }
  };

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  //* Cantidad de documentos por mes actual
  const optionsBarHorizontal = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex].toFixed(1);
            return value;
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
      },
      x: {
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };
  //* Cantidad de documentos por mes actual
  const dataDocumentosMesActual = {
    labels: formattedDocumentosMesActual["10"]?.map((item) => item.fecha),
    datasets: [
      {
        label: "SAT",
        data: formattedDocumentosMesActual["10"]?.map((item) =>
          item.dimensiones === "SAT" ? item.valor : 0
        ),
        backgroundColor: chartColors[0],
      },
      {
        label: "EMPRESA",
        data: formattedDocumentosMesActual["10"]?.map((item) =>
          item.dimensiones === "EMPRESA" ? item.valor : 0
        ),
        backgroundColor: chartColors[1],
      },
    ],
  };
  //* Cantidad de documentos por año

  const getVariacionConverter = (tabla) => {
    const satValues = tabla.filter((item) => item.dimensiones === "SAT");
    const empresaValues = tabla.filter(
      (item) => item.dimensiones === "EMPRESA"
    );

    const satFechaMasReciente = satValues.reduce((prev, current) =>
      prev.fecha > current.fecha ? prev : current
    );
    const empresaFechaMasReciente = empresaValues.reduce((prev, current) =>
      prev.fecha > current.fecha ? prev : current
    );

    const satValorMasReciente = parseFloat(satFechaMasReciente.valor);
    const empresaValorMasReciente = parseFloat(empresaFechaMasReciente.valor);
    const resultado = satValorMasReciente / empresaValorMasReciente - 1;
    return resultado.toFixed(1);
  };

  const dataDocumentosAcumAnio = {
    labels: formattedDocumentosAcumAnio["11"]?.map((item) => item.fecha),
    datasets: [
      {
        label: "SAT",
        data: formattedDocumentosAcumAnio["11"]?.map((item) =>
          item.dimensiones === "SAT" ? item.valor : 0
        ),
        backgroundColor: chartColors[0],
      },
      {
        label: "EMPRESA",
        data: formattedDocumentosAcumAnio["11"]?.map((item) =>
          item.dimensiones === "EMPRESA" ? item.valor : 0
        ),
        backgroundColor: chartColors[1],
      },
    ],
  };
  //* Cantidad de documentos por mes
  const optionsLineCharts = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex];
            return formatValue(value.y);
          },
        },
      },
    },
    scales: {
      xAxis: { display: true },
      y: {
        ticks: {
          stacked: true,
          beginAtZero: true,
          callback: function (value, index, values) {
            return formatValue(value);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const getDimentions = (dimensiones) => {
    if (dimensiones !== null && dimensiones !== undefined) {
      const _dimensiones = dimensiones.split("|");

      return {
        dimensiones: _dimensiones[0],
        year: _dimensiones[1],
        monthText:
          t(`App.CENDashboard.billCENClient.months.${_dimensiones[2]}`) +
          "-" +
          _dimensiones[1].slice(-2),
        month: _dimensiones[2],
      };
    }
  };
  let _formattedCantidadDocumentosPorMes = [];
  if (
    formattedCantidadDocumentosPorMes !== null &&
    formattedCantidadDocumentosPorMes !== undefined
  ) {
    _formattedCantidadDocumentosPorMes = formattedCantidadDocumentosPorMes["9"]
      ?.map(({ dimensiones, fecha, valor }) => {
        return {
          dimensiones: getDimentions(dimensiones),
          fecha,
          valor,
        };
      })
      ?.sort((a, b) => a.dimensiones.month - b.dimensiones.month);
  }

  _formattedCantidadDocumentosPorMes = _formattedCantidadDocumentosPorMes;

  const getConverterCantidadDocumentosPorMes = () => {
    if (
      _formattedCantidadDocumentosPorMes !== null &&
      _formattedCantidadDocumentosPorMes !== undefined
    ) {
      const y = _formattedCantidadDocumentosPorMes.reduce((acc, item) => {
        const etiqueta =
          item.dimensiones.dimensiones === "EMPRESA" ? "EMPRESA" : "SAT";
        if (!acc[etiqueta]) {
          acc[etiqueta] = {
            label: etiqueta,
            data: [],
            borderColor:
              etiqueta === "SAT" ? "rgb(138, 80, 113)" : "rgb(68, 103, 123)",
            backgroundColor:
              etiqueta === "SAT" ? "rgb(138, 80, 113)" : "rgb(68, 103, 123)",
            fill: false,
            tension: 0.1,
          };
        }
        acc[etiqueta].data.push({
          y: item.valor,
          x: item.dimensiones.monthText,
        });

        return acc;
      }, {});

      /* const x = Array.from(
        new Set(
          _formattedCantidadDocumentosPorMes.map(item => item.dimensiones.month)
        )
      ) */

      return {
        labels: [],
        datasets: Object.values(y),
      };
    }

    return {};
  };

  //* Cantidad de documentos por estado
  const dataCantidadDocumentosPorEstado = {
    labels: ["SAT", "EMPRESA"],
    datasets: [
      {
        label: "Vigente",
        data: [
          formattedCantidadDocumentosPorEstado["8"]?.find(
            (item) => item.dimensiones === "SAT|Vigente"
          )?.valor || 0,
          formattedCantidadDocumentosPorEstado["8"]?.find(
            (item) => item.dimensiones === "EMPRESA|Vigente"
          )?.valor || 0,
        ],
        backgroundColor: "rgb(93, 158, 215)",
      },
      {
        label: "Cancelado",
        data: [
          formattedCantidadDocumentosPorEstado["8"]?.find(
            (item) => item.dimensiones === "SAT|Cancelado"
          )?.valor || 0,
          formattedCantidadDocumentosPorEstado["8"]?.find(
            (item) => item.dimensiones === "EMPRESA|Cancelado"
          )?.valor || 0,
        ],
        backgroundColor: "rgb(239, 127, 50)",
      },
    ],
  };

  const formatValue = (value) => {
    if (typeof value !== "number") {
      return value;
    }

    const _format = value.toFixed(0);

    return _format.replace(",", ".");
  };

  // *  Cantidad de documentos por tipo
  const dataCantidadDocumentosPorTipo = {
    labels: ["SAT", "EMPRESA"],
    datasets: [
      {
        label: "Factura",
        data: [
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "SAT|Factura"
          )?.valor || 0,
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "EMPRESA|Factura"
          )?.valor || 0,
        ],
        backgroundColor: chartColors[0],
      },
      {
        label: "Nota Crédito",
        data: [
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "SAT|Nota Crédito"
          )?.valor || 0,
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "EMPRESA|Nota Crédito"
          )?.valor || 0,
        ],
        backgroundColor: "rgb(239, 127, 50)",
      },
      {
        label: "Traslado",
        data: [
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "SAT|Traslado"
          )?.valor || 0,
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "EMPRESA|Traslado"
          )?.valor || 0,
        ],
        backgroundColor: chartColors[1],
      },
      {
        label: "Complemento de Pago",
        data: [
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "SAT|Complemento de Pago"
          )?.valor || 0,
          formattedCantidadDocumentosPorTipo["7"]?.find(
            (item) => item.dimensiones === "EMPRESA|Complemento de Pago"
          )?.valor || 0,
        ],
        backgroundColor: "rgb(93, 158, 215)",
      },
    ],
  };
  // * Opsiones de las graficas de barras verticales
  const optionsBarVertical = {
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex];
            return formatValue(value);
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            return formatValue(value);
          },
        },
      },
      x: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            footer: {
              border: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "center",
            },
            toolButton: {
              // * Secreto para que los headers del datatable queden centrados con el contenido
              margin: "0",
              padding: "0",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
            },
          },
        },
      },
    });

  /**
   * Joyride steps as html elements
   */
  const steps = [
    {
      target: ".step-1",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title1")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 1/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content1")}
          </p>
        </div>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".step-2",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title2")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 2/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content2")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".step-3",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title3")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 3/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content3")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-0",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title4")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 4/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content4")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-1",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title5")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 5/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content5")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-2",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title6")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 6/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content6")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  return (
    <Box className="box-container">
      {userInfo?.company?.eanCompany !== "CASSANDRA" && (
        <>
          <Joyride
            callback={handleJoyrideCallback}
            run={isOpenTour}
            continuous={true}
            showSkipButton={true}
            hideCloseButton={true}
            steps={steps}
            locale={{
              skip: (
                <Button variant="text" className="button-skip">
                  {t("App.homeDashboard.coachmark.buttons.skip")}
                </Button>
              ),
              next: (
                <Button
                  startIcon={<ArrowForwardOutlinedIcon />}
                  className="button-next"
                >
                  {t("App.homeDashboard.coachmark.buttons.next")}
                </Button>
              ),
              back: (
                <Button className="button-back" variant="outlined">
                  {t("App.homeDashboard.coachmark.buttons.back")}
                </Button>
              ),
              last: (
                <Button className="button-last">
                  {t("App.homeDashboard.coachmark.buttons.finish")}
                </Button>
              ),
            }}
            styles={{
              arrowColor: "#fff",
              backgroundColor: "#fff",
              beaconSize: 36,
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: "#f04",
              spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
              textColor: "#333",
              width: undefined,
              zIndex: 100,
            }}
          />

          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                aria-label="delete"
                onClick={() => setIsOpenTour(true)}
              >
                <TourIcon fontSize="large" className="heading__primary-color" />
              </IconButton>

              <label className="display-large__primary-one">
                {t("App.CENDashboard.billCENClient.welcomeTitle")}
              </label>
            </Grid>

            <Grid item lg={8} md={8} xs={12} sm={12} justifyContent="flex-end">
              <Grid container spacing={2} justifyContent={"flex-end"}>
                <IndicatorsCards
                  excludeHome={"invoice"}
                  setIsLoadingButtons={setIsLoadingButtons}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Grid
        item
        sx={{
          width: "100%",
          padding: "1%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title")}
          handleRequestIndicators={handleFilters}
          setIsLoading={setIsLoading}
          typeOption={6}
        />
      </Grid>
      {userInfo?.company?.eanCompany === "CASSANDRA" ? (
        <WelcomeHome />
      ) : isLoading || isLoadingButtons ? (
        <CustomSkeleton />
      ) : dataTableInfoCEN.length > 0 && chartsInfoCEN.length > 0 ? (
        <Grid item width="100%">
          <Grid container>
            <Grid item width="100%">
              {" "}
              <Grid container spacing={2} width="100%">
                {/* Indicador Valor SAT ($) Acum */}
                <Grid item xl={1.7} xs={12} lg={4} sm={6}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label
                            className="subheading__text-black"
                            style={{ fontWeight: 700 }}
                          >
                            {t(
                              "App.CENDashboard.billCENClient.firstBlock.valueSAT"
                            )}
                          </label>
                        </div>
                        <label
                          className="display-small__text-blue3"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {facturaValorSatData !== null
                            ? `${facturaValorSatData}`
                            : "-"}
                        </label>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Indicador Valor Empresa ($) Acum */}
                <Grid item xl={1.7} xs={12} lg={4} sm={6}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label className="subheading__text-black">
                            {t(
                              "App.CENDashboard.billCENClient.firstBlock.valueCompany"
                            )}
                          </label>
                        </div>
                        <label
                          className="display-small__text-blue3"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {facturaValorEmpresaData !== null
                            ? `${facturaValorEmpresaData}`
                            : "-"}
                        </label>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Indicador de % Var. (Cant) */}
                <Grid item xl={1.7} xs={12} lg={4} sm={6}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label className="subheading__text-black">
                            {t(
                              "App.CENDashboard.billCENClient.firstBlock.valueVarPercentage"
                            )}
                          </label>
                        </div>
                        <label
                          className={`display-small${
                            facturaVariacionValorData !== null
                              ? facturaVariacionValorData.includes("-")
                                ? "__warning"
                                : "__text-blue3"
                              : ""
                          }`}
                          style={{ fontSize: "2.5rem" }}
                        >
                          {facturaVariacionValorData !== null
                            ? `${facturaVariacionValorData}%`
                            : "-"}
                        </label>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Indicador Doc SAT (Cant) Acum
                 */}
                <Grid item xl={1.7} xs={12} lg={3} sm={6}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label className="subheading__text-black">
                            {t(
                              "App.CENDashboard.billCENClient.firstBlock.docSAT"
                            )}
                          </label>
                        </div>
                        <label
                          className="display-small__text-blue3"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {facturaDocumentosSatData !== null
                            ? `${facturaDocumentosSatData}`
                            : "-"}
                        </label>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Indicador Doc Empresa (Cant) Acum */}
                <Grid item xl={1.7} xs={12} lg={3} sm={4}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label className="subheading__text-black">
                            {t(
                              "App.CENDashboard.billCENClient.firstBlock.docCompany"
                            )}
                          </label>
                        </div>
                        <label
                          className="display-small__text-blue3"
                          style={{ fontSize: "2.5rem" }}
                        >
                          {facturaDocumentosEmpresaData !== null
                            ? `${facturaDocumentosEmpresaData}`
                            : "-"}
                        </label>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Indicador % Var. (Cant) */}
                <Grid item xs={12} xl={1.7} lg={3} sm={4}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label className="subheading__text-black">
                            {t(
                              "App.CENDashboard.billCENClient.firstBlock.valueVarQuantity"
                            )}
                          </label>
                        </div>
                        <label
                          className={`display-small${
                            facturaVariacionDocumentosData !== null
                              ? facturaVariacionDocumentosData.includes("-")
                                ? "__warning"
                                : "__text-blue3"
                              : ""
                          }`}
                          style={{ fontSize: "2.5rem" }}
                        >
                          {facturaVariacionDocumentosData !== null
                            ? `${facturaVariacionDocumentosData}%`
                            : "-"}
                        </label>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Indicador de Certificado */}
                <Grid item xs={12} xl={1.7} lg={3} sm={4}>
                  <Card
                    style={{
                      borderRadius: "16px",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          height: "6rem",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ marginBottom: "1rem" }}>
                          <label className={`subheading__text-black`}>
                            {vigenciaCertificadoData[0].valor === "Vigente"
                              ? "Certificado Vigente"
                              : vigenciaCertificadoData[0].valor}
                          </label>
                        </div>
                        <div>
                          <label
                            style={{ fontSize: "2.5rem" }}
                            className={`display-small__text-blue3`}
                          >
                            {vigenciaCertificadoData[0].fecha}
                          </label>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                {/* GRÁFICOS */}

                {/* Cantidad de documentos por tipo (Acum) */}
                <Grid item xs={12} lg={6} xl={4} order={{ xs: 1, lg: 1 }}>
                  <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                    <CardContent className="card-content">
                      <Box
                        className="box-content__bar scroll-sidebar"
                        height={isPhone ? "350px" : "320px"}
                        overflow={"auto"}
                      >
                        <label
                          className="display-small__text-black"
                          style={{ paddingBottom: "20px" }}
                        >
                          {t(
                            "App.CENDashboard.billCENClient.secondBlock.numberDocByType"
                          )}
                        </label>
                        <div style={{ height: "290px", paddingTop: "20px" }}>
                          <Bar
                            options={optionsBarVertical}
                            data={dataCantidadDocumentosPorTipo}
                          />
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Cantidad de documentos por estado (Acum)
                 */}
                <Grid item xs={12} lg={6} xl={4} order={{ xs: 2, lg: 2 }}>
                  <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                    <CardContent className="card-content">
                      <Box
                        className="box-content__bar scroll-sidebar"
                        height={isPhone ? "350px" : "320px"}
                        overflow={"auto"}
                      >
                        <label
                          className="display-small__text-black"
                          style={{ paddingBottom: "20px" }}
                        >
                          {t(
                            "App.CENDashboard.billCENClient.secondBlock.numberDocByStatus"
                          )}
                        </label>
                        <div style={{ height: "290px", paddingTop: "20px" }}>
                          <Bar
                            options={optionsBarVertical}
                            data={dataCantidadDocumentosPorEstado}
                          />
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Cantidad de documentos por mes
                 */}
                <Grid item xs={12} lg={6} xl={4} order={{ xs: 3, lg: 3 }}>
                  <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                    <CardContent className="card-content">
                      <Box
                        className="box-content__bar scroll-sidebar"
                        height={isPhone ? "350px" : "320px"}
                        overflow={"auto"}
                      >
                        <label className="display-small__text-black">
                          {t(
                            "App.CENDashboard.billCENClient.secondBlock.numberDocByMounth"
                          )}
                        </label>

                        {isPhone ? (
                          <div
                            style={{
                              height: "320px",
                              width: "375px",
                              overflowX: "auto",
                            }}
                          >
                            <Line
                              options={optionsLineCharts}
                              data={getConverterCantidadDocumentosPorMes()}
                            />
                          </div>
                        ) : (
                          <div style={{ height: "290px", overflowX: "auto" }}>
                            <Line
                              options={optionsLineCharts}
                              data={getConverterCantidadDocumentosPorMes()}
                            />
                          </div>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Documentos (Cant) mes actual */}
                <Grid
                  item
                  xs={12}
                  lg={6}
                  xl={4}
                  order={{ xs: 4, lg: 5, xl: 4 }}
                >
                  <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                    <CardContent className="card-content">
                      <Box
                        className="box-content__bar scroll-sidebar"
                        height={isPhone ? "350px" : "320px"}
                        overflow={"auto"}
                      >
                        <label className="display-small__text-black">
                          {t(
                            "App.CENDashboard.billCENClient.threeBlock.docCantCurrentMonth"
                          )}
                        </label>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <Bar
                            options={optionsBarHorizontal}
                            data={dataDocumentosMesActual}
                          />
                          <div style={{ padding: "20px" }}>
                            <p
                              style={{ width: "60px", float: "right" }}
                              className={`${
                                parseFloat(
                                  getVariacionConverter(
                                    formattedDocumentosMesActual["10"]
                                  )
                                ) < 0
                                  ? "error"
                                  : "success"
                              } card-text-align-right  subheading__text-black`}
                            >
                              {getVariacionConverter(
                                formattedDocumentosMesActual["10"]
                              )}
                              %
                            </p>
                            <p
                              style={{
                                width: "180px",
                                display: "inline-block",
                                float: "right",
                              }}
                              className="card-text-align-right  subheading__text-black"
                            >
                              {t(
                                "App.CENDashboard.billCENClient.card.ytdCantDoc"
                              )}
                            </p>
                          </div>
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Documentos (Cant) Acum año
                 */}
                <Grid
                  item
                  xs={12}
                  lg={6}
                  xl={4}
                  order={{ xs: 5, lg: 6, xl: 5 }}
                >
                  <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                    <CardContent className="card-content">
                      <Box
                        className="box-content__bar scroll-sidebar"
                        height={isPhone ? "350px" : "320px"}
                        overflow={"auto"}
                      >
                        <label className="display-small__text-black">
                          {t(
                            "App.CENDashboard.billCENClient.threeBlock.docCantAccumuYear"
                          )}
                        </label>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <Bar
                            options={optionsBarHorizontal}
                            data={dataDocumentosAcumAnio}
                          />
                          <div style={{ padding: "20px" }}>
                            <p
                              style={{ width: "60px", float: "right" }}
                              className={`${
                                parseFloat(
                                  getVariacionConverter(
                                    formattedDocumentosAcumAnio["11"]
                                  )
                                ) < 0
                                  ? "error"
                                  : "success"
                              } card-text-align-right  subheading__text-black`}
                            >
                              {getVariacionConverter(
                                formattedDocumentosAcumAnio["11"]
                              )}
                              %
                            </p>
                            <p
                              style={{
                                width: "180px",
                                display: "inline-block",
                                float: "right",
                              }}
                              className="card-text-align-right  subheading__text-black"
                            >
                              {t(
                                "App.CENDashboard.billCENClient.card.mtdValueDoc"
                              )}
                            </p>
                          </div>
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Cantidad de documentos no relacionados
                 */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={6}
                  xl={4}
                  order={{ xs: 6, lg: 4, xl: 6 }}
                >
                  <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                    <CardContent className="card-content">
                      <Box
                        className="box-content scroll-sidebar"
                        height={isPhone ? "350px" : "320px"}
                        overflow={"auto"}
                        style={{
                          flexDirection: "column",
                          justifyContent: "initial",
                          width: "100%",
                        }}
                      >
                        <label className="display-small__text-black">
                          {t(
                            "App.CENDashboard.billCENClient.threeBlock.numberUnrelatedDoc"
                          )}
                        </label>
                        <Grid
                          container
                          rowSpacing={0}
                          style={{
                            marginTop: isPhone ? "14%" : "7%",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "70%",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            alignItems="center"
                            height="60px"
                            width="100%"
                          >
                            <Grid
                              container
                              direction="row"
                              textAlign="center"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                borderRadius: "15px",
                                padding: "10px",
                                backgroundColor: "#d6cce5",
                                height: "60px",
                                width: "100%",
                              }}
                            >
                              <Grid item paddingX={1}>
                                <p className="text-doc-num">SAT</p>
                              </Grid>
                              <Grid item paddingX={1}>
                                {" "}
                                <p className="doc-num">
                                  {formatNumber(
                                    formattedCantidadDocumentosNoRelacionados[
                                      "12"
                                    ]?.find(
                                      (item) => item.dimensiones === "SAT"
                                    ).valor ?? 0
                                  )}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            alignItems={"center"}
                            height="60px"
                            width="100%"
                          >
                            <Grid
                              container
                              direction="row"
                              textAlign="center"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                borderRadius: "15px",
                                padding: "10px",
                                height: "60px",
                                width: "100%",
                                backgroundColor: "#d6cce5",
                              }}
                            >
                              <Grid item paddingX={1}>
                                <p className="text-doc-num">EMPRESA</p>
                              </Grid>
                              <Grid item paddingX={1}>
                                <p className="doc-num">
                                  {formatNumber(
                                    formattedCantidadDocumentosNoRelacionados[
                                      "12"
                                    ]?.find(
                                      (item) => item.dimensiones === "EMPRESA"
                                    ).valor ?? 0
                                  )}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* tabla */}
                <Grid item xs={12} order={{ xs: 7, xl: 7, lg: 7 }}>
                  <Grid container>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                      <label className="display-large__primary-one">
                        {t(
                          "App.CENDashboard.billCENClient.table.transmissionsStatus"
                        )}
                      </label>
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      lg={6}
                      md={7}
                      sm={7}
                      xs={12}
                      textAlign={isTablet || isPhone ? "left" : "end"}
                      justifyContent={isTablet || isPhone ? "left" : "end"}
                      alignItems={"center"}
                      paddingY={0.5}
                      paddingRight={isPhone ? 0 : 1}
                    >
                      <p className="subheading__text-black">
                        {t("App.CENDashboard.billCENClient.table.paragraph")}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xl={2}
                      lg={2}
                      md={4}
                      sm={5}
                      xs={12}
                      textAlign={"end"}
                      justifyContent={"end"}
                    >
                      <Button
                        onClick={() =>
                          openQlikSense(
                            fullNameUser,
                            emailUser,
                            userInfo.linksHome[3].url
                          )
                        }
                        style={{ width: "100%", height: "100%" }}
                        className="button-transmission"
                        startIcon={<KeyboardArrowRightIcon />}
                      >
                        {t(
                          "App.CENDashboard.billCENClient.table.buttonTransmission"
                        )}
                      </Button>
                    </Grid>
                  </Grid>

                  {/* aqui va el div del titulo de la tabla */}
                  <Grid style={{ width: "100%" }} paddingTop={1}>
                    <CacheProvider value={muiCache}>
                      <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                          className="body-dataTable tblFiscalCEN"
                          data={dataTableRaws}
                          columns={dataTableColumns}
                          options={optionsDataTable}
                        />
                      </ThemeProvider>
                    </CacheProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>{" "}
        </Grid>
      ) : (
        <NoInfoDashboard />
      )}
    </Box>
  );
};

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
  Checkbox,
  Check,
  Chip,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateContractedProduct } from "../../../services/admin/adminServices";
import { CODES } from "../../../consts/codes";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import { CreateContact } from "../../../services/user/contactsService";
import { REGEXP } from "../../../consts/regexp";
import { de } from "date-fns/locale";
import { DeployMonthsList } from "../../../services/user/BILayers/requestBILayers";
import { getLstCompanies } from "../../../parts/businessGroup/businessGroupSlice";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../../services/user/deleteAndReload/deleteAndReload";
import ModalInfo from "../Modals/modalInfo";

export const EditMonthsForms = ({
  productOptions,
  optionsLayers,
  handleSubmit,
  control,
  errors,
  setValue,
  reset,
  data,
  setData,
  newData,
  setNewData,
  setDatatableLoading,
  setIsDataLoading,
  setFilterNotFound,
  watch,
  setNoContent,
  setOpenModalDecision,
  setAlliesReloadForm,
  allySelected,
  getValues,
  setFiltersInfo,
  setFilters,
  // companyList,
  country,
}) => {
  /**
   * Const
   */

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [companyList, setCompanyList] = useState([]);
  const [companiesUnaltered, setCompaniesUnaltered] = useState([]);
  const [eanList, setEanList] = useState([]);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [enabledFields, setEnabledFields] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const getCompaniesList = async () => {
    let newCompanies = [];
    // const country = getValues("country").country;

    newCompanies = await fetchTraders(country);
    const eanList = newCompanies.map((item) => item.value);

    const companyList = newCompanies.map((item) => ({
      label: item.label,
      value: item.value,
    }));

    companyList.unshift({
      label: t("App.downloadSalesForce.selectAll"),
      value: t("App.downloadSalesForce.selectAll"),
    });

    setEanList(eanList);
    setCompanyList(companyList);
    setCompaniesUnaltered(companyList);
  };

  /**
   * Use Effect que llama a la lista de empresas
   */
  useEffect(() => {
    getCompaniesList();
  }, []);

  /**
   *
   * Consulta los proveedores de un país específico
   *
   * @param {*} country país a consultar
   * @returns lista de proveedores por país consultado
   */
  const fetchSuppliers = async (country) => {
    try {
      const suppliersService = await GetSuppliersService({ country });
      const responseCode = suppliersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          return suppliersList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error FiltersAdminProducts.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para consultar los comercios de un país específico
   * @param {*} country País seleccionado
   * @returns lista de comercios por país consultado
   */
  const fetchTraders = async (country) => {
    try {
      const tradersService = await GetTradersService({ country });
      const responseCode = tradersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          return tradersList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error FiltersAdminProducts.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Estructura la data entrante en el formato de la tabla
   * @param {Array} data data a formattear
   * @returns data con formato de tabla
   */
  const setDataStructure = (data) => {
    let deployID = "-";

    const processRows = data.map((item) => {
      //Damos un valor al ID de cada input de deploy
      deployID = item.pk + "_" + item.sk;

      return {
        company: !item.companyName ? "-" : item.companyName,
        layer: !item.layer ? "-" : item.layer,
        deployTime: (
          <Grid>
            <Controller
              control={control}
              defaultValue={
                !item.providerExtractMonths ? "-" : item.providerExtractMonths
              } // Si no hay valor, muestra un guion
              id={deployID}
              name={deployID}
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{
                    label: {
                      fontFamily: "Roboto !important",
                      fontSize: "14px !important",
                      translate: "0px -5px",
                      alignItems: "center",
                      padding: "4px",
                      transformOrigin: "-45px -40px",
                      "&.Mui-focused": {
                        color: "#8C8D9D", // Mismo valor para mantener alineación
                      },
                    },
                  }}
                  value={value}
                  min={1}
                  max={999}
                  type="number"
                  inputProps={{
                    sx: {
                      height: "100% !important",
                      width: "100% !important",
                      "&:-webkit-autofill": {
                        backgroundColor: "white !important",
                        boxShadow: "0 0 0 100px white inset !important",
                        borderRadius: "8px",
                      },
                      inputProps: {
                        min: 1,
                        max: 999,
                      },
                    },
                  }}
                  className={
                    errors[`deployTime_${item.id}`]
                      ? "select__filters__errors"
                      : isFieldFilled("deployTime")
                      ? "filters"
                      : "filters__not-filled"
                  }
                  onChange={(e) => {
                    onChange(e.target.value);
                    changeDeployTime(e.target.value, item.pk + "_" + item.sk);
                  }}
                />
              )}
            />
          </Grid>
        ),
      };
    });

    return processRows;
  };

  /**
   * Handles
   */
  const handleDisableFilterButton = () => {
    if (watch("product") && watch("layer")) {
      return false;
    } else {
      return true;
    }
  };

  const handleDisableLayerSeletc = () => {
    if (watch("product")) {
      return false;
    } else {
      return true;
    }
  };

  const handleDisableCompaniesSelect = () => {
    if (watch("product") && watch("layer")) {
      setEnabledFields(true);
      return false;
    } else {
      return true;
    }
  };

  /**
   * Handles the input change of type notification field
   * @param {array} values list of selected countries
   */
  const handleCompaniesChange = (values, reason, detail) => {
    if (values) {
      const withSelectAll = values.map((item) => item.value);
      const withoutSelectAll = values.filter(
        (item) => item.value !== t("App.downloadSalesForce.selectAll")
      );
      //Revisando si selectAll fue deseleccionado
      if (
        reason === "removeOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("company", []);
      }
      //Revisando si selectAll fue seleccionado
      if (
        reason === "selectOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("company", companyList);
      }
      //Revisando si todos los campos a excepcion de selectAll estan seleccionados
      if (
        values.length >= companyList.length - 1 &&
        reason === "selectOption" &&
        !withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("company", companyList);
      }
      //Revisando si se deselecciono alguna opcion
      if (
        reason === "removeOption" &&
        values.length < companyList.length &&
        withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("company", withoutSelectAll);
      }
    } else {
      setValue("company", []);
    }
  };

  const handleFilter = async (data) => {
    console.log("Filtrando");
    try {
      setData([]);
      setNoContent(false);
      setFilterNotFound(true);
      setIsDataLoading(true);
      let companiesWithoutSelectAll = [];

      if (data.company.length < 1) {
        companiesWithoutSelectAll = companyList.map((item) => ({
          companyName: item.label,
          eanCompany: item.value,
        }));
      } else {
        companiesWithoutSelectAll = data.company
          .filter(
            (item) => item.value !== t("App.downloadSalesForce.selectAll")
          )
          .map((item) => ({
            companyName: item.label,
            eanCompany: item.value,
          }));
      }
      let obj = {
        product: data.product.value,
        nameAPP: data.layer.value,
        lstCompanies: companiesWithoutSelectAll,
      };

      const companiesService = await DeployMonthsList(obj);
      obj.nameCompany = data.bussiness;
      setFiltersInfo({
        product: data.product.value,
        layer: data.layer.value,
        nameCompany: data.company,
        idMenuOption: data.product ? data.product.value : null,
      });
      setFilters(obj);
      //Setteando informacion en REDUX de los filtros
      const filterKeys = {
        product: data.product.value,
        layer: data.layer.value,
        company: data.company,
      };
      // Guardando en localstorage los filtros seleccionados por el usuario
      // para que no se borren al recargar la página
      localStorage.setItem(
        "dataKeyFilters",
        JSON.stringify({
          filterKeys,
        })
      );

      if (companiesService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          companiesService.data.responseCode ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          let responseMessage = companiesService.data.responseMessage;
          const formattedData = setDataStructure(responseMessage);
          setData(formattedData);
          setNewData(responseMessage);
          if (responseMessage.length == 0) {
            setFilterNotFound(true);
          } else {
            setFilterNotFound(false);
          }
        }
      } else {
        setModalMessage(companiesService.data.responseMessage);
        setOpenModalInfo(true);
      }

      setIsDataLoading(false);
    } catch (error) {
      setOpenModalInfo(true);
      console.log(
        "============== Error FiltersAdminProducts.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Limpia los filtros y resetea la pantalla de solicitudes
   */
  const handleCleanFilters = () => {
    reset();
    setValue("product", null);
    setValue("layer", null);
    setValue("company", []);
    setNoContent(true);
    setFilterNotFound(true);
  };

  /**
   * Función para editar el tiempo de despliegue de un comercio
   */
  const changeDeployTime = (value, index) => {
    // Actualizamos el estado de newData usando una función de actualización
    setNewData((prevData) => {
      // Creamos una nueva copia del estado anterior
      const updatedData = prevData.map((item) => {
        let currentID = item.pk + "_" + item.sk;
        if (currentID === index) {
          // Modificamos el item que coincide con el índice
          return {
            ...item,
            providerExtractMonths: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const isFieldFilled = (fieldName) => {
    const currentField = watch(fieldName);
    switch (fieldName) {
      case "product":
        return currentField !== null;
      case "layer":
        return currentField !== null;
      case "company":
        return currentField.length !== 0;
      case "deployTime":
        return (
          currentField !== null || currentField !== "" || currentField !== "0"
        );
      default:
        return currentField !== null;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        item
        className="side-line__bottomAlign full-width"
        xl={8}
        lg={8}
        md={9}
        sm={8}
        xs={12}
        sx={{
          padding: "0 !important",
          display: "flex",
          justifyContent: "center",
          maxWidth: "none !important",
          width: "100% !important",
        }}
      >
        <Grid item sx={{ width: "100%", padding: "0px" }}>
          <form
            id="edit-months"
            onSubmit={handleSubmit(handleFilter)}
            style={{ display: "flex" }}
          >
            <Grid
              container
              sx={{ display: "flex", width: "100%" }}
              spacing={2.5}
            >
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="modal-editMonths__filterField"
              >
                <Controller
                  defaultValue={[]}
                  name="product"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      noOptionsText={t("App.listUsers.noOptions")}
                      className={`${
                        errors.product
                          ? "select__filters__errors"
                          : isFieldFilled("product")
                          ? "filters"
                          : "filters__not-filled"
                      } full-width`}
                      options={productOptions}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.value + ""}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label={t("App.BILayers.editMonths.filter.product")}
                            sx={{
                              label: {
                                height: "100% !important",
                                width: "100% !important",
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "-5px -7px",
                                alignItems: "center !important",
                                "&.Mui-focused": {
                                  color: "#8C8D9D !important",
                                },
                              },
                            }}
                          />
                        );
                      }}
                      onChange={(event, values, reason, detail) => {
                        onChange(values);
                      }}
                      value={value || null}
                    />
                  )}
                  control={control}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="modal-editMonths__filterField"
              >
                <Controller
                  control={control}
                  name="layer"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable
                      options={optionsLayers}
                      getOptionLabel={(option) => option.label}
                      noOptionsText={t("App.listUsers.noOptions")}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      disabled={handleDisableLayerSeletc()}
                      className={`${
                        errors.layer
                          ? "select__filters__errors"
                          : isFieldFilled("layer")
                          ? "filters"
                          : "filters__not-filled"
                      } full-width`}
                      renderInput={({ inputProps, ...rest }) => (
                        <TextField
                          {...rest}
                          label={t("App.BILayers.editMonths.filter.layer")}
                          sx={{
                            label: {
                              height: "100% !important",
                              width: "100% !important",
                              fontFamily: "Roboto !important",
                              fontSize: "14px !important",
                              translate: "-5px -7px",
                              alignItems: "center !important",
                              "&.Mui-focused": {
                                color: "#8C8D9D !important",
                              },
                            },
                          }}
                          inputProps={{
                            ...inputProps,
                            readOnly: true,
                            endAdornment: "",
                          }}
                        />
                      )}
                      onChange={(event, values, reason) => {
                        onChange(values);
                      }}
                      value={value || null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="modal-editMonths__filterField"
              >
                <Controller
                  defaultValue={[]}
                  name="company"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={handleDisableCompaniesSelect()}
                      className={
                        errors.bussiness
                          ? "select-contact__filters__errors"
                          : enabledFields
                          ? isFieldFilled("company")
                            ? "filters"
                            : "filters__not-filled"
                          : "filters__disabled"
                      }
                      disableCloseOnSelect
                      options={companyList}
                      multiple
                      limitTags={2}
                      value={value}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.value + ""}>
                          <Checkbox
                            icon={icon}
                            className="autoComplete__checkbox"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />

                          {`${option.label}`}
                        </li>
                      )}
                      onChange={(event, values, reason, detail) => {
                        onChange(values);
                        handleCompaniesChange(values, reason, detail);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            label={t("App.listUsers.company")}
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoadingFields ? (
                                    <CircularProgress
                                      sx={{ color: "#4DCAFA" }}
                                      size={15}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        );
                      }}
                      renderTags={(value, getTagProps) => {
                        const limitTags = 2;
                        const withoutSelectAll = value.filter(
                          (item) =>
                            item.value !== t("App.downloadSalesForce.selectAll")
                        );
                        const numTags = withoutSelectAll.length;
                        return (
                          <>
                            {withoutSelectAll
                              .slice(0, limitTags)
                              .map((option, index) => (
                                <Chip
                                  className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                  sx={{
                                    width: "30%",
                                  }}
                                  key={index}
                                  size="small"
                                  label={`${option.label}`}
                                />
                              ))}
                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                    />
                  )}
                  control={control}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid
        item
        xl={4}
        lg={4}
        md={3}
        sm={4}
        xs={12}
        className="filter-button__container"
      >
        <Grid container direction="row" rowSpacing={0}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} width={"100%"}>
            <Button
              disabled={handleDisableFilterButton()}
              type="submit"
              form="edit-months"
              className={`${
                handleDisableFilterButton()
                  ? "btn__applyFilter-reload-disabled"
                  : "btn__applyFilter-reload"
              } filter-button__apply dimentions-btn__applyFilter`}
              startIcon={<TuneOutlinedIcon />}
            >
              {t("App.catalogue.filter.applyFilters")}
            </Button>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {" "}
            <Button
              onClick={handleCleanFilters}
              className={"btn__deleteFilter dimentions-btn__cleanFilter"}
              sx={{ width: "100%" }}
            >
              {" "}
              {t("App.catalogue.filter.cleanFilters")}
            </Button>{" "}
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          data: { responseMessage: modalMessage },
        }}
        open={openModalInfo}
        onClose={() => {
          setOpenModalInfo(false);
        }}
      />
    </Grid>
  );
};

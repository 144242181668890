import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import dayjs from "dayjs";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { CODES } from "../../../consts/codes";
import { GetBILayers } from "../../../services/user/BILayers/requestBILayers";
import moment from "moment";
import ModalInfo from "../Modals/modalInfo";
import StateComponent from "../stateComponent";
import ModalCancelRequest from "../modalCancelRequest";

export const FilterFormBILayers = ({
  control,
  errors,
  setValue,
  reset,
  watch,
  handleSubmit,
  setChipsFilters,
  setData,
  setDatatableLoading,
  setFilterNotFound,
  setNoContent,
  setFormFilters,
}) => {
  /**
   * Const
   */

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /**
   * Use Translation
   */
  const { t } = useTranslation();

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use State
   */

  // Listas autocomplete
  const [listActions, setListActions] = useState([]);
  const [listStates, setListStates] = useState([]);

  //Boolean cuando la acción está seleccionada
  const [isActionSeleced, setIsActionSelected] = useState(false);

  //Texto para el chip de acción
  const [actionTxt, setActionTxt] = useState("");

  //Modal error general
  const [openModalGeneralError, setOpenModalGeneralError] = useState(false);

  let formFilters;

  // Listas de opciones de autocomplete
  const actions = [
    {
      actionName: t("App.BILayers.request.filter.updates"),
      actionValue: "update",
    },
    {
      actionName: t("App.BILayers.request.filter.deployments"),
      actionValue: "reload",
    },
  ];

  const states = [
    {
      stateName: t("App.BILayers.request.filter.inProcess"),
      stateValue: "pendiente",
    },
    { stateName: t("App.BILayers.request.filter.error"), stateValue: "error" },
    {
      stateName: t("App.BILayers.request.filter.finished"),
      stateValue: "finalizado",
    },
  ];
  /**
   * Use Effect
   */
  useEffect(() => {
    const getAutocompleteOptions = async () => {
      setListActions(actions);
      setListStates(states);
    };
    getAutocompleteOptions();
  }, []);

  /**
   * Valida el estado de la solicitud
   * @param {*} value
   * @returns
   */
  const valueState = (value) => {
    const statesP = [
      "PENDIENTE",
      "PENDIENTE CANCELADO",
      "FINALIZADO",
      "PROCESANDO",
      "EN PROCESO",
      "ENCOLADO POR RECARGAR",
      "CANCELADO",
      "PENDIENTE ENVIO",
    ];
    let text = value;
    if (value === "PENDIENTE CANCELADO") {
      text = "CANCELADO";
    } else if (!statesP.includes(value.toUpperCase())) {
      text = "ERROR";
    }
    return text;
  };

  /**
   * Función que determina si un campo ha sido rellenado o no
   * @param {*} fieldName nombre del campo
   * @returns true si fue rellenado, false si no
   */
  const isFieldFilled = (fieldName) => {
    const currentField = watch(fieldName);
    switch (fieldName) {
      case "action":
        return currentField !== null;
      case "initialDate":
        return currentField !== null;
      case "finalDate":
        return currentField !== null;
      case "state":
        return currentField !== null;
      default:
        return currentField !== null;
    }
  };

  /**
   * Estructura la data entrante en el formato de la tabla
   * @param {Array} data data a formattear
   * @returns data con formato de tabla
   */
  const setDataStructure = (data) => {
    const processRows = data.map((item) => {
      return {
        user: !item.createUser
          ? "-"
          : item.createUser
              .split(" ") // Divide el nombre en palabras
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Pone en mayúscula la inicial de cada palabra
              .join(" "), // Vuelve a unir las palabras,
        date: !item.requestDate ? "-" : item.requestDate,
        supplier: !item.companyName ? "-" : item.companyName,
        layers: !item.layers ? "-" : item.layers,
        app: !item.nameApp ? "-" : item.nameApp,
        stateAutomation: !item.stateAutomation ? "-" : item.stateAutomation,
        stateQvds: !item.stateQvds ? "-" : item.stateQvds,
      };
    });

    return processRows;
  };

  /**
   * Aplica los filtros de solicitudes de capasBI
   * Consulta las solicitudes de recarga de aliados basado en los filtros
   * @param {object} data filtros de formulario
   */
  const handleApplyFilters = async (data) => {
    try {
      setData([]);
      setDatatableLoading(true);
      if (data) {
        formFilters = data;
        setChipsFilters({
          action: manageChipText(data),
        });
        setNoContent(false);
      }
      let obj = {
        action: data.action.actionValue,
        startDate: !data.initialDate
          ? ""
          : dayjs(data.initialDate).format("YYYY-MM-DD"),
        endDate: !data.finalDate
          ? ""
          : dayjs(data.finalDate).format("YYYY-MM-DD"),
        state: data.state ? data.state.stateValue : null,
      };

      setFormFilters(obj);
      const requestApplicationAllies = await GetBILayers(obj);

      const responseCode = requestApplicationAllies.data.responseCode;
      let responseMessage = requestApplicationAllies.data.responseMessage;
      if (responseMessage.length == 0) {
        setFilterNotFound(true);
      } else {
        setFilterNotFound(false);
      }
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          //Setteamos la data de la tabla
          const formattedData = setDataStructure(responseMessage);
          setData(formattedData);
          break;
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          setData([]);
          setFilterNotFound(true);
          break;
        default:
          setData([]);
          setFilterNotFound(true);
          setOpenModalGeneralError(true);
          break;
      }

      setDatatableLoading(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Deshabilita el botón de aplicar filtros
   * @returns true si se deshabilita, false si no se deshabilita
   */

  /**
   * Limpia los filtros y resetea la pantalla de solicitudes
   */
  const handleCleanFilters = () => {
    reset();
    setData([]);
    setIsActionSelected(false);
    setValue("action", null);
    setValue("initialDate", undefined);
    setValue("finalDate", undefined);
    setValue("state", null);
    setChipsFilters({
      action: "",
    });

    setFilterNotFound(false);
    setNoContent(true);
  };

  //Función para cambiar el texto del chip
  const manageChipText = (data) => {
    if (data.action.actionValue === "update") {
      return t("App.BILayers.request.filter.updates");
    } else if (data.action.actionValue === "reload") {
      return t("App.BILayers.request.filter.deployments");
    } else {
      return "";
    }
  };

  //Función para habilitar los campos opcionales
  const disableFields = () => {
    if (watch("action")) {
      return false;
    } else {
      return true;
    }
  };

  // Valor de acción
  const getActionValue = (data) => {
    switch (data.action.actionValue) {
      case "1":
        return "updates";
        break;
      case "2":
        return "deployments";
        break;
      default:
        return "";
        break;
    }
  };

  //Valor del estado
  const getStateValue = (data) => {
    switch (data.state.stateValue) {
      case "1":
        return "inProgress";
        break;
      case "2":
        return "errors";
        break;
      case "3":
        return "finished";
      default:
        return "";
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        item
        xs={12}
        sm={8}
        lg={8.5}
        md={9}
        xl={9}
        paddingRight={{ xs: "0%", sm: "2%" }}
        className="side-line-contact__bottomAlign"
      >
        <form id="request-BILayers" onSubmit={handleSubmit(handleApplyFilters)}>
          <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
            rowSpacing={3}
          >
            <Grid item width={"100%"} className="reloadsForm__block">
              <Grid
                container
                direction="row"
                spacing={2}
                rowSpacing={1}
                className="filter-BILayers__container"
              >
                <Grid
                  item
                  xl={3}
                  lg={6}
                  md={3}
                  sm={12}
                  xs={12}
                  className="filter-BILayers__field-container"
                >
                  <Grid container direction="column">
                    <Grid item sx={{ paddingTop: "2rem !important" }}>
                      <Controller
                        name="action"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.action
                                ? "select-contact__filters__errors__xs"
                                : isFieldFilled("action")
                                ? "filters"
                                : "filters__not-filled"
                            }
                            options={listActions}
                            getOptionLabel={(option) => option.actionName}
                            isOptionEqualToValue={(option, value) =>
                              option.actionValue == value.actionValue
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t(
                                    "App.BILayers.request.filter.action"
                                  )}
                                  sx={{
                                    label: {
                                      height: "100% !important",
                                      width: "100% !important",
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "-5px -7px",
                                      alignItems: "center !important",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D !important",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>
                    {errors?.action && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 0",
                          color: "#e52900",
                        }}
                      >
                        {errors?.action?.action
                          ? errors.action.action.message
                          : errors.action.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  md={3}
                  sm={12}
                  xs={12}
                  className="filter-BILayers__field-container"
                >
                  <Grid container direction="column">
                    <Grid item sx={{ paddingTop: "2rem !important" }}>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="initialDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <DatePicker
                              disabled={disableFields()}
                              inputFormat="YYYY-MM-DD"
                              label={t("App.BILayers.request.filter.startDate")}
                              sx={{
                                paddingTop: "0px !important",
                                height: "100% !important",
                                width: "100% !important",
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "-5 70px !important",
                                alignItems: "center !important",
                              }}
                              className={
                                errors.initialDate
                                  ? "select-contact__filters__errors__xs"
                                  : !disableFields()
                                  ? isFieldFilled("initialDate")
                                    ? "filters"
                                    : "filters__not-filled"
                                  : "filters__disabled"
                              }
                              value={value}
                              slots={{
                                openPickerIcon: CalendarMonthIcon,
                              }}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              components={{
                                OpenPickerIcon: (props) => (
                                  <CalendarMonthIcon
                                    {...props}
                                    className={
                                      errors.initialDate
                                        ? "filters__icon-btn__error"
                                        : !disableFields()
                                        ? isFieldFilled("initialDate")
                                          ? "filters__icon-btn"
                                          : "filters__icon-btn__not-filled"
                                        : "filters__icon-btn__disabled"
                                    }
                                  /> // Color personalizado
                                ),
                              }}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  size="medium"
                                  label={t(
                                    "App.BILayers.request.filter.startDate"
                                  )}
                                  {...params}
                                  sx={{
                                    label: {
                                      paddingTop: "0px !important",
                                      height: "100% !important",
                                      width: "100% !important",
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "-5px -7px",
                                      alignItems: "center !important",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D !important",
                                      },
                                    },
                                    input: {
                                      paddingTop: "3% !important",
                                      width: "100% !important",
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "-5px 3px",
                                      alignItems: "center !important",
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    {errors && errors.initialDate && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.initialDate.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  md={3}
                  sm={12}
                  xs={12}
                  className="filter-BILayers__field-container"
                >
                  <Grid container direction="column">
                    <Grid item sx={{ paddingTop: "2rem !important" }}>
                      <Controller
                        defaultValue={null}
                        control={control}
                        name="finalDate"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          formState,
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              disabled={disableFields()}
                              inputFormat="YYYY-MM-DD"
                              InputProps={{
                                style: {
                                  backgroundColor: "red !important",
                                  paddingTop: "0px !important",
                                },
                              }}
                              label={t("App.BILayers.request.filter.finalDate")}
                              sx={{
                                backgroundColor: "red !impoprtant",
                                height: "100% !important",
                                width: "100% !important",
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "-5px -7px !important",
                                alignItems: "center !important",
                              }}
                              className={
                                errors.finalDate
                                  ? "select-contact__filters__errors__xs"
                                  : !disableFields()
                                  ? isFieldFilled("finalDate")
                                    ? "filters"
                                    : "filters__not-filled"
                                  : "filters__disabled"
                              }
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              components={{
                                OpenPickerIcon: (props) => (
                                  <CalendarMonthIcon
                                    {...props}
                                    className={
                                      errors.initialDate
                                        ? "filters__icon-btn__error"
                                        : !disableFields()
                                        ? isFieldFilled("finalDate")
                                          ? "filters__icon-btn"
                                          : "filters__icon-btn__not-filled"
                                        : "filters__icon-btn__disabled"
                                    }
                                  /> // Color personalizado
                                ),
                              }}
                              renderInput={(params) => (
                                <TextField
                                  autoComplete="off"
                                  size="medium"
                                  label={t(
                                    "App.BILayers.request.filter.finalDate"
                                  )}
                                  {...params}
                                  sx={{
                                    label: {
                                      height: "100% !important",
                                      width: "100% !important",
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "-5px -7px",
                                      alignItems: "center !important",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D !important",
                                      },
                                    },
                                    input: {
                                      paddingTop: "3% !important",
                                      width: "100% !important",
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "-5px 3px",
                                      alignItems: "center !important",
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    {errors && errors.finalDate && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.finalDate.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={6}
                  md={3}
                  sm={12}
                  xs={12}
                  className="filter-BILayers__field-container"
                >
                  <Grid container direction="column">
                    <Grid item sx={{ paddingTop: "2rem !important" }}>
                      <Controller
                        control={control}
                        name="state"
                        render={({ field: { onChange, value }, formState }) => (
                          <Autocomplete
                            disabled={disableFields()}
                            noOptionsText={t("App.listUsers.noOptions")}
                            className={
                              errors.state
                                ? "select-contact__filters__errors__xs"
                                : !disableFields()
                                ? isFieldFilled("state")
                                  ? "filters"
                                  : "filters__not-filled"
                                : "filters__disabled"
                            }
                            options={listStates}
                            getOptionLabel={(option) => option.stateName}
                            isOptionEqualToValue={(option, value) =>
                              option.stateValue == value.stateValue
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  label={t("App.BILayers.request.filter.state")}
                                  sx={{
                                    label: {
                                      height: "100% !important",
                                      width: "100% !important",
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "-5px -7px",
                                      alignItems: "center !important",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D !important",
                                      },
                                    },
                                  }}
                                  {...params}
                                />
                              );
                            }}
                            onChange={(event, values) => {
                              onChange(values);
                            }}
                            value={value || null}
                          />
                        )}
                      />
                    </Grid>
                    {errors && errors.user && (
                      <Grid
                        item
                        sx={{
                          margin: "1px 0 0 6px",
                          color: "#e52900",
                        }}
                      >
                        {errors.user.message}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid
        item
        xl={3}
        lg={3.5}
        md={3}
        sm={4}
        xs={12}
        // className="filter-button__container"
      >
        <Grid container direction="row" rowSpacing={0}>
          <Grid
            item
            xl={7}
            lg={7}
            md={12}
            sm={12}
            xs={12}
            sx={{ width: " 100% !important", justifyContent: "center" }}
          >
            <Button
              sx={{ maxWidth: "none !important", width: " 100% !important" }}
              disabled={disableFields()}
              type="submit"
              form="request-BILayers"
              className={`${
                disableFields()
                  ? "btn__applyFilter-reload-disabled"
                  : "btn__applyFilter-reload"
              } filter-button__apply dimentions-btn__applyFilter`}
              startIcon={<TuneOutlinedIcon />}
            >
              {t("App.catalogue.filter.applyFilters")}
            </Button>
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={12}
            sm={12}
            xs={12}
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={handleCleanFilters}
              className="btn__deleteFilter-reload dimentions-btn__cleanFilter"
              width="100% !important"
            >
              {" "}
              {t("App.catalogue.filter.cleanFilters")}
            </Button>{" "}
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          data: { responseMessage: t("App.validationMessages.systemError2") },
        }}
        open={openModalGeneralError}
        onClose={() => {
          setOpenModalGeneralError(false);
        }}
      />
    </Grid>
  );
};

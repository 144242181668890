import { Grid, Skeleton, IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";
import { FilterIndicators } from "../../../components/common/Filters/filterIndicators";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import ModalUpdateIndicator from "../../../components/common/modalUpdateIndicator";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { CODES } from "../../../consts/codes";

const AdminIndicators = () => {
  //data
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [insight, setInsight] = useState([]);

  //No Selection and No Filter Found
  const [noContent, setNoContent] = useState(true);
  const [isNoFilterFound, setIsNoFilterFound] = useState(false);

  // Error modal
  const [openErrorModal, setOpenErrorModal] = useState(false);

  //Edit
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  //Response modal
  const [isOpenModalResponse, setIsOpenModalResponse] = useState(false);
  const [modalResponseTitle, setModalResponseTitle] = useState("");

  //Filters info
  const [filters, setFilters] = useState({
    country: "",
    eanCompany: "",
    companyName: "",
    typeInsight: [],
  });

  const { t, i18n } = useTranslation();

  const columns = [
    {
      name: "indicatorType",
      label: t("App.indicadors.table.typeInsight"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => value,
      },
    },
    {
      name: "name",
      label: t("App.indicadors.table.nameInsight"),
      options: { filter: false, sort: false, searchable: true },
    },
    {
      name: "description",
      label: t("App.indicadors.table.description"),
      options: { filter: false, sort: false, searchable: false },
    },
    {
      name: "indicatorOrder",
      label: t("App.indicadors.table.order"),
      options: { filter: false, sort: true, searchable: false },
    },
    {
      name: "enabled",
      label: t("App.indicadors.table.enabled"),
      options: { filter: false, sort: false, searchable: false },
    },
    {
      name: "url",
      label: t("App.indicadors.table.URL"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <a
            className="link__primary-one"
            style={{ cursor: "pointer" }}
            target="_blank"
            href={value}
          >
            {value}
          </a>
        ),
      },
    },
    {
      name: "edit",
      label: t("App.adminCompanies.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => {
          return (
            <IconButton
              onClick={() => {
                getInsightInfo(value.nameInsight);
              }}
            >
              <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
            </IconButton>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.indicadors.table.search"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    download: false,
    print: "false",
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.catalogue.table.searchPlaceholder"),
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Get insight information to open edit modal
   * @param {string} nameInsight nameInsight of insight
   */
  const getInsightInfo = async (nameInsight) => {
    const insightFilter = data.filter((item) => item.name === nameInsight);
    if (insightFilter) {
      console.log(insightFilter[0])
      setInsight(insightFilter[0]);
      setIsOpenEditModal(true);
    } else {
      setModalResponseTitle("Error");
      setIsOpenModalResponse(true);
    }
  };

  /**
   * Handle to set de edit information column in the indicator
   * @param {obj} data indicators information
   * @returns
   */
  const setDataStructure = (data) => {
    const finalArray = data.map((item) => {
      return {
        ...item,

        indicatorType: indicatorTypeFilter(item),
        name: i18n.language.includes("es") ? item.name : item.nameEnglish,
        description: i18n.language.includes("es")
          ? item.description
          : item.descriptionEnglish,
        nameSpanish: item.name,
        nameEnglish: item.nameEnglish,
        descriptionSpanish: item.description,
        descriptionEnglish: item.descriptionEnglish,
        product: item.product,
        edit: {
          nameInsight: i18n.language.includes("es")
            ? item.name
            : item.nameEnglish,
          indicatorType: item.indicatorType,
        },
      };
    });
    return finalArray;
  };

  /**
   * Method to set the title by indicatorType
   *
   * @param {String} indicatorType
   * @returns text in its respective language
   */
  const indicatorTypeFilter = (indicator) => {
    let text = "";
    let txt = "";
    const types = ["Commerce Service Level", "Provider Service Level"];
    if (indicator.product) {
      txt = i18n.language.includes("es")
        ? indicator.typeIndicatorEnglish
        : capitalize(indicator.indicatorType);
      if (indicator.product.includes("CEN")) {
        text = t("App.indicatorCards.nameInsightCEN", {
          type: txt,
        });
      } else {
        text =
          types.includes(indicator.indicatorType) &&
          !i18n.language.includes("es")
            ? t("App.indicatorCards.nameInsight2", {
                type: txt,
              })
            : t("App.indicatorCards.nameInsight", {
                type: txt,
              });
      }
    } else {
      text = indicatorName(indicator.indicatorType);
    }
    return text;
  };

  /**
   * Method to capitalize the first letter of a string
   */
  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /**
   * Obtener el nombre del tipo de indicador sin producto
   * @param {*} indicatorType
   * @returns
   */
  const indicatorName = (indicatorType) => {
    let text = "";
    switch (indicatorType) {
      case "sales":
        text = t("App.indicatorCards.saleInsights");
        break;
      case "devolution":
        text = t("App.indicatorCards.returnInsights");
        break;
      case "stock":
        text = t("App.indicatorCards.inventoryInsights");
        break;
      case "invoice":
        text = t("App.indicatorCards.invoice");
        break;
      case "budget":
        text = t("App.indicatorCards.budget");
        break;
      case "serviceleveltrader":
        text = t("App.indicatorCards.traderService");
        break;
      case "servicelevelprovider":
        text = t("App.indicatorCards.providerService");
        break;
      case "home":
        text = t("App.indicatorCards.home");
        break;
      case "reception":
        text = t("App.indicatorCards.reception");
        break;
      case "emission":
        text = t("App.indicatorCards.emission");
        break;
      default:
        text = t("App.indicatorCards.payroll");
        break;
    }
    return text;
  };
  return (
    <Grid>
      <ModalUpdateIndicator
        setOpenErrorModal={setOpenErrorModal}
        open={isOpenEditModal}
        setOpen={setIsOpenEditModal}
        insight={insight}
        setInsight={setInsight}
        filters={filters}
        setData={setData}
        list={data}
        setIsLoadingData={setIsLoadingData}
        setIsNoFilterFound={setIsNoFilterFound}
        setDataStructure={setDataStructure}
      />
      <Grid container="row">
        <Grid item style={{ marginTop: "2%" }}>
          <p className="display-large__primary-one">
            {t("App.indicadors.title")}
          </p>
          <br /> <br />
        </Grid>
      </Grid>

      <Grid
        className="companies_info_container"
        container
        style={{ columnGap: "2%" }}
      >
        <Grid item className="full-width">
          <FilterIndicators
            setOpenErrorModal={setOpenErrorModal}
            setData={setData}
            setIsNoFilterFound={setIsNoFilterFound}
            setNoContent={setNoContent}
            setIsLoadingData={setIsLoadingData}
            setFilters={setFilters}
            setDataStructure={setDataStructure}
            indicatorTypeFilter={indicatorTypeFilter}
          />
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          spacing={1}
          style={{ marginTop: "2%" }}
        ></Grid>

        <Grid item style={{ marginTop: "10px" }} className="full-width">
          {!noContent ? (
            isLoadingData ? (
              <MUIDataTable
                className="dataTable__salesForce datatable-style"
                data={[]}
                columns={columns}
                options={skeletonOptions}
              />
            ) : isNoFilterFound ? (
              <NoMatchImage />
            ) : (
              <MUIDataTable
                className="dataTable__salesForce datatable-style"
                data={data}
                columns={columns}
                options={options}
              />
            )
          ) : (
            <NoSelectionComponent type="adminCompanies" />
          )}
        </Grid>
        {/* Modal para fallo de servicios */}
        <ModalInfo
          title={t("App.validationMessages.error")}
          responseData={{
            status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
            data: {
              responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
              responseMessage: t("App.validationMessages.systemError2"),
            },
          }}
          open={openErrorModal}
          onClose={() => {
            setOpenErrorModal(false);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AdminIndicators;

import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalChangeAllie } from "../../../components/common/Modals/modalChangeAllie";
import { CODES } from "../../../consts/codes";

export const SelectorBILayers = ({
  actionTypeSelected,
  setActionTypeSelected,
  isActionSelected,
  setIsActionSelected,
}) => {
  const { t } = useTranslation();

  /**
   * Use State
   */
  //Lista de aliados
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [allySelected, setAllySelected] = useState(0);

  //Modal cambio de alidos
  const [openModalAllieChange, setOpenModalAllieChange] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    setIsTypeLoading(true);
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      typeAction: null,
    },
  });
  /**
   * Handles
   */

  const isFieldFilled = (fieldName) => {
    const currentField = watch(fieldName);
    switch (fieldName) {
      case "typeAction":
        return currentField !== null;
      default:
        return currentField !== null;
    }
  };

  /**
   * Administra el cambio de selección de aliado
   * @param {*} event evento que contiene el aliado seleccionado
   */
  const handleActionChange = (event) => {
    const action = event.target.value;
    setValue("typeAction", action);
    setActionTypeSelected(action);
    if (action) {
      setIsActionSelected(true);
    } else {
      setIsActionSelected(false);
    }
  };

  return (
    <Grid item className="grid__container__fullW">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className="grid__container__filled__rounded"
      >
        <Grid item>
          <p className="selector-text">{t("App.BILayers.selector")}</p>
        </Grid>

        <Grid
          item
          className="u-marginL-XL"
          xl={3}
          lg={3}
          md={3}
          sm={5.5}
          xs={11}
        >
          <Controller
            control={control}
            name="typeAction"
            defaultValue="placeholder" // Establece el valor inicial como "placeholder"
            render={({ field: { onChange, value }, formState }) => (
              <FormControl fullWidth variant="outlined">
                <Select
                  value={value || "placeholder"} // Asegúrate de que el valor sea "placeholder" inicialmente
                  className={
                    errors.typeAction
                      ? "select-contact__filters__errors"
                      : isFieldFilled("typeAction")
                      ? "filters"
                      : "filters__not-filled"
                  }
                  type="text"
                  onChange={(event) => {
                    handleActionChange(event);
                    onChange(event.target.value); // Actualiza el valor del formulario
                  }}
                >
                  {/* Placeholder con valor único */}
                  <MenuItem value="placeholder" disabled>
                    <em>{t("App.BILayers.selectorPlaceholder")}</em>
                  </MenuItem>

                  {/* Opciones del Select */}
                  <MenuItem value="deploy" key={1}>
                    <em>{t("App.BILayers.selectDeploy")}</em>
                  </MenuItem>
                  <MenuItem value="update" key={2}>
                    <em>{t("App.BILayers.selectUpdate")}</em>
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        {errors && errors.typeAction && (
          <Grid
            item
            sx={{
              margin: "1px 0 0 0",
              color: "#e52900",
            }}
          >
            {errors.typeAction.message}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Line, Bar } from "react-chartjs-2";
import { IconButton } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
} from "chart.js";
import Box from "@mui/material/Box";
import Joyride, { STATUS } from "react-joyride";
import TourIcon from "@mui/icons-material/Tour";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getUser } from "../../userSlice";
import { openQlikSense } from "../../../helpers/helpers";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import WelcomeHome from "../../../components/common/welcomeHome";
import NoInfoDashboard from "../../../components/common/noInfoDashboard";
import FilterBusinessGroup from "../../../components/common/Filters/businessGroup/filterWithButtons";
import {
  IndicatorsChartHomeCEN,
  ListTransmissionsTableCEN,
} from "../../../services/user/dashboard";
import { useNavigate } from "react-router";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IndicatorsCards } from "../indicatorsCards";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title
);

// PARA AGREGAR UN NUEVO HOME SE DEBE IR A IndicatorsCards.jsx Y
// AGREGAR LA INFORMACIÓN DEL NUEVO HOME
export const PayrollFiscalCEN = () => {
  const { t } = useTranslation();
  const chartColors = ["rgba(141, 81, 218, 1)", "rgba(252,176,69,1)"];
  const userInfo = useSelector(getUser);

  const navigate = useNavigate();
  const [isOpenTour, setIsOpenTour] = useState(false);

  const [nominaValorSatData, setNominaValorSat] = useState(null);
  const [nominaValorEmpresaData, setNominaValorEmpresa] = useState(null);
  const [nominaVariacionValorData, setNominaVariacionValor] = useState(null);
  const [nominaDocumentosSatData, setNominaDocumentosSat] = useState(null);
  const [nominaDocumentosEmpresaData, setNominaDocumentosEmpresa] =
    useState(null);
  const [nominaVariacionDocumentosData, setNominaVariacionDocumentos] =
    useState(null);
  const [nominaVigenciaCertificadoData, setNominaVigenciaCertificado] =
    useState(null);

  const [formattedDocumentosMesActual, setFormattedDocumentosMesActual] =
    useState({});
  const [formattedDocumentosAcumAnio, setFormattedDocumentosAcumAnio] =
    useState({});
  const [
    formattedCantidadDocumentosDuplicadosMes,
    setformattedcantidadDocumentosDuplicadosMes,
  ] = useState({});

  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [dataTableRaws, setDataTableRaws] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingButtons, setIsLoadingButtons] = useState(true);
  const [dataTableInfoCEN, setDataTable] = useState([]);
  const [chartsInfoCEN, setCharts] = useState([]);

  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;
  const emailUser = userInfo.email;

  const [isPhone, setIsPhone] = useState(window.innerWidth <= 420);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 768 && window.innerWidth > 420
  );

  /**
   * Función para actualizar el estado  si es tablet o no
   */
  const updateMedia = () => {
    setIsPhone(window.innerWidth <= 420);
    setIsTablet(window.innerWidth <= 768 && window.innerWidth > 420);
  };
  /**
   * Actualizar de acuerdo al tamaño de la pantalla
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  /**
   * Funcion que sirve para settear los valores de las columnas en la tabla de transmisiones
   * @param {obj} dataTable
   * @returns columnas de la tabla
   */
  const buildDataTableColumns = (dataTable) => {
    const columns = [];
    for (const item of dataTable) {
      const skSplited = ([] = item.sk?.split("#") ?? []);
      const documentTypeIndex = skSplited.indexOf("document_type");
      if (
        documentTypeIndex !== -1 &&
        skSplited[documentTypeIndex + 1] === "nomina"
      ) {
        const dimensiones =
          item?.dimensiones !== null || item?.dimensiones !== undefined
            ? item?.dimensiones
            : "Vacio|2000-00-00|2000-00-00";
        const fechacarga =
          item?.fechacarga === null || item?.fechacarga === undefined
            ? "0000-00-00"
            : item?.fechacarga;
        const fecha =
          item?.fecha !== null || item?.fecha !== undefined
            ? item?.fecha
            : "0000-00-00";
        const cantidad = item?.cantidad !== undefined ? item?.cantidad : 0;
        const valor = item?.valor !== undefined ? item?.valor : 0;

        const obj = {
          dimensiones: dimensiones[0],
          fechacarga: fechacarga,
          fecha: fecha,
          cantidad: cantidad,
          valor: valor,
        };
        columns.push(obj);
      } else {
        columns.push(1);
      }
    }
    setDataTable(columns);

    return [
      {
        name: "dimensiones",
        label: t("App.CENDashboard.billCENClient.table.columns.column1"),
        options: {
          sort: true,
        },
      },
      {
        name: "fechacarga",
        label: t("App.CENDashboard.billCENClient.table.columns.column2"),
        options: {
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            const lastTransmission = (
              <div>
                <h4>
                  {t("App.CENDashboard.billCENClient.table.columns.column2")}
                </h4>
                <h5 className="subheading-datatable">
                  {t("App.CENDashboard.billCENClient.table.columns.formatDate")}
                </h5>
              </div>
            );

            return lastTransmission;
          },
        },
      },
      {
        name: "cantidad",
        label: t("App.CENDashboard.billCENClient.table.columns.column3"),
        options: {
          sort: true,
        },
      },
      {
        name: "valor",
        label: t("App.CENDashboard.billCENClient.table.columns.column4"),
        options: {
          sort: true,
        },
      },
      {
        name: "dateAll",
        label: t("App.CENDashboard.billCENClient.table.columns.column5"),
        options: {
          sort: true,
          hint: t("App.CENDashboard.billCENClient.table.columns.toolTip"),
          customBodyRender: (value) => {
            return (
              <Accordion
                disableGutters
                className="custom-accordion-summary__dates"
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  className="custom-accordion-summary"
                  sx={{
                    "& .MuiAccordionSummary-content.MuiAccordionSummary-content":
                      {
                        justifyContent: "center",
                      },
                  }}
                  expandIcon={
                    value.length > 1 ? (
                      <ExpandMoreIcon
                        fontSize="large"
                        className="custom-accordion-summary__icon"
                      />
                    ) : (
                      <></>
                    )
                  }
                >
                  <label className="label-Primary">{value[0]}</label>
                </AccordionSummary>

                <AccordionDetails className="custom-accordion-detail">
                  {value.map((item, idx) => {
                    if (idx <= 4) {
                      return (
                        <label
                          key={idx}
                          className={
                            idx === 0
                              ? "custom-accordion-detail__hidden label-Primary"
                              : "label-Primary"
                          }
                        >
                          {item}
                        </label>
                      );
                    }
                  })}
                  {value.length > 4 && (
                    <>
                      <MoreHorizIcon
                        sx={{ color: "#8c8d9d" }}
                        fontSize="large"
                      />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          },
          customHeadLabelRender: (columnMeta) => {
            const upDates = (
              <div>
                <h4>
                  {t("App.CENDashboard.billCENClient.table.columns.column5")}
                </h4>
                <h5 className="subheading-datatable">
                  {t("App.CENDashboard.billCENClient.table.columns.formatDate")}
                </h5>
              </div>
            );
            return upDates;
          },
        },
      },
    ];
  };

  /**
   * Ordena una lista de fechas en formato 'YYYYMMDD' y elimina las duplicadas.
   *
   * @param {Array} list - La lista de fechas a ordenar.
   * @param {string} fecha - Una fecha adicional a agregar a la lista.
   * @param {string} fechacarga - Otra fecha adicional a agregar a la lista.
   * @returns {Array} La lista de fechas ordenadas y sin duplicados.
   */
  const sortDates = (list, fecha, fechacarga) => {
    list.push(
      moment(fecha, "YYYYMMDD").format("YYYY-MM-DD"),
      moment(fechacarga, "YYYYMMDD").format("YYYY-MM-DD")
    );

    let orderedDates = Array.from(new Set(list.map((date) => date)));

    orderedDates = orderedDates.sort((a, b) =>
      moment(b, "YYYY-MM-DD").diff(moment(a, "YYYY-MM-DD"))
    );

    return orderedDates;
  };

  /**
   * Funcon que sirve para settear los valores de las filas de la tabla
   * @param {obj} dataTable
   * @returns filas de la tabla de
   */
  function buildDataTableRows(dataTable) {
    let uniqueData = [];

    for (const item of dataTable) {
      const skSplited = ([] = item.sk?.split("#") ?? []);
      const documentTypeIndex = skSplited.indexOf("document_type");
      if (
        documentTypeIndex !== -1 &&
        skSplited[documentTypeIndex + 1] === "nomina"
      ) {
        const empresaDimension = item.dimensiones ?? "";
        const employeee = empresaDimension?.split("|") ?? "";

        const _eanProvider = item.ean_provider ?? item.eanProvider;

        if (
          uniqueData.some(
            (unique) =>
              unique.dimensiones === employeee[0] &&
              unique.ean_provider === _eanProvider &&
              unique.country === item.country &&
              unique.indicador === item.indicador
          )
        ) {
          uniqueData = uniqueData.map((unique) => {
            if (
              unique.dimensiones === employeee[0] &&
              unique.ean_provider === _eanProvider &&
              unique.country === item.country &&
              unique.indicador === item.indicador
            )
              return {
                ...unique,
                cantidad: item.cantidad + unique.cantidad,
                valor: formatNumber(
                  parseFloat(item.valor) + parseFloat(unique.valor)
                ),
                dateAll: sortDates(unique.dateAll, item.fecha, item.fechacarga),
              };
            else return unique;
          });
        } else {
          uniqueData.push({
            ean_provider: _eanProvider,
            country: item.country,
            indicador: item.indicador,
            fechacarga: item.fechacarga,
            fecha: item.fecha,
            cantidad: item.cantidad,
            valor: formatNumber(parseFloat(item.valor)),
            dateAll: sortDates([], item.fecha, item.fechacarga),
            dimensiones: employeee[0],
          });
        }
      }
    }

    setDataTableRaws(uniqueData);
    return uniqueData;
  }

  /**
   * Maneja los filtros y realiza las solicitudes de datos.
   * @param {Object} data - Los datos de los filtros.
   */
  const handleFilters = async (data) => {
    try {
      const obj = {
        eanProvider: data.company.eanCompany,
        country: userInfo.company.country,
      };
      setIsLoading(true);
      verificarYRedirigir(userInfo);

      const indicatorsChartService = await IndicatorsChartHomeCEN(obj);
      const listTransmissionsService = await ListTransmissionsTableCEN(obj);

      processData(listTransmissionsService.data, indicatorsChartService.data);

      if (
        listTransmissionsService.data.responseMessage.length === 0 ||
        listTransmissionsService == undefined
      ) {
        const defaultColumns = [
          {
            sk: "transmission#document_type#factura#table",
            indicador: 0,
            cantidad: 0,
            country: "Vacio",
            dimensiones: "Vacio|0000-00-00|0000-00-00",
            eanProvider: "Vacio",
            fecha: "0000-00-00",
            fechacarga: "0000-00-00",
            valor: "0",
          },
          {
            sk: "transmission#document_type#nomina#table",
            indicador: 0,
            cantidad: 0,
            country: "Vacio",
            dimensiones: "Vacio|0000-00-00|0000-00-00",
            eanProvider: "Vacio",
            fecha: "0000-00-00",
            fechacarga: "0000-00-00",
            valor: "0",
          },
        ];

        listTransmissionsService.data.responseMessage = defaultColumns;
      }

      const columns = buildDataTableColumns(
        listTransmissionsService.data.responseMessage
      );

      const rows = buildDataTableRows(
        listTransmissionsService.data.responseMessage
      );

      setDataTableColumns(columns);
      setDataTableRaws(rows);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Función que verifica la información del usuario y redirige según los servicios disponibles.
   * @param {Object} userInfo - Información del usuario.
   */
  const verificarYRedirigir = (userInfo) => {
    if (typeof userInfo === "object") {
      const lstMenuServices = userInfo.lstMenu;

      // Verificar si lstMenuServices contiene los servicios Ventas e Inventarios
      const hasVentasInventariosService = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "Ventas e Inventarios"
      );
      // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de factura"
      const hasCenFiscalFactura = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "CEN Fiscal Factura"
      );
      // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de nómina"
      const hasCenFiscalNomina = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "CEN Fiscal Nómina"
      );

      if (!hasCenFiscalFactura && hasVentasInventariosService) {
        navigate("/user/homePayrollCEN");
      } else if (
        hasCenFiscalFactura &&
        !hasVentasInventariosService &&
        !hasCenFiscalNomina
      ) {
        navigate("/user/homeBill");
      }
    }
  };

  /**
   * Formatea un número para su visualización.
   * @param {number} value - El número a formatear.
   * @returns {string} El número formateado como una cadena.
   */
  const formatNumber = (value) => {
    if (value >= 1000000000) {
      return `${(value / 1000000000).toFixed(1)}B`;
    } else if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `${value.toFixed(1)}`;
    } else {
      return value.toFixed(1);
    }
  };

  /**
   * Formatea los indicadores para su visualización.
   * @param {Object} separatedIndicators - Los indicadores separados por tipo.
   * @returns {Object} Los indicadores formateados.
   */
  function formatIndicators(separatedIndicators) {
    const nomina_valor_sat = separatedIndicators.nomina_valor_sat.reduce(
      (acc, item) =>
        acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
      0
    );
    const nomina_valor_empresa =
      separatedIndicators.nomina_valor_empresa.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const nomina_variacion_valor =
      separatedIndicators.nomina_variacion_valor.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const nomina_documentos_sat =
      separatedIndicators.nomina_documentos_sat.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const nomina_documentos_empresa =
      separatedIndicators.nomina_documentos_empresa.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    const nomina_variacion_documentos =
      separatedIndicators.nomina_variacion_documentos.reduce(
        (acc, item) =>
          acc + (isNaN(parseFloat(item.valor)) ? 0 : parseFloat(item.valor)),
        0
      );
    let nomina_vigencia_certificado;

    if (
      separatedIndicators.nomina_vigencia_certificado === undefined ||
      separatedIndicators.nomina_vigencia_certificado.length === 0
    ) {
      nomina_vigencia_certificado = [
        {
          valor: "Certificado pendiente",
          fecha: "0",
        },
      ];
    } else {
      nomina_vigencia_certificado =
        separatedIndicators.nomina_vigencia_certificado.reduce((acc, item) => {
          const fechaOriginal = item.fecha;
          const fechaFormateada = `${fechaOriginal.substring(
            0,
            4
          )}-${fechaOriginal.substring(4, 6)}-${fechaOriginal.substring(6, 8)}`;
          acc.push({
            valor: item.valor,
            fecha: fechaFormateada,
          });
          return acc;
        }, []);
    }

    const nomina_valor_sat_formatted = formatNumber(nomina_valor_sat);
    const nomina_valor_empresa_formatted = formatNumber(nomina_valor_empresa);
    const nomina_variacion_valor_formatted = formatNumber(
      nomina_variacion_valor
    );
    const nomina_documentos_sat_formatted = formatNumber(nomina_documentos_sat);
    const nomina_documentos_empresa_formatted = formatNumber(
      nomina_documentos_empresa
    );
    const nomina_variacion_documentos_formatted = formatNumber(
      nomina_variacion_documentos
    );

    return {
      nominaValorSat: nomina_valor_sat_formatted,
      nominaValorEmpresa: nomina_valor_empresa_formatted,
      nominaVariacionValor: nomina_variacion_valor_formatted,
      nominaDocumentosSat: nomina_documentos_sat_formatted,
      nominaDocumentosEmpresa: nomina_documentos_empresa_formatted,
      nominaVariacionDocumentos: nomina_variacion_documentos_formatted,
      nominaVigenciaCertificado: nomina_vigencia_certificado,
    };
  }

  /**
   * Procesa los datos de la tabla y los gráficos.
   * @param {Object} dataTableInfoCEN - La información de la tabla.
   * @param {Object} chartsInfoCEN - La información de los gráficos.
   */
  function processData(dataTableInfoCEN, chartsInfoCEN) {
    const separatedIndicators = separateIndicators(
      chartsInfoCEN.responseMessage
    );
    const formattedIndicators = formatIndicators(separatedIndicators);

    setNominaValorSat(formattedIndicators.nominaValorSat);
    setNominaValorEmpresa(formattedIndicators.nominaValorEmpresa);
    setNominaVariacionValor(formattedIndicators.nominaVariacionValor);
    setNominaDocumentosSat(formattedIndicators.nominaDocumentosSat);
    setNominaDocumentosEmpresa(formattedIndicators.nominaDocumentosEmpresa);
    setNominaVariacionDocumentos(formattedIndicators.nominaVariacionDocumentos);
    setNominaVigenciaCertificado(formattedIndicators.nominaVigenciaCertificado);

    const separatedCharts = groupCharts(chartsInfoCEN.responseMessage);
    const formattedDocumentosMesActual = formatCharts(
      separatedCharts?.documentosMesActual
    );
    setFormattedDocumentosMesActual(formattedDocumentosMesActual);
    const formattedDocumentosAcumAnio = formatCharts(
      separatedCharts?.documentosAcumAnio
    );
    setFormattedDocumentosAcumAnio(formattedDocumentosAcumAnio);
    const formattedcantidadDocumentosDuplicadosMes = formatCharts(
      separatedCharts?.cantidadDocumentosDuplicadosMes
    );
    setformattedcantidadDocumentosDuplicadosMes(
      formattedcantidadDocumentosDuplicadosMes
    );

    setCharts(chartsInfoCEN.responseMessage);
    setDataTable(dataTableInfoCEN.responseMessage);
  }

  /**
   * Agrupa los datos de los gráficos por tipo.
   * @param {Array} data - Los datos a agrupar.
   * @returns {Object} Un objeto con los datos agrupados por tipo de gráfico.
   */
  const groupCharts = (data) => {
    const separatedData = data.reduce((acc, item) => {
      const key = item.sk.split("#")[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const groups = {
      documentosMesActual: {
        keys: ["nomina_documentos_mes_actual"],
        data: [],
        indicator: 7,
      },
      documentosAcumAnio: {
        keys: ["nomina_documentos_anio"],
        data: [],
        indicator: 8,
      },
      cantidadDocumentosDuplicadosMes: {
        keys: ["nomina_documentos_duplicados_mes"],
        data: [],
        indicator: 9,
      },
    };

    Object.entries(separatedData).forEach(([key, value]) => {
      Object.values(groups).forEach((group) => {
        if (group.keys.includes(key)) {
          group.data.push(value);
        }
      });
    });

    const groupedCharts = {};

    Object.entries(groups).forEach(([key, group]) => {
      groupedCharts[key] = group.data;
    });

    Object.keys(groups).forEach((key) => {
      if (groupedCharts[key].length === 0) {
        groupedCharts[key] = [
          [
            {
              sk: `${groups[key].keys}#document_type#nominas#id_record#${groups[key].indicator}`,
              indicador: 0,
              country: "Vacio",
              dimensiones: "VACIO|2000|01",
              eanProvider: "Vacio",
              fecha: "Vacio",
              valor: "0.0",
            },
            {
              sk: `${groups[key].keys}#document_type#nominas#id_record#${groups[key].indicator}`,
              indicador: 0,
              country: "Vacio",
              dimensiones: "VACIO|2000|01",
              eanProvider: "Vacio",
              fecha: "Vacio",
              valor: "0.0",
            },
          ],
        ];
      }
    });

    return groupedCharts;
  };

  /**
   * Formatea los datos de los gráficos para su visualización.
   * @param {Array} data - Los datos a formatear.
   * @returns {Object} Los datos formateados.
   */
  const formatCharts = (data) => {
    const separatedData = data[0].reduce((acc, item) => {
      const key = item.sk.split("#")[4];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push({
        dimensiones: item.dimensiones,
        fecha: item.fecha,
        valor: parseFloat(item.valor),
      });
      return acc;
    }, {});

    return separatedData;
  };

  /**
   * Builds a new object for charts based on indicators types.
   * @param {*} data Indicators items
   * @returns Formatted indicators object
   */
  const separateIndicators = (data) => {
    const separatedData = data.reduce((acc, item) => {
      const key = item.sk.split("#")[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const nomina_valor_sat = separatedData["nomina_valor_sat"] || [];
    const nomina_valor_empresa = separatedData["nomina_valor_empresa"] || [];
    const nomina_variacion_valor =
      separatedData["nomina_variacion_valor"] || [];
    const nomina_documentos_sat = separatedData["nomina_documentos_sat"] || [];
    const nomina_documentos_empresa =
      separatedData["nomina_documentos_empresa"] || [];
    const nomina_variacion_documentos =
      separatedData["nomina_variacion_documentos"] || [];
    const nomina_vigencia_certificado =
      separatedData["nomina_vigencia_certificado"] || [];

    return {
      nomina_valor_sat,
      nomina_valor_empresa,
      nomina_variacion_valor,
      nomina_documentos_sat,
      nomina_documentos_empresa,
      nomina_variacion_documentos,
      nomina_vigencia_certificado,
    };
  };

  /**
   * Triggers actions based on users selection into the joyride
   * @param {*} data
   */
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsOpenTour(false);
    }
  };

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            footer: {
              border: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "center",
            },
            toolButton: {
              // * Secreto para que los headers del datatable queden centrados con el contenido
              margin: "0",
              padding: "0",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
            },
          },
        },
      },
    });

  //* Cantidad de documentos por mes actual
  const optionsBarHorizontal = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,

    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex].toFixed(1);
            return value;
          },
        },
      },
    },

    scales: {
      y: {
        stacked: true,
      },
      x: {
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  //* Cantidad de documentos por mes actual
  const dataDocumentosMesActual = {
    labels: formattedDocumentosMesActual["7"]?.map((item) => item.fecha),
    datasets: [
      {
        label: "SAT",
        data: formattedDocumentosMesActual["7"]?.map((item) =>
          item.dimensiones === "SAT" ? item.valor : 0
        ),
        backgroundColor: chartColors[0],
      },
      {
        label: "EMPRESA",
        data: formattedDocumentosMesActual["7"]?.map((item) =>
          item.dimensiones === "EMPRESA" ? item.valor : 0
        ),
        backgroundColor: chartColors[1],
      },
    ],
  };

  //* Cantidad de documentos acumulados por año
  const dataDocumentosAcumAnio = {
    labels: formattedDocumentosAcumAnio["8"]?.map((item) => item.fecha),
    datasets: [
      {
        label: "SAT",
        data: formattedDocumentosAcumAnio["8"]?.map((item) =>
          item.dimensiones === "SAT" ? item.valor : 0
        ),
        backgroundColor: chartColors[0],
      },
      {
        label: "EMPRESA",
        data: formattedDocumentosAcumAnio["8"]?.map((item) =>
          item.dimensiones === "EMPRESA" ? item.valor : 0
        ),
        backgroundColor: chartColors[1],
      },
    ],
  };

  const formatValue = (value) => {
    if (typeof value !== "number") {
      return value;
    }

    const _format = value.toFixed(0);

    return _format.replace(",", ".");
  };

  const optionsLineCharts = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex];
            return formatValue(value.y);
          },
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: 12,
            display: true,
          },
        },
      ],
      y: {
        ticks: {
          stacked: true,
          beginAtZero: true,
          callback: function (value) {
            return formatValue(value);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const getVariacionConverter = (tabla) => {
    const satValues = tabla.filter((item) => item.dimensiones === "SAT");
    const empresaValues = tabla.filter(
      (item) => item.dimensiones === "EMPRESA"
    );

    const satFechaMasReciente = satValues.reduce((prev, current) =>
      prev.fecha > current.fecha ? prev : current
    );
    const empresaFechaMasReciente = empresaValues.reduce((prev, current) =>
      prev.fecha > current.fecha ? prev : current
    );

    const satValorMasReciente = parseFloat(satFechaMasReciente.valor);
    const empresaValorMasReciente = parseFloat(empresaFechaMasReciente.valor);
    const resultado = satValorMasReciente / empresaValorMasReciente - 1;
    return resultado.toFixed(1);
  };

  const getDimentions = (dimensiones) => {
    if (dimensiones !== null && dimensiones !== undefined) {
      const _dimensiones = dimensiones.split("|");

      return {
        dimensiones: _dimensiones[0],
        year: _dimensiones[1],
        monthText:
          t(`App.CENDashboard.billCENClient.months.${_dimensiones[2]}`) +
          "-" +
          _dimensiones[1].slice(-2),
        month: _dimensiones[2],
      };
    }
  };
  let _formattedCantidadDocumentosDuplicadosMes = [];
  if (
    formattedCantidadDocumentosDuplicadosMes !== null &&
    formattedCantidadDocumentosDuplicadosMes !== undefined
  ) {
    _formattedCantidadDocumentosDuplicadosMes =
      formattedCantidadDocumentosDuplicadosMes["9"]
        ?.map(({ dimensiones, fecha, valor }) => {
          return {
            dimensiones: getDimentions(dimensiones),
            fecha,
            valor,
          };
        })
        ?.sort((a, b) => a.dimensiones.month - b.dimensiones.month);
  }

  _formattedCantidadDocumentosDuplicadosMes =
    _formattedCantidadDocumentosDuplicadosMes;

  const getConverterCantidadDocumentosDuplicadosMes = () => {
    if (
      _formattedCantidadDocumentosDuplicadosMes !== null &&
      _formattedCantidadDocumentosDuplicadosMes !== undefined
    ) {
      const y = _formattedCantidadDocumentosDuplicadosMes.reduce(
        (acc, item) => {
          const etiqueta =
            item.dimensiones.dimensiones === "SAT" ? "SAT" : "EMPRESA";
          if (!acc[etiqueta]) {
            acc[etiqueta] = {
              label: etiqueta,
              data: [],
              borderColor:
                etiqueta === "SAT" ? "rgb(138, 80, 113)" : "rgb(68, 103, 123)",
              backgroundColor:
                etiqueta === "SAT" ? "rgb(138, 80, 113)" : "rgb(68, 103, 123)",
              fill: false,
              tension: 0.1,
            };
          }
          acc[etiqueta].data.push({
            y: item.valor,
            x: item.dimensiones.monthText,
          });

          return acc;
        },
        {}
      );

      return {
        labels: [],
        datasets: Object.values(y),
      };
    }

    return {};
  };

  /**
   * Transmitions datatable values
   */
  const optionsDataTable = {
    download: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    filter: false,
    rowHover: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: t("App.salesForce.placeholder"),
    elevation: 0,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: t("App.validationMessages.noMatchFilter"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Joyride steps as html elements
   */
  const steps = [
    {
      target: ".step-1",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title1")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 1/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content1")}
          </p>
        </div>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".step-2",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title2")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 2/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content2")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".step-3",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title3")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 3/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content3")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-0",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title4")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 4/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content4")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-1",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title5")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 5/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content5")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-2",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title6")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 6/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content6")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  return (
    <Box className="box-container">
      {userInfo?.company?.eanCompany !== "CASSANDRA" && (
        <>
          <Joyride
            callback={handleJoyrideCallback}
            run={isOpenTour}
            continuous={true}
            showSkipButton={true}
            hideCloseButton={true}
            steps={steps}
            locale={{
              skip: (
                <Button variant="text" className="button-skip">
                  {t("App.homeDashboard.coachmark.buttons.skip")}
                </Button>
              ),
              next: (
                <Button
                  startIcon={<ArrowForwardOutlinedIcon />}
                  className="button-next"
                >
                  {t("App.homeDashboard.coachmark.buttons.next")}
                </Button>
              ),
              back: (
                <Button className="button-back" variant="outlined">
                  {t("App.homeDashboard.coachmark.buttons.back")}
                </Button>
              ),
              last: (
                <Button className="button-last">
                  {t("App.homeDashboard.coachmark.buttons.finish")}
                </Button>
              ),
            }}
            styles={{
              arrowColor: "#fff",
              backgroundColor: "#fff",
              beaconSize: 36,
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: "#f04",
              spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
              textColor: "#333",
              width: undefined,
              zIndex: 100,
            }}
          />
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center">
                <IconButton
                  aria-label="delete"
                  onClick={() => setIsOpenTour(true)}
                >
                  <TourIcon
                    fontSize="large"
                    className="heading__primary-color"
                  />
                </IconButton>

                <label className="display-large__primary-one">
                  {t("App.CENDashboard.payrollCENClient.welcomeTitle")}
                </label>
              </Grid>
            </Grid>

            <Grid item lg={8} md={8} xs={12} sm={12} justifyContent="flex-end">
              <Grid container spacing={2} justifyContent={"flex-end"}>
                <IndicatorsCards
                  excludeHome={"payroll"}
                  setIsLoadingButtons={setIsLoadingButtons}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        item
        sx={{
          width: "100%",
          padding: "1%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title")}
          handleRequestIndicators={handleFilters}
          setIsLoading={setIsLoading}
          typeOption={5}
        />
      </Grid>
      {userInfo?.company?.eanCompany === "CASSANDRA" ? (
        <WelcomeHome />
      ) : isLoading || isLoadingButtons ? (
        <CustomSkeleton />
      ) : dataTableInfoCEN.length > 0 && chartsInfoCEN.length > 0 ? (
        <>
          <Grid item width="100%">
            <Grid container>
              <Grid item width="100%">
                <Grid container spacing={2} width="100%">
                  <Grid item xs={12} xl={1.7} lg={4} sm={6}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className="subheading__text-black">
                              {t(
                                "App.CENDashboard.payrollCENClient.firstBlock.payrollSATAccum"
                              )}
                            </label>
                          </div>

                          <label
                            className="display-small__text-blue3"
                            style={{ fontSize: "2.5rem" }}
                          >
                            {nominaValorSatData !== null
                              ? `${nominaValorSatData}`
                              : "-"}
                          </label>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} xl={1.7} lg={4} sm={6}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className="subheading__text-black">
                              {t(
                                "App.CENDashboard.payrollCENClient.firstBlock.payrollCompanyAccum"
                              )}
                            </label>
                          </div>

                          <label
                            className="display-small__text-blue3"
                            style={{ fontSize: "2.5rem" }}
                          >
                            {nominaValorEmpresaData !== null
                              ? `${nominaValorEmpresaData}`
                              : "-"}
                          </label>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xl={1.7} lg={4} xs={12} sm={6}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className="subheading__text-black">
                              {t(
                                "App.CENDashboard.payrollCENClient.firstBlock.valueVarPercentage"
                              )}
                            </label>
                          </div>
                          <label
                            className={`display-small${
                              nominaVariacionValorData !== null
                                ? nominaVariacionValorData.includes("-")
                                  ? "__warning"
                                  : "__text-blue3"
                                : ""
                            }`}
                            style={{ fontSize: "2.5rem" }}
                          >
                            {nominaVariacionValorData !== null
                              ? `${nominaVariacionValorData}%`
                              : "-"}
                          </label>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xl={1.7} lg={3} xs={12} sm={6}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className="subheading__text-black">
                              {t(
                                "App.CENDashboard.payrollCENClient.firstBlock.docSAT"
                              )}
                            </label>
                          </div>
                          <label
                            className="display-small__text-blue3"
                            style={{ fontSize: "2.5rem" }}
                          >
                            {nominaDocumentosSatData !== null
                              ? `${nominaDocumentosSatData}`
                              : "-"}
                          </label>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xl={1.7} lg={3} xs={12} sm={4}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className="subheading__text-black">
                              {t(
                                "App.CENDashboard.payrollCENClient.firstBlock.docCompany"
                              )}
                            </label>
                          </div>
                          <label
                            className="display-small__text-blue3"
                            style={{ fontSize: "2.5rem" }}
                          >
                            {nominaDocumentosEmpresaData !== null
                              ? `${nominaDocumentosEmpresaData}`
                              : "-"}
                          </label>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xl={1.7} lg={3} xs={12} sm={4}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className="subheading__text-black">
                              {t(
                                "App.CENDashboard.payrollCENClient.firstBlock.valueVarQuantity"
                              )}
                            </label>
                          </div>
                          <label
                            className={`display-small${
                              nominaVariacionDocumentosData !== null
                                ? nominaVariacionDocumentosData.includes("-")
                                  ? "__warning"
                                  : "__text-blue3"
                                : ""
                            }`}
                            style={{ fontSize: "2.5rem" }}
                          >
                            {nominaVariacionDocumentosData !== null
                              ? `${nominaVariacionDocumentosData}%`
                              : "-"}
                          </label>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xl={1.7} lg={3} xs={12} sm={4}>
                    <Card
                      style={{
                        borderRadius: "16px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            height: "6rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <div style={{ marginBottom: "1rem" }}>
                            <label className={`subheading__text-black`}>
                              {nominaVigenciaCertificadoData[0].valor ===
                              "Vigente"
                                ? "Certificado Vigente"
                                : nominaVigenciaCertificadoData[0].valor}
                            </label>
                          </div>
                          <div>
                            <label
                              style={{ fontSize: "2.5rem" }}
                              className={`display-small__text-blue3`}
                            >
                              {nominaVigenciaCertificadoData[0].fecha}
                            </label>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                      <CardContent className="card-content">
                        <Box
                          className="box-content__bar scroll-sidebar"
                          height={isPhone ? "350px" : "320px"}
                          overflow={"auto"}
                        >
                          <label className="display-small__text-black">
                            {t(
                              "App.CENDashboard.payrollCENClient.secondBlock.docCantCurrentMonth"
                            )}
                          </label>

                          <div style={{ height: "250px", paddingTop: "20px" }}>
                            <Bar
                              options={optionsBarHorizontal}
                              data={dataDocumentosMesActual}
                            />
                            <div style={{ padding: "20px" }}>
                              <p
                                style={{ width: "60px", float: "right" }}
                                className={`${
                                  parseFloat(
                                    getVariacionConverter(
                                      formattedDocumentosMesActual["7"]
                                    )
                                  ) < 0
                                    ? "error"
                                    : "success"
                                } card-text-align-right  subheading__text-black`}
                              >
                                {getVariacionConverter(
                                  formattedDocumentosMesActual["7"]
                                )}
                                %
                              </p>
                              <p
                                style={{
                                  width: "180px",
                                  display: "inline-block",
                                  float: "right",
                                }}
                                className="card-text-align-right  subheading__text-black"
                              >
                                {t(
                                  "App.CENDashboard.payrollCENClient.card.ytdCantDoc"
                                )}
                              </p>
                            </div>
                          </div>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} lg={4} overflow={"auto"}>
                    <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                      <CardContent className="card-content">
                        <Box
                          className="box-content__bar scroll-sidebar"
                          height={isPhone ? "350px" : "320px"}
                          overflow={"auto"}
                        >
                          <label className="display-small__text-black">
                            {t(
                              "App.CENDashboard.payrollCENClient.secondBlock.docCantAccumuYear"
                            )}
                          </label>

                          <div style={{ height: "250px", paddingTop: "20px" }}>
                            <Bar
                              options={optionsBarHorizontal}
                              data={dataDocumentosAcumAnio}
                            />
                            <div style={{ padding: "20px" }}>
                              <p
                                style={{ width: "60px", float: "right" }}
                                className={`${
                                  parseFloat(
                                    getVariacionConverter(
                                      formattedDocumentosAcumAnio["8"]
                                    )
                                  ) < 0
                                    ? "error"
                                    : "success"
                                } card-text-align-right  subheading__text-black`}
                              >
                                {getVariacionConverter(
                                  formattedDocumentosAcumAnio["8"]
                                )}
                                %
                              </p>
                              <p
                                style={{
                                  width: "180px",
                                  display: "inline-block",
                                  float: "right",
                                }}
                                className="card-text-align-right  subheading__text-black"
                              >
                                {t(
                                  "App.CENDashboard.payrollCENClient.card.mtdValueDoc"
                                )}
                              </p>
                            </div>
                          </div>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                      <CardContent className="card-content">
                        <Box
                          className="box-content__bar scroll-sidebar"
                          height={isPhone ? "350px" : "320px"}
                          overflow={"auto"}
                        >
                          <label className="display-small__text-black">
                            {t(
                              "App.CENDashboard.billCENClient.secondBlock.numberDocByMounth"
                            )}
                          </label>

                          {isPhone ? (
                            <div
                              style={{
                                height: "320px",
                                width: "375px",
                                overflowX: "auto",
                                border: "1px solid #e0e0e0",
                              }}
                            >
                              <Line
                                options={optionsLineCharts}
                                data={getConverterCantidadDocumentosDuplicadosMes()}
                              />
                            </div>
                          ) : (
                            <div style={{ height: "290px", overflowX: "auto" }}>
                              <Line
                                options={optionsLineCharts}
                                data={getConverterCantidadDocumentosDuplicadosMes()}
                              />
                            </div>
                          )}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <label className="display-large__primary-one">
                          {t(
                            "App.CENDashboard.billCENClient.table.transmissionsStatus"
                          )}
                        </label>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={7}
                        sm={7}
                        xs={12}
                        textAlign={isTablet || isPhone ? "left" : "end"}
                        justifyContent={isTablet || isPhone ? "left" : "end"}
                        alignItems={"center"}
                        paddingY={0.5}
                        paddingRight={isPhone ? 0 : 1}
                      >
                        <p className="subheading__text-black">
                          {t("App.CENDashboard.billCENClient.table.paragraph")}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xl={2}
                        lg={2}
                        md={4}
                        sm={5}
                        xs={12}
                        textAlign={"end"}
                        justifyContent={"end"}
                      >
                        <Button
                          onClick={() =>
                            openQlikSense(
                              fullNameUser,
                              emailUser,
                              userInfo.linksHome[3].url
                            )
                          }
                          style={{ width: "100%", height: "100%" }}
                          className="button-transmission"
                          startIcon={<KeyboardArrowRightIcon />}
                        >
                          {t(
                            "App.CENDashboard.billCENClient.table.buttonTransmission"
                          )}
                        </Button>
                      </Grid>
                    </Grid>

                    {/* aqui va el div del titulo de la tabla */}
                    <Grid style={{ width: "100%" }} paddingTop={1}>
                      <CacheProvider value={muiCache}>
                        <ThemeProvider theme={getMuiTheme()}>
                          <MUIDataTable
                            className="body-dataTable tblFiscalCEN"
                            data={dataTableRaws}
                            columns={dataTableColumns}
                            options={optionsDataTable}
                          />
                        </ThemeProvider>
                      </CacheProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <NoInfoDashboard />
      )}
    </Box>
  );
};

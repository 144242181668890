import { Button, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccountsOutlined";
import MUIDataTable from "mui-datatables";
import Table from "@mui/material/Table";
import { useState, useEffect } from "react";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import { DeployCompany } from "../../../services/user/BILayers/requestBILayers";
import { CODES } from "../../../consts/codes";
import moment from "moment";
import { getUser, getUserStatus } from "../../userSlice";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import {
  fetchCatalogueDownload,
  getCatalogueDownload,
  getCatalogueDownloadStatus,
  resetCatalogueDownloadState,
} from "../../../parts/catalogue/catalogueSlice";
import { NoInfoComponent } from "../../../components/common/NoInfoImage/noInfoComponent";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import { set } from "date-fns";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";

const DeployNewClient = (props) => {
  const { t } = useTranslation();

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "company",
      label: t("App.catalogue.table.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "eanCompany",
      label: t("App.BILayers.deployNewUser.table.eanCompany"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "app",
      label: t("App.BILayers.filter.app"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "layer",
      label: t("App.BILayers.filter.layer"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "deployTime",
      label: t("App.BILayers.deployNewUser.table.deployTime"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
  ];

  const handleAgree = async () => {
    setIsOpenModal(false);
    try {
      let obj = {
        qlikUpdateArray: props.messageData,
        createUser: userInfo.createUser,
      };

      const deployCompanies = await DeployCompany(obj);
      const responseCode = deployCompanies.data.responseCode;
      let responseMessage = deployCompanies.data.responseMessage;

      if (
        deployCompanies.status === CODES.COD_RESPONSE_HTTP_OK &&
        deployCompanies.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = deployCompanies.data.responseMessage;
        setIsOpenModalInfo(true);
        setModalTitle(t("App.BILayers.deployNewUser.modal.title"));
        setInfoContent({
          data: {
            responseCode: responseCode,
            responseMessage: (
              <span>
                <p>
                  {t("App.BILayers.deployNewUser.modal.modalInfoContent1")}
                  <br />
                  <br />
                  {t("App.BILayers.deployNewUser.modal.modalInfoContent2")}
                  <span className="bold">
                    {t("App.BILayers.updateBILayers.modal.modalInfoContent3")}
                  </span>
                </p>
              </span>
            ),
          },
        });
      } else {
        setIsOpenModalInfo(true);
      }
    } catch (error) {
      console.log(
        "============== Error FiltersAdminProducts.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDisagree = () => {
    setIsOpenModal(false);
  };

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        // noMatch: t("App.validationMessages.noMatchFilter"),
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  //Usuario
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  //Archivos de descarga
  const catalogueDownload = useSelector(getCatalogueDownload);
  const catalogueDownloadStatus = useSelector(getCatalogueDownloadStatus);

  //Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */

  //Campo de tabla
  const [noDownloadCatalogue, setNoDownloadCatalogue] = useState(false);

  //Modal respuesta de descarga
  const [downloadTitle, setDownloadTitle] = useState("");
  const [downloadResponseData, setDownloadResponseData] = useState({});
  const [infoContent, setInfoContent] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);

  //Modal desplegar empresas
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);

  //Carga visual
  const [isLoadingDownloadRequest, setIsLoadingDownloadRequest] =
    useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Grupo empresarial
  const [showDownload, setShowDownload] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  /**
   * useEffect para manejar la actualización de la información de la tabla
   */
  useEffect(() => {
    try {
      if (userInfo) {
        handleUpdateTable();
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }, [userStatus, dispatch, catalogueDownloadStatus]);

  /**
   * Use Effect para mostrar por default el componente de carga si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [isBusinessGroup]);

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * Función para estructurar la información a mostrar en la tabla
   *
   * @param {Object} catalogue celda de catálogo
   * @returns la información estructurada para mostrar en la tabla
   */
  const cellStructure = (catalogue) => {
    return {
      initialUploadDate: !catalogue.starDate
        ? " "
        : moment(catalogue.starDate).format("YYYY-MM-DD hh:mm"),
      finalUploadDate: !catalogue.endDate
        ? "  "
        : moment(catalogue.endDate).format("YYYY-MM-DD hh:mm"),
      user: !catalogue.userLoad ? "" : catalogue.userLoad,
      company: !catalogue.company ? "" : catalogue.company,
      file: !catalogue.fileName
        ? ""
        : {
            fileName: catalogue.fileName,
            pathFileName: catalogue.pathFileName,
            state: !catalogue.state ? "" : catalogue.state,
          },
      state: !catalogue.state ? "" : catalogue.state,
    };
  };

  /**
   * Función para actualizar la información de la tabla
   */
  const handleUpdateTable = async () => {
    try {
      switch (catalogueDownloadStatus) {
        case "fetch":
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 9,
          };
          dispatch(fetchCatalogueDownload(obj));
          break;
        case "succeeded":
          let newData = [];
          const records = catalogueDownload;

          if (records.length > 0) {
            const finalData = records.map((catalog) => {
              return cellStructure(catalog);
            });
            newData = finalData;
          }

          setNoDownloadCatalogue(newData.length > 0 ? false : true);
          // setData(newData);
          setLoading(false);
          setIsLoadingRefresh(false);
          break;
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const openModal = () => {
    setModalTitle(t("App.BILayers.deployNewUser.modal.decisionTitle"));
    setIsOpenModal(true);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        rowSpacing={2}
        className="table-BILayers"
        sx={{ paddingTop: "0px", marginTop: "0px" }}
      >
        <Grid item className="download-table table-BILayers">
          <>
            {!props.foundResults ? (
              !props.filterNotFound ? (
                <>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    className="table-BILayers__header"
                  >
                    <Grid
                      container
                      direction="row"
                      className="catalogeOptContainer"
                      lg={5}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Grid
                        item
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            alignContent: "center !important",
                          }}
                        >
                          <Grid item className="catalogueIcon">
                            <ManageAccountsIcon className="accordion__upload__deployIcon" />
                          </Grid>
                          <Grid>
                            <p
                              className="BILayers-title"
                              style={{ paddingLeft: "1rem" }}
                            >
                              {t("App.BILayers.deployNewUser.title")}
                            </p>
                          </Grid>
                          <Grid>
                            <Tooltip
                              title={t("App.BILayers.deployNewUser.tooltip")}
                              placement="right"
                            >
                              <InfoIcon
                                style={{
                                  fontSize: "24px",
                                  color: "#553AB6",
                                  marginLeft: "8px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ marginBottom: "0.8rem", marginRight: "0px" }}
                      lg={5}
                      md={6}
                      sm={4.5}
                      xs={12}
                    >
                      <Button
                        sx={{ marginRight: "0rem" }}
                        className="btn__outlined__blue dimentions-btn__applyFilter"
                        onClick={openModal}
                        startIcon={<CheckIcon />}
                      >
                        {t("App.BILayers.deployNewUser.button")}
                      </Button>
                    </Grid>
                  </Grid>
                  <MUIDataTable
                    className="dataTable__BILayers"
                    data={props.data}
                    columns={columns}
                    options={props.isLoadingData ? skeletonOptions : options}
                    components={{
                      Table: (props) => (
                        <Table {...props} style={{ tableLayout: "fixed" }} /> // Añade el estilo aquí
                      ),
                    }}
                  />
                </>
              ) : (
                <Grid>
                  <NoMatchImage type="adminBILayers" />
                </Grid>
              )
            ) : (
              <NoSelectionComponent type="adminBILayers" />
            )}
          </>
        </Grid>
        <ModalInfo
          title={modalTitle}
          responseData={infoContent}
          open={isOpenModalInfo}
          onClose={() => {
            setIsOpenModalInfo(false);
          }}
        />
        <ModalDecision
          title={modalTitle}
          message={
            <span>
              <p>{t("App.BILayers.deployNewUser.modal.content1")}</p>
              <br />
              <p>{t("App.BILayers.deployNewUser.modal.content2")}</p>
            </span>
          }
          disagreeText={t("App.buttonLabels.cancel")}
          agreeText={t("App.buttonLabels.accept")}
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          handleAgree={handleAgree}
          handleDisagree={handleDisagree}
        />
      </Grid>
    </>
  );
};

export default DeployNewClient;

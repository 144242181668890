import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
  Checkbox,
  Check,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateContractedProduct } from "../../../services/admin/adminServices";
import { CODES } from "../../../consts/codes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import { CreateContact } from "../../../services/user/contactsService";
import { REGEXP } from "../../../consts/regexp";
import { de } from "date-fns/locale";
import { FilterEditMonths } from "../Filters/filterEditMonths";
import NoMatchImage from "../NoMatchImage/noMatchImage";
import { NoSelectionComponent } from "../noImages/noSelectionComponent";
import { UpdateMonths } from "../../../services/user/BILayers/requestBILayers";
import ModalInfo from "../Modals/modalInfo";

export const ModalEditMonthsBI = ({
  setOpenEditMonthsModal,
  open,
  companyList,
  companyType,
  country,
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  /**
   * Title Container component
   */
  const DialogTitleContainer = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  /**
   * Use Translation
   */

  const { t } = useTranslation();

  /**
   * Use State
   */
  //Data de la tabla
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);

  //Elementos de la view cuando no se muestra la tabla
  const [noContent, setNoContent] = useState(true);
  const [filterNotFound, setFilterNotFound] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filtersInfo, setFiltersInfo] = useState([]);
  const [filters, setFilters] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);

  const productOptions = [{ label: "CEN Fiscal", value: "PRESCRIPTIVA" }];

  const optionsLayers = [
    { label: t("App.BILayers.editMonths.invoice"), value: "invoice" },
    { label: t("App.BILayers.editMonths.payroll"), value: "payroll" },
  ];

  /**
   * UseForm
   */
  const schema = yup.object().shape({});

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  /**
   * Use Selector
   */

  const userInfo = useSelector(getUser);

  /**
   * Use State
   */

  //Sets loading state when creating contact request is loading
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);

  /**
   * Use Effect
   */

  //When closing the modal, it resets the form
  useEffect(() => {
    switch (open) {
      case false:
        reset();
        break;
    }
  }, [open]);

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <NoMatchImage />,

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "company",
      label: t("App.catalogue.table.company"),
      options: {
        filter: true,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "layer",
      label: t("App.BILayers.editMonths.table.layer"),
      options: {
        filter: true,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "deployTime",
      label: t("App.BILayers.editMonths.table.deployMonths"),
      options: {
        filter: true,
        sort: false,
        searchable: true,
      },
    },
  ];

  /**
   * Handles
   */
  const disableConfirmChanges = () => {
    if (filterNotFound) {
      return true;
    } else {
      return false;
    }
  };

  const handleEdit = async () => {
    setOpenModalInfo(true);
    try {
      let obj = [...newData];

      const editMonths = await UpdateMonths(obj);
      const responseCode = editMonths.data.responseCode;
      let responseMessage = editMonths.data.responseMessage;

      if (
        editMonths.status === CODES.COD_RESPONSE_HTTP_OK &&
        editMonths.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = editMonths.data.responseMessage;
      } else {
        setOpenModalInfo(true);
      }
    } catch (error) {
      console.log(
        "============== Error FiltersAdminProducts.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Closes modal create
   */
  const handleClose = () => {
    setNoContent(true);
    setFilterNotFound(true);
    setOpenEditMonthsModal(false);
  };

  return (
    <Grid sx={{ display: "flex", justifyContent: "center" }}>
      <Dialog
        maxWidth={"lg"}
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem",
            },
          },
        }}
      >
        <DialogTitleContainer onClose={handleClose}>
          <Grid container direction="column" rowSpacing={1}>
            <Grid
              item
              className="display-large__primary-one modal-editMonths__title"
            >
              {t("App.BILayers.editMonths.title")}
            </Grid>
          </Grid>
        </DialogTitleContainer>

        <DialogContent>
          <Grid
            container
            direction="column"
            sx={{
              paddingTop: "1%",
              paddingBottom: "5%",
            }}
          >
            <FilterEditMonths
              data={data}
              setData={setData}
              newData={newData}
              setNewData={setNewData}
              setNoContent={setNoContent}
              setFilterNotFound={setFilterNotFound}
              setIsDataLoading={setIsDataLoading}
              productOptions={productOptions}
              optionsLayers={optionsLayers}
              setFiltersInfo={setFiltersInfo}
              setFilters={setFilters}
              companyList={companyList}
              companyType={companyType}
              country={country}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            {!noContent ? (
              !filterNotFound ? (
                isDataLoading ? (
                  <Grid>
                    <MUIDataTable
                      className="dataTable__base"
                      data={data}
                      columns={columns}
                      options={skeletonOptions}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <MUIDataTable
                      className="dataTable__base"
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </Grid>
                )
              ) : (
                <Grid container className="full-width col-flex">
                  <NoMatchImage />
                </Grid>
              )
            ) : (
              <Grid>
                <NoSelectionComponent type="editMonths" />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            className="col-flex"
            columnGap={4}
            rowSpacing={2}
            sx={{ padding: "2.5%" }}
          >
            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              lg={1}
              order={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Button
                className="btn__filled__gradient-contact dimentions-btn__applyFilter"
                onClick={handleClose}
              >
                {t("App.createUser.cancelButton")}
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              lg={2}
              order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
            >
              {" "}
              <Button
                disabled={disableConfirmChanges()}
                className={`${
                  disableConfirmChanges()
                    ? "btn__applyFilter-reload-disabled"
                    : "btn__applyFilter"
                } dimentions-btn__applyFilter`}
                onClick={handleEdit}
              >
                {t("App.BILayers.editMonths.button")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <ModalInfo
        title={t("App.BILayers.editMonths.modalTitle")}
        responseData={""}
        open={openModalInfo}
        onClose={() => {
          setOpenModalInfo(false);
        }}
      />
    </Grid>
  );
};

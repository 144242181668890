import { Button, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoopIcon from "@mui/icons-material/Loop";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { ModalDecision } from "../../../components/common/Modals/modalDecision";
import { GetDeployGuide } from "../../../services/user/BILayers/requestBILayers";
import { CODES } from "../../../consts/codes";
import moment from "moment";
import fileDownload from "js-file-download";
import { decode } from "base64-arraybuffer";
import { getUser, getUserStatus } from "../../userSlice";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import {
  fetchCatalogueDownload,
  getCatalogueDownload,
  getCatalogueDownloadStatus,
  resetCatalogueDownloadState,
} from "../../../parts/catalogue/catalogueSlice";
import { getIsBusinessGroup } from "../../../parts/businessGroup/businessGroupSlice";
import InfoIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { UpdateBILayers } from "../../../services/user/BILayers/requestBILayers";
import { NoSelectionComponent } from "../../../components/common/noImages/noSelectionComponent";

const UpdateLayers = (props) => {
  const { t } = useTranslation();

  /**
   *
   * Función para descargar la guía de cómo llenar la plantilla
   *
   * @param {Event} event
   */
  const handleDownloadGuide = async (event) => {
    event.preventDefault();

    try {
      const guideService = await GetDeployGuide();

      if (guideService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          guideService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          fileDownload(
            decode(guideService.data.data.fileContent),
            guideService.data.data.fileName
          );
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Opciones para la configuración de la tabla
   */
  const options = {
    filterType: "dropdown",
    download: false,
    print: false,
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    viewColumns: false,
    search: false,
    filter: false,
    pagination: false,

    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
    },
  };

  /**
   * SkeletonOptions para la carga cuando se está trayendo la información a mostrar en la tabla
   */
  const skeletonOptions = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Dispatch
   */

  const dispatch = useDispatch();

  /**
   * Use Selector
   */
  //Usuario
  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  //Archivos de descarga
  const catalogueDownload = useSelector(getCatalogueDownload);
  const catalogueDownloadStatus = useSelector(getCatalogueDownloadStatus);

  //Grupo empresarial
  const isBusinessGroup = useSelector(getIsBusinessGroup);

  /**
   * Use State
   */

  //Campo de tabla
  const [data, setData] = useState([]);
  const [noDownloadCatalogue, setNoDownloadCatalogue] = useState(false);

  //Modal de actualzar capas
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  //Modal respuesta de descarga
  const [infoTitle, setInfoTitle] = useState("");
  const [infoContent, setInfoContent] = useState({});
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false);

  //Carga visual
  const [isLoadingDownloadRequest, setIsLoadingDownloadRequest] =
    useState(false);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  //Responsive
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Grupo empresarial
  const [showDownload, setShowDownload] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  /**
   * useEffect para manejar la actualización de la información de la tabla
   */
  useEffect(() => {
    try {
      if (userInfo) {
        handleUpdateTable();
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  }, [userStatus, dispatch, catalogueDownloadStatus]);

  /**
   * Use Effect para mostrar por default el componente de carga si no es grupo
   */
  useEffect(() => {
    if (!isBusinessGroup) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [isBusinessGroup]);

  /**
   * Función para actualizar el state de isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  /**
   * useEffect para actualizar el state de isDesktop basado en el ancho del navegador
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   *
   * Función para estructurar la información a mostrar en la tabla
   *
   * @param {Object} catalogue celda de catálogo
   * @returns la información estructurada para mostrar en la tabla
   */
  const cellStructure = (catalogue) => {
    return {
      initialUploadDate: !catalogue.starDate
        ? " "
        : moment(catalogue.starDate).format("YYYY-MM-DD hh:mm"),
      finalUploadDate: !catalogue.endDate
        ? "  "
        : moment(catalogue.endDate).format("YYYY-MM-DD hh:mm"),
      user: !catalogue.userLoad ? "" : catalogue.userLoad,
      company: !catalogue.company ? "" : catalogue.company,
      file: !catalogue.fileName
        ? ""
        : {
            fileName: catalogue.fileName,
            pathFileName: catalogue.pathFileName,
            state: !catalogue.state ? "" : catalogue.state,
          },
      state: !catalogue.state ? "" : catalogue.state,
    };
  };

  /**
   * Función para actualizar la información de la tabla
   */
  const handleUpdateTable = async () => {
    try {
      switch (catalogueDownloadStatus) {
        case "fetch":
          const obj = {
            country: userInfo.company.country,
            eanProvider: userInfo.company.eanCompany,
            emailLoad: userInfo.email,
            idFileType: 9,
          };
          dispatch(fetchCatalogueDownload(obj));
          break;
        case "succeeded":
          let newData = [];
          const records = catalogueDownload;

          if (records.length > 0) {
            const finalData = records.map((catalog) => {
              return cellStructure(catalog);
            });
            newData = finalData;
          }

          setNoDownloadCatalogue(newData.length > 0 ? false : true);
          setData(newData);
          setLoading(false);
          setIsLoadingRefresh(false);
          break;
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para refrescar la información de la tabla
   */
  const handleRefresh = () => {
    setLoading(true);
    setIsLoadingRefresh(true);
    setData([]);
    dispatch(resetCatalogueDownloadState());
  };

  const openModal = () => {
    setModalTitle(t("App.BILayers.updateBILayers.modal.decisionTitle"));

    setIsOpenModal(true);
  };

  const handleAgree = async () => {
    setIsOpenModal(false);

    try {
      let obj = {
        qlikUpdateArray: props.messageData,
      };

      const updateBiLayers = await UpdateBILayers(obj);
      const responseCode = updateBiLayers.data.responseCode;
      let responseMessage = updateBiLayers.data.responseMessage;

      if (
        updateBiLayers.status === CODES.COD_RESPONSE_HTTP_OK &&
        updateBiLayers.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const responseMessage = updateBiLayers.data.responseMessage;
        setInfoTitle(t("App.BILayers.updateBILayers.modal.decisionTitle"));
        setInfoContent({
          data: {
            responseCode: responseCode,
            responseMessage: (
              <span>
                <p>
                  {t("App.BILayers.updateBILayers.modal.modalInfoContent1")}
                  <br />
                  <br />
                  {t("App.BILayers.updateBILayers.modal.modalInfoContent2")}
                  <span className="bold">
                    {t("App.BILayers.updateBILayers.modal.modalInfoContent3")}
                  </span>
                </p>
              </span>
            ),
          },
        });
      } else {
        setIsOpenModalInfo(true);
      }
      setIsOpenModalInfo(true);
    } catch (error) {
      console.log(
        "============== Error FiltersAdminProducts.jsx function handleFilters ======================"
      );
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDisagree = () => {
    setIsOpenModal(false);
  };

  const infoModalMessage = () => {};
  /**
   *
   * Función para colocar el título correspondiente del modal basado en el responseCode
   *
   * @param {Object} response código de respuesta de la petición
   */
  const handleDisplayModal = (response) => {
    const { responseCode } = response.data;
    let title = "";

    switch (responseCode) {
      case 1005:
        title = t("App.downloadCatalogue.downloadModal.noRegistry");
        break;

      case 1006:
        title = t("App.downloadCatalogue.downloadModal.errorDownload");
        break;
      case 0:
        title = t("App.downloadCatalogue.downloadModal.initDownload");
        break;
    }

    setInfoTitle(title);
    // setInfoContent(response);
    setIsOpenModalInfo(true);
  };

  /**
   * Configuración para las columnas de la tabla
   */
  const columns = [
    {
      name: "company",
      label: t("App.catalogue.table.company"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "eanCompany",
      label: t("App.BILayers.deployNewUser.table.eanCompany"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "app",
      label: t("App.BILayers.filter.app"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "layer",
      label: t("App.BILayers.filter.layer"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "deployTime",
      label: t("App.BILayers.deployNewUser.table.deployTime"),
      options: {
        filter: true,
        sort: true,
        searchable: true,
      },
    },
  ];

  return (
    <>
      <Grid
        container
        direction="column"
        rowSpacing={2}
        className="table-BILayers"
        sx={{ paddingTop: "0px", marginTop: "0px" }}
      >
        <Grid item className="download-table table-BILayers">
          <>
            {!props.foundResults ? (
              !props.filterNotFound ? (
                <>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    display="flex"
                    className="table-BILayers__header"
                  >
                    <Grid
                      container
                      direction="row"
                      className="catalogeOptContainer"
                      lg={5}
                      md={6}
                      sm={6}
                      xs={12}
                    >
                      <Grid
                        item
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            alignContent: "center !important",
                          }}
                        >
                          <Grid item className="catalogueIcon">
                            <LoopIcon className="accordion__upload__uploadIcon" />
                          </Grid>
                          <Grid>
                            <p
                              className="BILayers-title"
                              style={{ paddingLeft: "1rem" }}
                            >
                              {t("App.BILayers.updateBILayers.title")}
                            </p>
                          </Grid>
                          <Grid>
                            <Tooltip
                              title={t("App.BILayers.updateBILayers.tooltip")}
                              placement="right"
                            >
                              <InfoIcon
                                style={{
                                  fontSize: "24px",
                                  color: "#553AB6",
                                  marginLeft: "8px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ marginBottom: "0.8rem", marginRight: "0px" }}
                      lg={5}
                      md={6}
                      sm={4.8}
                      xs={12}
                    >
                      <Grid
                        justifySelf="flex-end"
                        sx={{ marginBottom: "0.8rem" }}
                      >
                        <Button
                          sx={{ marginRight: "0rem" }}
                          className="btn__outlined__blue dimentions-btn__applyFilter"
                          onClick={openModal}
                          startIcon={<LoopIcon />}
                        >
                          {t("App.BILayers.updateBILayers.button")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <MUIDataTable
                    className="dataTable__BILayers"
                    data={props.data}
                    columns={columns}
                    options={props.isLoadingData ? skeletonOptions : options}
                  />
                </>
              ) : (
                <NoMatchImage type="adminBILayers" />
              )
            ) : (
              <NoSelectionComponent type="adminBILayers" />
            )}
          </>
        </Grid>
        <ModalInfo
          title={infoTitle}
          responseData={infoContent}
          open={isOpenModalInfo}
          onClose={() => {
            setIsOpenModalInfo(false);
          }}
        />
      </Grid>
      <ModalDecision
        title={modalTitle}
        message={
          <span>
            <p>{t("App.BILayers.updateBILayers.modal.content1")}</p>
            <br />
            <p>{t("App.BILayers.updateBILayers.modal.content2")}</p>
          </span>
        }
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setIsOpenModal}
        isOpen={isOpenModal}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </>
  );
};

export default UpdateLayers;

import { React, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Grid, Skeleton, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { FilterAdminBILayers } from "../../../components/common/filterAdminBILayers";
import { ModalEditMonthsBI } from "../../../components/common/Modals/modalEditMonthsBI";
import DeployNewClient from "./DeployNewClient";
import UpdateLayers from "./updateLayers";
import { SelectorBILayers } from "./BILayersSelector";
import ModalInfo from "../../../components/common/Modals/modalInfo";
import { CODES } from "../../../consts/codes";

export const BILayers = () => {
  const { t } = useTranslation();

  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    role = jwtDecodeTokenInfo["custom:Role"];
  }

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use State
   */
  const [data, setData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [companyType, setCompanyType] = useState("");
  const [country, setCountry] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filtersInfo, setFiltersInfo] = useState([]);
  const [foundResults, setFoundResults] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);

  //Use State para seleccionar acción
  const [isActionSeleced, setIsActionSelected] = useState(false);
  const [actionTypeSelected, setActionTypeSelected] = useState("");

  //Controlar Accordion
  const [expandedDeploy, setExpandedDeploy] = useState(false);
  const [expandedUpdate, setExpandedUpdate] = useState(false);
  const [locked, setLocked] = useState(true);

  const [isNavigateToUsers, setIsNavigateToUsers] = useState(false);
  const [objfilters, setObjfilters] = useState([]);
  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  // Error modal
  const [openErrorModal, setOpenErrorModal] = useState(false);
  // Modal editar meses
  const [openEditMonthsModal, setOpenEditMonthsModal] = useState(false);

  //Información del formulario
  const [formFilters, setFormFilters] = useState({
    country: "",
    bussiness: [],
    lstEansBussiness: [],
    lstNameBussiness: [],
    app: "",
    companyType: "",
  });
  const columns = [
    {
      name: "country",
      label: t("App.listUsers.countryN"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("App.listUsers.company"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "product",
      label: t("App.listUsers.product"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contractedUsers",
      label: t("App.listUsers.contractedUsersN"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "activeUsers",
      label: t("App.listUsers.activeUsers"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "availableUsers",
      label: t("App.listUsers.availableUsers"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: t("App.editUser.status"),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        // noMatch: t("App.validationMessages.noMatchFilter"),
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    selectableRows: "none",
    responsive: "standard",
    print: false,
    filter: false,
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const navigate = useNavigate();

  /**
   * Redirige la la página de solicitudes de recarga aliados
   */
  const handleNavigateRequest = () => {
    navigate("request");
  };

  /**
   *
   * Función para eliminar la opción 'Seleccionar todo' cuando se envía la data del formulario para crear o editar un producto
   *
   * @param {Array} sellersOrCommerces
   * @param {Number} idOptionType
   * @returns objeto con las lista de vendedores y/o eans de los comercios
   */
  const getSellersOrEanCommerces = (sellersOrCommerces, idOptionType) => {
    const eanTraders =
      idOptionType === 1
        ? sellersOrCommerces.filter((ean) =>
            ean.label
              ? ean.label !== t("App.downloadSalesForce.selectAll")
              : ean !== t("App.downloadSalesForce.selectAll")
          )
        : [];

    const eanTradersList = eanTraders.map((commerce) => commerce.value);

    const sellers =
      idOptionType === 4
        ? sellersOrCommerces.filter(
            (seller) => seller.label !== t("App.downloadSalesForce.selectAll")
          )
        : [];

    const sellerFormatObject = sellers.map((seller) => {
      return { salesMan: seller.label, salesManCode: seller.value };
    });

    return {
      eanTraders: eanTradersList[0] !== undefined ? eanTradersList : eanTraders,
      sellers: sellerFormatObject[0] !== undefined ? sellerFormatObject : [],
    };
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 912);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  //Handles de secciones

  const handleDeployAccordion = () => {
    if (!locked) {
      setExpandedDeploy((prev) => !prev);
    }
  };

  const handleUpdateAccordion = () => {
    if (!locked) {
      setExpandedUpdate((prev) => !prev);
    }
  };

  const handleLockAccordion = () => {
    setLocked(true);
    setExpandedDeploy(false);
    setExpandedUpdate(false);
  };

  const handleUnlockAccordion = () => {
    setLocked(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gap={2}
      className="table-BILayers__base"
    >
      <Grid
        container
        direction={"row"}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xl={8} lg={8} md={6} sm={9.2} xs={12}>
          <p className="title-screen-text__primary-one">
            {t("App.BILayers.title")}
          </p>
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={2.8}
          xs={12}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            className="btn__applyFilter dimentions-btn__applyFilter"
            onClick={handleNavigateRequest}
          >
            {t("App.deleteAndReload.request")}
          </Button>
        </Grid>
      </Grid>

      <Grid item style={{ width: "100%" }}>
        <Grid item sx={{ marginBottom: "2rem" }}>
          <SelectorBILayers
            setActionTypeSelected={setActionTypeSelected}
            actionTypeSelected={actionTypeSelected}
            setIsActionSelected={setIsActionSelected}
            isActionSeleced={isActionSeleced}
          />
        </Grid>
        {isActionSeleced && (
          <Grid
            sx={{
              padding: "3rem !important",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <Accordion defaultExpanded sx={{ borderRadius: "8px" }}>
              <AccordionSummary
                className="accordion__filter"
                id="panel2a-header"
                expandIcon={
                  <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
                }
                aria-expanded="true"
                aria-controls="panel2a-content"
              >
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "#543ab4",
                  }}
                />

                <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                  {t("App.listUsers.filter")}
                </p>
              </AccordionSummary>

              <AccordionDetails>
                <FilterAdminBILayers
                  setOpenErrorModal={setOpenErrorModal}
                  setFilters={setFilters}
                  setFiltersInfo={setFiltersInfo}
                  setObjfilters={setObjfilters}
                  objfilters={objfilters}
                  isNavigateToUsers={isNavigateToUsers}
                  setFoundResults={setFoundResults}
                  filters={filters}
                  data={data}
                  setData={setData}
                  companyList={companyList}
                  setCompanyList={setCompanyList}
                  setCompanyType={setCompanyType}
                  setCountryValue={setCountryValue}
                  setCountry={setCountry}
                  setFilterNotFound={setFilterNotFound}
                  setIsLoadingData={setIsLoadingData}
                  setFormFilters={setFormFilters}
                  setExpandedDeploy={setExpandedDeploy}
                  setExpandedUpdate={setExpandedUpdate}
                  setLocked={setLocked}
                  messageData={messageData}
                  setMessageData={setMessageData}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>

      <Grid item style={{ width: "100%" }}>
        {actionTypeSelected === "deploy" && (
          <Grid container direction="column" gap={2}>
            {!foundResults && !filterNotFound && (
              <Grid
                items
                className="full-width"
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  margin: "1.5rem 0",
                  maxWidth: "none",
                }}
              >
                <Button
                  onClick={() => {
                    setOpenEditMonthsModal(true);
                  }}
                  className="btn__filled__blue dimentions-btn__openModal"
                  sx={{ marginRight: "0px !important" }}
                >
                  {t("App.BILayers.button")}
                </Button>
              </Grid>
            )}
            <Grid className="biLayers_info_container biLayers_container">
              <Grid className="companies_info_container">
                <Grid className="companies_info_container">
                  <DeployNewClient
                    filters={filters}
                    data={data}
                    foundResults={foundResults}
                    filterNotFound={filterNotFound}
                    setFilterNotFound={setFilterNotFound}
                    isLoadingData={isLoadingData}
                    setIsLoadingData={setIsLoadingData}
                    messageData={messageData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {actionTypeSelected === "update" && (
          <Grid className="biLayers_info_container biLayers_container">
            <Grid className="companies_info_container">
              <Grid className="companies_info_container">
                <UpdateLayers
                  filters={filters}
                  data={data}
                  foundResults={foundResults}
                  filterNotFound={filterNotFound}
                  isLoadingData={isLoadingData}
                  setIsLoadingData={setIsLoadingData}
                  messageData={messageData}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        gap={2}
        // lg={12}
        direction="column"
      ></Grid>
      {/* Modal para editar meses */}
      <ModalEditMonthsBI
        setOpenEditMonthsModal={setOpenEditMonthsModal}
        open={openEditMonthsModal}
        onClose={() => {
          setOpenEditMonthsModal(false);
        }}
        companyList={companyList}
        companyType={companyType}
        country={country}
      />
      {/* Modal para fallo de servicios */}
      <ModalInfo
        title={t("App.validationMessages.error")}
        responseData={{
          status: CODES.COD_RESPONSE_SUCCESS_REQUEST,
          data: {
            responseCode: CODES.COD_RESPONSE_HTTP_ERROR,
            responseMessage: t("App.validationMessages.systemError2"),
          },
        }}
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
      />
    </Grid>
  );
};

import { useTranslation } from "react-i18next";
import { Grid, Button, Skeleton, IconButton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import NoMatchImage from "../../../components/common/NoMatchImage/noMatchImage";
import { useEffect, useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddIcon from "@mui/icons-material/Add";

import { FilterAdminClientUsers } from "../../../components/common/Filters/filterAdminClientUsers";
import { useNavigate } from "react-router-dom";
import { getUser, getUserStatus } from "../../userSlice";
import { useSelector } from "react-redux";
import { CODES } from "../../../consts/codes";

import CreateUser from "../../../components/common/AdminUsersClient/createUser";
import EditUser from "../../../components/common/AdminUsersClient/editUser";

import {
  FilterUsersClient,
  getUserPermissions,
} from "../../../services/user/consultRolesService";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ModalSeeCompanies from "../../../components/common/AdminUsersClient/modalSeeCompanies";
import jwt_decode from "jwt-decode";
import PeriodicReportState from "../../../components/common/DynamicReport/periodicReportState";

const AdminClientUsers = () => {
  /**
   * Tomando el rol por el token.
   */
  const jwtTokenInfo = localStorage.getItem("infoToken");

  let role = "";
  if (jwtTokenInfo) {
    const jwtDecodeTokenInfo = jwt_decode(jwtTokenInfo);

    role = jwtDecodeTokenInfo["custom:Role"];
  }

  const userInfo = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);
  const [openCreateUser, setOpenCreateUser] = useState(false);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [noContent, setNoContent] = useState(false);

  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);

  /**
   *  verifica si el tamaño de la pantalla es mobile o tablet
   */
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 400);
      setIsScreenSmall(window.innerWidth < 1000);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [openEditUser, setOpenEditUser] = useState(false);
  const [currentUserEdit, setCurrentUserEdit] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    permissions: [],
  });

  const [currentCompany, setCurrentCompany] = useState({
    eanCompany: "",
    country: "",
    companyName: "",
  });

  const { t } = useTranslation();

  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  const columns = [
    {
      name: "firstName",
      label: t("App.adminClientUsers.table.firstName"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "lastName",
      label: t("App.adminClientUsers.table.lastName"),
      options: {
        filter: false,
        sort: true,
        searchable: true,
      },
    },
    {
      name: "email",
      label: t("App.adminClientUsers.table.email"),
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: "phone",
      label: t("App.adminClientUsers.table.phone"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "listCompanies",
      label: t("App.adminClientUsers.table.enterprise"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "menuOptionName",
      label: t("App.adminClientUsers.table.menuOptionName"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    {
      name: "nameRole",
      label: t("App.adminClientUsers.table.nameRole"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },

    {
      name: "state",
      label: t("App.adminClientUsers.table.state"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customBodyRender: (value) => <PeriodicReportState value={value} />,
      },
    },
    {
      name: "edit",
      label: t("App.adminClientUsers.table.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    print: "false",
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  const skeletonOptions = {
    filterType: "dropdown",
    print: "false",
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "standard",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,
    searchOpen: true,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <Skeleton />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Use Effect
   */
  /**
   * Carga los usuarios
   */
  useEffect(() => {
    if (userStatus === "succeeded") fetchUsers();
  }, [userStatus]);

  /**
   * Abrir modal de crear usuario
   */
  const handleOpen = () => {
    setOpenCreateUser(true);
  };

  /**
   * Función que consulta y settea la información de los usuarios
   */
  const fetchUsers = async () => {
    try {
      const eanCompany = userInfo.company.eanCompany;
      const country = userInfo.company.country;

      setCurrentCompany({
        eanCompany: userInfo.company.eanCompany,
        country: userInfo.company.country,
        companyName: userInfo.company.companyName,
      });

      setIsLoadingData(true);

      const userService = await FilterUsersClient({
        eanCompany: eanCompany,
        country: country,
      });

      const { responseCode, responseMessage } = userService.data;

      if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
        const dataUsers = setDataStructureArray(responseMessage);

        setData(dataUsers);
        setUnfilteredData(dataUsers);
      } else {
        setData([]);
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función que formatea los permisos del usuario
   * @param {*} user usuario
   * @param {*} rawData lista de usuarios
   * @returns lista de permisos del usuario
   */
  const definePermissionsArray = async (user) => {
    const eanCompany = userInfo.company.eanCompany;
    const country = userInfo.company.country;
    const email = user.email;

    const permissionService = await getUserPermissions({
      eanCompany: eanCompany,
      country: country,
      email: email,
    });
    const { responseCode, responseMessage } = permissionService.data;

    if (responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST) {
      const finalPermissions = responseMessage.map((userPermission) => {
        return {
          idMenuOption: userPermission.idMenuOption,
          menuOptionName: userPermission.menuOptionName,
          companyList: userPermission.lstCompanies,
          roleList: `{"idRole": "${userPermission.idRole}", "nameRole": "${userPermission.nameRole}"}`,
          state: userPermission.state,
          editRecord: userPermission.editRecord,
        };
      });
      return finalPermissions;
    } else {
      return [];
    }
  };

  /**
   * Función que settea el usuario para editarlo
   * @param {*} currentUser usuario actual
   */
  const handleOpenEdit = async (currentUser) => {
    setCurrentUserEdit({
      ...currentUser,
      permissions: await definePermissionsArray(currentUser),
      randomIdTrigger: generateId(),
    });
    setOpenEditUser(true);
  };

  /**
   * Función que formatea el arreglo de usuarios a el formato de tabla
   * @param {*} data arreglo de usuarios
   * @returns arreglo formateado
   */
  const setDataStructureArray = (data) => {
    const formattedData = data.map((clientUser) => {
      const itemForEdit = {
        ...clientUser,
      };

      return {
        firstName: clientUser.firstName,
        lastName: clientUser.lastName,
        email: clientUser.email,
        phone: clientUser.phone,
        listCompanies:
          clientUser.lstCompanies?.length > 1 ? (
            <ModalSeeCompanies
              data={clientUser.lstCompanies}
              userName={`${clientUser.firstName} ${clientUser.lastName}`}
            />
          ) : (
            clientUser.lstCompanies[0].renameCompany
          ),
        nameRole: clientUser.nameRole,
        menuOptionName: clientUser.menuOptionName,
        state: clientUser.state === 1 ? "Activo" : "Inactivo",
        edit: (
          <IconButton
            onClick={() => {
              handleOpenEdit(itemForEdit);
            }}
          >
            <EditOutlinedIcon style={{ fontSize: "22px", color: "#543ab4" }} />
          </IconButton>
        ),
      };
    });

    return formattedData;
  };

  /**
   * Función que navega a la view de consultar roles
   */
  const handleSeeRoles = () => {
    navigate("/user/consultRoles");
  };

  /**
   * Función que genera un id random
   * @returns id random
   */
  const generateId = () => {
    const id = Date.now().toString(32) + Math.random().toString(32);
    return id;
  };

  return (
    // °° Grid principal °°
    <Grid container direction="column" spacing={2}>
      <Grid item style={{ marginTop: "2%" }}>
        <p className="display-large__primary-one">
          {t("App.adminClientUsers.title")}
        </p>
      </Grid>

      <Grid className="companies_button_container" marginTop={1}>
        <Grid container className="create-btn-container" gap={2} width="100%">
          <Grid item xs={12} sm={2} md={2} lg={1.2} xl={1}>
            <Button
              fullWidth
              style={{ flex: "1%", padding: "8px" }}
              startIcon={<VisibilityOutlinedIcon sx={{ fontSize: 22 }} />}
              className="btn__outlined__blue"
              onClick={handleSeeRoles}
            >
              {t("App.adminClientUsers.buttonSeeRoles")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2.4} md={2.2} lg={1.5} xl={1}>
            <Button
              fullWidth
              style={{ flex: "1%", padding: "8px" }}
              startIcon={<AddIcon sx={{ fontSize: 22 }} />}
              className="btn__filled__blue"
              onClick={handleOpen}
            >
              {t("App.adminClientUsers.buttonCreateUsuario")}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <br />

      <Grid className="companies_info_container" padding={"24px"}>
        <Grid item>
          <FilterAdminClientUsers
            setDataStructureArray={setDataStructureArray}
            setData={setData}
            setIsLoadingData={setIsLoadingData}
            setNoContent={setNoContent}
            unfilteredData={unfilteredData}
            data={data}
          />
        </Grid>

        <br />
        <br />
        <br />

        <Grid item>
          {!noContent ? (
            isLoadingData ? (
              <MUIDataTable
                className="dataTable__salesForce datatable-style"
                style={{ marginTop: "5%", display: "none" }}
                data={data}
                columns={columns}
                options={skeletonOptions}
              />
            ) : (
              <MUIDataTable
                className="dataTable__salesForce datatable-style"
                style={{ marginTop: "5%", display: "none" }}
                data={data}
                columns={columns}
                options={options}
              />
            )
          ) : (
            <NoMatchImage />
          )}
        </Grid>
      </Grid>
      <CreateUser
        clientUser={currentUserEdit}
        openCreateUser={openCreateUser}
        setOpenCreateUser={setOpenCreateUser}
        handleOpenEdit={handleOpenEdit}
        currentCompany={currentCompany}
        companyId={currentCompany?.eanCompany}
        country={currentCompany?.country}
        role={role}
        fetchUsers={fetchUsers}
      />

      {/* °° Grid principal °° */}
      <EditUser
        openEditUser={openEditUser}
        setOpenEditUser={setOpenEditUser}
        clientUser={currentUserEdit}
        role={role}
        currentCompany={currentCompany}
        companyId={currentCompany?.eanCompany}
        country={currentCompany?.country}
        fetchUsers={fetchUsers}
      />
    </Grid>
  );
};

export default AdminClientUsers;

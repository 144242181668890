import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { RequestFilterForm } from "../Allies/requestFilterForm";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditMonthsForms } from "../BILayers/filterFormEditMonths";

export const FilterEditMonths = ({
  handleFilter,
  productOptions,
  optionsLayers,
  data,
  setData,
  newData,
  setNewData,
  setIsDataLoading,
  setFilterNotFound,
  setNoContent,
  setFormFilters,
  setFiltersInfo,
  setFilters,
  companyList,
  companyType,
  country,
}) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(true);

  /**
   * Esquema para validar el filtro de de solicitudes de recarga
   */
  const schema = yup.object().shape({
    product: yup
      .object()
      .shape({
        label: yup.string().required(t("App.validationMessage.required")),
        value: yup.string().required(t("App.validationMessage.required")),
      })
      .required(t("App.validationMessage.required")),
    layer: yup
      .object()
      .shape({
        label: yup.string().required(t("App.validationMessage.required")),
        value: yup.string().required(t("App.validationMessage.required")),
      })
      .required(t("App.validationMessage.required")),
    company: yup
      .array()
      .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
      .nullable(),
  });

  /**
   * UseForm
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      product: null,
      layer: null,
      company: [],
    },
  });

  return (
    <Accordion defaultExpanded sx={{ padding: "0px !important" }}>
      <AccordionSummary className="accordion__filter">
        <TuneOutlinedIcon
          sx={{
            fontSize: 20,
            color: "#543ab4",
          }}
        />

        <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
          {" "}
          {t("App.catalogue.filterName")}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <EditMonthsForms
          handleFilter={handleFilter}
          productOptions={productOptions}
          optionsLayers={optionsLayers}
          control={control}
          errors={errors}
          setValue={setValue}
          reset={reset}
          watch={watch}
          handleSubmit={handleSubmit}
          data={data}
          setData={setData}
          newData={newData}
          setNewData={setNewData}
          setIsDataLoading={setIsDataLoading}
          setFilterNotFound={setFilterNotFound}
          setNoContent={setNoContent}
          setFormFilters={setFormFilters}
          setFiltersInfo={setFiltersInfo}
          setFilters={setFilters}
          companyList={companyList}
          getValues={getValues}
          country={country}
        />
      </AccordionDetails>
    </Accordion>
  );
};

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import LeftArrow from "@mui/icons-material/KeyboardArrowLeft";
import RightArrow from "@mui/icons-material/KeyboardArrowRight";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMediaQuery } from "@mui/material";

const ConfigurationByCommerce = ({
  configurationsFields,
  handleAddNewConfiguration,
  handleRemoveConfiguration,
  handleCommerceChange,
  setDisabledButton,
  control,
  commerceList,
  sizePreviousCommerceList = 0,
  sizeCommerceList,
  errors,
  isEdit,
  watch,
  resetCommerce,
}) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 992);
  const [currentPage, setCurrentPage] = useState(0);

  const [commercesSelected, setCommercesSelected] = useState([]);

  // Estado para gestionar si el ancho de la pantalla es mayor a 1300px
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1400);

  /**
   * useMediaQuery for HD responsive
   */
  const matches = useMediaQuery("(min-width:1670px)");

  const { t } = useTranslation();

  /**
   * Verifica si hay un error presente en un campo específico de una configuración en particular.
   * @param {Object} errors - El objeto de errores que contiene información sobre los errores de validación.
   * @param {Number} index - El índice de la configuración dentro de la lista de configuraciones.
   * @param {String} field - El nombre del campo que se está verificando para encontrar un error.
   * @returns {Boolean} - Retorna `true` si hay un error presente en el campo especificado de la configuración indicada por el índice, de lo contrario, retorna `false`.
   */
  const getError = (errors, index, field) => {
    return errors?.configurations?.[index]?.[field] ? true : false;
  };

  /**
   * Obtiene el mensaje de error de un campo específico de una configuración en particular.
   * @param {Object} errors - El objeto de errores que contiene información sobre los errores de validación.
   * @param {Number} index - El índice de la configuración dentro de la lista de configuraciones.
   * @param {String} field - El nombre del campo cuyo mensaje de error se está obteniendo.
   * @returns {String} - Retorna el mensaje de error del campo especificado de la configuración indicada por el índice, o una cadena vacía si no hay un mensaje de error.
   */
  const getErrorMessage = (errors, index, field) => {
    return errors?.configurations?.[index]?.[field]?.message || "";
  };

  // La función watch te permite observar los valores de los inputs
  const dailyPaths = watch("configurations", []).map((c) => c.dailyPath);
  const states = watch("configurations", []).map((c) => c.state);

  // useEffect para verificar si los campos están diligenciados
  useEffect(() => {
    const areFieldsFilled = configurationsFields.every((_, index) => {
      const dailyPathFilled =
        dailyPaths[index] && dailyPaths[index].trim() !== "";
      const stateFilled = states[index] && states[index].trim() !== "";
      return dailyPathFilled && stateFilled;
    });

    setDisabledButton(areFieldsFilled);
  }, [dailyPaths, states, configurationsFields]);

  useEffect(() => {
    // Verificar si es edición
    let auxSelected = [];
    if (isEdit) {
      for (let i = 0; i < sizePreviousCommerceList; i++) {
        auxSelected.push("");
      }
    }

    setCommercesSelected(auxSelected);
  }, [isEdit, sizePreviousCommerceList]);

  // Efecto para actualizar isWideScreen cuando la ventana cambia de tamaño
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1400);
      setDesktop(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);

    // Ejecutar al montar y al desmontar
    handleResize();

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Habilitar boton de eliminar o textfield
  const isDisabled = (selected = null) => {
    return isEdit && selected;
  };

  /**
   * Handle para actualizar la pagina cuando se navega a la siguiente
   */
  const nextPage = () => {
    if (currentPage < configurationsFields.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  /**
   * Handle para actualizar la pagina cuando se navega a la anterior
   */
  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  /**
   * Handle para el disabled del boton de agregar configuracion
   */
  const handleAddFieldMobile = () => {
    let disabled = true;
    if (isEdit) {
      disabled = sizeCommerceList === 0;
    } else {
      disabled =
        configurationsFields.length >= sizeCommerceList ||
        sizeCommerceList === 0;
    }
    return disabled;
  };

  /**
   * Handle para el disabled del boton de eliminar configuracion
   */
  const handleRemoveFieldMobile = (index) => {
    if (configurationsFields[index] !== undefined) {
      return (
        (isEdit && configurationsFields[index].selected) ||
        configurationsFields.length === 1
      );
    } else {
      return isEdit || configurationsFields.length === 1;
    }
  };

  /**
   * Handle para el disabled del boton de navegacion de las configuraciones
   */
  const handleRemoveConfigurationAndNavigate = (index) => {
    handleRemoveConfiguration(index);
    setCommercesSelected(
      commercesSelected.filter(
        (selected) => selected !== commercesSelected[index]
      )
    );
    if (currentPage === configurationsFields.length - 1 && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  /**
   * Handle para navegar cuando se agregan productos
   */
  const handleAddNewPermissionAndNavigate = () => {
    handleAddNewConfiguration();
    setCurrentPage(configurationsFields.length);
  };

  // Scroll cuando haya más de dos configuraciones
  const gridContainerStyles = isDesktop
    ? {
        maxHeight: "calc(2 * 100px)", // Ajusta este valor al alto de tus líneas de configuración
        overflowY: configurationsFields.length > 2 ? "auto" : "hidden",
        width: "100%",
      }
    : {};

  return (
    <>
      <Grid container spacing={2} mt={1}
        style={{
          paddingLeft: !isWideScreen && isDesktop ? "25px" : "",
        }}
      >
        {isDesktop ? null : (
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={1}
            mt={2}
          >
            <Grid item sm={6} sx={{ width: "100%" }}>
              <IconButton
                className={
                  handleAddFieldMobile()
                    ? "allie-page__addField__disabled"
                    : "allie-page__addField"
                }
                onClick={handleAddNewPermissionAndNavigate}
                disabled={handleAddFieldMobile()}
              >
                <AddIcon sx={{ fontSize: 27 }} />
                {t("App.adminSendAllies.modals.addButton")}
              </IconButton>
            </Grid>
            <Grid item sm={6} sx={{ width: "100%" }}>
              <IconButton
                className={
                  handleRemoveFieldMobile(currentPage)
                    ? "allie-page__removeField__disabled"
                    : "allie-page__removeField"
                }
                onClick={() =>
                  handleRemoveConfigurationAndNavigate(currentPage)
                }
                disabled={handleRemoveFieldMobile(currentPage)}
              >
                <ClearIcon sx={{ fontSize: 27 }} />
                {t("App.adminSendAllies.modals.deleteButton")}
              </IconButton>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} style={gridContainerStyles} mt={1} className={isDesktop ?"scroll-table" :""}>
          <Grid container spacing={2} >
            {configurationsFields.map((configuration, index) => {
              return (
                (isDesktop || index === currentPage) && (
                  <Grid
                    item
                    container
                    alignItems="center"
                    spacing={2}
                    key={configuration.key}
                    style={{
                      flexWrap: !isWideScreen && isDesktop ? "nowrap" : "",
                      minWidth: !isWideScreen && isDesktop ? "120%" : "",
                      overflowX: !isWideScreen && isDesktop ? "auto" : "hidden",
                    }}
                  >
                    <Grid item xs={12} lg={2.1} xl={1.8}>
                      {isEdit && configuration.selected ? (
                        <Controller
                          defaultValue=""
                          name={`configurations.${index}.commerceList.trader`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              disabled={isDisabled(configuration.selected)}
                              label={t("App.adminSendAllies.modals.commerce")}
                              sx={{
                                label: {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D",
                                  },
                                },
                              }}
                              className={`${
                                getError(errors, index, "commerceList")
                                  ? "modalEditRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                              FormHelperTextProps={{
                                style: {
                                  margin: "-5px 0 1px 0px",
                                  color: "#e52900",
                                  fontSize: 10,
                                },
                              }}
                              helperText={getErrorMessage(
                                errors,
                                index,
                                "commerceList"
                              )}
                              onChange={(e, values) => {
                                setCommercesSelected([
                                  ...commercesSelected,
                                  values.eanCommerce,
                                ]);

                                onChange(values);
                              }}
                              value={value || ""}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          name={`configurations.${index}.commerceList`}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={`${
                                getError(errors, index, "commerceList")
                                  ? "modalEditRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                              options={
                                commercesSelected.length > 0
                                  ? commerceList.filter(
                                      (commerce) =>
                                        !commercesSelected.includes(
                                          commerce?.eanCommerce
                                        )
                                    )
                                  : commerceList
                              }
                              getOptionLabel={(option) => option.commerceName}
                              isOptionEqualToValue={(option, value) =>
                                option?.eanCommerce === value?.eanCommerce
                              }
                              renderInput={(params) => (
                                <TextField
                                  label={t(
                                    "App.adminSendAllies.modals.commerce"
                                  )}
                                  sx={{
                                    label: {
                                      fontFamily: "Roboto !important",
                                      fontSize: "14px !important",
                                      translate: "0px -5px",
                                      transformOrigin: "-45px -10px",
                                      "&.Mui-focused": {
                                        color: "#8C8D9D",
                                      },
                                    },
                                  }}
                                  FormHelperTextProps={{
                                    style: {
                                      margin: "-5px 0 1px 0px",
                                      color: "#e52900",
                                      fontSize: 10,
                                    },
                                  }}
                                  helperText={getErrorMessage(
                                    errors,
                                    index,
                                    "commerceList"
                                  )}
                                  {...params}
                                />
                              )}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                let auxSelected = commercesSelected;

                                if (reason === "clear") {
                                  // Remplaza el valor en la posición indicada por el índice
                                  auxSelected.splice(index, 1, "");

                                  setCommercesSelected(auxSelected);
                                  resetCommerce(value, index);
                                } else {
                                  // verificar si en la posicion ya hay un valor
                                  if (commercesSelected[index]) {
                                    // Remplaza el valor en la posición indicada por el índice
                                    auxSelected.splice(
                                      index,
                                      1,
                                      values.eanCommerce
                                    );

                                    setCommercesSelected(auxSelected);
                                  } else {
                                    setCommercesSelected([
                                      ...commercesSelected,
                                      values.eanCommerce,
                                    ]);
                                  }

                                  handleCommerceChange(values, index);
                                }
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} lg={2.1} xl={1.8}>
                      <Controller
                        defaultValue=""
                        name={`configurations.${index}.dailyPath`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            onChange={onChange}
                            value={value || ""}
                            className={`${
                              getError(errors, index, "dailyPath")
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 1px 0px",
                                color: "#e52900",
                                fontSize: 10,
                              },
                            }}
                            helperText={getErrorMessage(
                              errors,
                              index,
                              "dailyPath"
                            )}
                            label={t("App.adminSendAllies.modals.dailyPath")}
                            disabled={
                              watch(`configurations.${index}.commerceList`)
                                ? false
                                : true
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={2.1} xl={1.8}>
                      <Controller
                        defaultValue=""
                        name={`configurations.${index}.historicalPath`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            onChange={onChange}
                            value={value || ""}
                            className="modalEditRoles__form-input full-width"
                            disabled={
                              watch(`configurations.${index}.commerceList`)
                                ? false
                                : true
                            }
                            label={t(
                              "App.adminSendAllies.modals.historicalPath"
                            )}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={2.1} xl={1.8}>
                      <Controller
                        defaultValue=""
                        name={`configurations.${index}.reloadPath`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            sx={{
                              label: {
                                fontFamily: "Roboto !important",
                                fontSize: "14px !important",
                                translate: "0px -5px",
                                transformOrigin: "-45px -10px",
                                "&.Mui-focused": {
                                  color: "#8C8D9D",
                                },
                              },
                            }}
                            onChange={onChange}
                            value={value || ""}
                            className="modalEditRoles__form-input full-width"
                            label={t("App.adminSendAllies.modals.reloadPath")}
                            disabled={
                              watch(`configurations.${index}.commerceList`)
                                ? false
                                : true
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} lg={2.1} xl={1.8}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.createUser.state")}
                        </InputLabel>
 
                        <Controller
                          defaultValue=""
                          name={`configurations.${index}.state`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value || ""}
                              className={`${
                                getError(errors, index, "state")
                                  ? "modalEditRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                              disabled={
                                watch(`configurations.${index}.commerceList`)
                                  ? false
                                  : true
                              }
                            >
                              <MenuItem disabled value="">
                                {t("App.createUser.state")}
                              </MenuItem>

                              <MenuItem value="ACTIVO">
                                {t("App.createRole.active")}
                              </MenuItem>

                              <MenuItem value="INACTIVO">
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />

                        {getError(errors, index, "state") && (
                          <FormHelperText
                            sx={{
                              margin: "-8px 0 1px 0px",
                              color: "#e52900",
                              fontSize: 10,
                            }}
                          >
                            {getErrorMessage(errors, index, "state")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} lg={2.1} xl={1.8}>
                      <FormControl className="full-width">
                        <Controller
                          defaultValue={null}
                          control={control}
                          name={`configurations.${index}.sendToDate`}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            formState,
                          }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                inputFormat="YYYY-MM-DD"
                                label={t(
                                  "App.adminSendAllies.modals.sendTo"
                                )}
                                sx={{
                                  translate: "0px -5px",
                                }}
                                disabled={
                                  watch(`configurations.${index}.commerceList`)
                                    ? false
                                    : true
                                }
                                className={`${
                                  getError(errors, index, "sendToDate")
                                    ? "modalEditRoles__form-input--error"
                                    : "modalCreateRoles__form-input"
                                } full-width`}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    autoComplete="off"
                                    size="medium"
                                    label={t("App.allies.filter.typeReloadO")}
                                    {...params}
                                    sx={{
                                      label: {
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "0px -5px",
                                        transformOrigin: "-45px -10px",
                                      },
                                    }}
                                  />
                                )}
                                InputProps={{
                                  sx: {
                                      '& .MuiSvgIcon-root': {
                                        color: "#808080",
                                        fontSize: "20px", // Ajustar el tamaño del ícono (opcional)
                                        position: 'relative', // Permite manipular la posición
                                        top: '-5px', // Ajustar la posición vertical del ícono
                                      },
                                  },
                              }}
                              components={{
                                OpenPickerIcon: CalendarMonthOutlinedIcon, // Aquí reemplazas el ícono predeterminado
                              }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {isDesktop ? (
                      <Grid item xs={1}>
                        {index === 0 ? (
                          <IconButton
                            className={
                              handleAddFieldMobile()
                                ? "addPermission__disabled"
                                : "addPermission__button"
                            }
                            onClick={handleAddNewConfiguration}
                            disabled={handleAddFieldMobile()}
                          >
                            <AddIcon sx={{ fontSize: 22 }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            className={
                              isDisabled(configuration?.selected)
                                ? "addPermission__disabled"
                                : "addPermission__delete"
                            }
                            onClick={() =>
                              handleRemoveConfigurationAndNavigate(index)
                            }
                            disabled={isDisabled(configuration?.selected)}
                          >
                            <ClearIcon sx={{ fontSize: 22 }} />
                          </IconButton>
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                )
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      {isDesktop ? null : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Grid
            item
            style={{ visibility: currentPage === 0 ? "hidden" : "visible" }}
          >
            <IconButton
              className="mapping-page__btn"
              onClick={previousPage}
              disabled={currentPage === 0}
              sx={{ fontSize: 27 }}
            >
              <LeftArrow />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              {t("App.adminSendAllies.modals.commercePag")} {currentPage + 1}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              visibility:
                currentPage === configurationsFields.length - 1
                  ? "hidden"
                  : "visible",
            }}
          >
            <IconButton
              className="mapping-page__btn"
              onClick={nextPage}
              disabled={currentPage === configurationsFields.length - 1}
              sx={{ fontSize: 27 }}
            >
              <RightArrow />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ConfigurationByCommerce;

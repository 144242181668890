import axios from "axios";
import getToken from "../../../config/getToken";
import { env } from "../../../env";

export async function GetBILayers(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `requestQlik/administration`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function ApplyFiltersAdminBILayers(data) {
  const token = await getToken();

  return axios({
    method: "POST",
    url: `reloads/qlik/requestAdmin`,
    data: data,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function GetDeployGuide(data) {
  const token = await getToken();
  return axios({
    method: "GET",
    url: `requestQliks/administration/guides`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function BILayersService(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `requests/savereload`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

//Servicio para listar las capas BI


export async function DeployMonthsList(data) {
  const token = await getToken();
  return axios({
    method: "POST",
    data: data,
    url: `reloads/qlik/months`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function DeployCompany(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    data: data,
    url: `reloads/qlik/deploycompany`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function UpdateBILayers(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    data: data,
    url: `reloads/qlik/updatecompany`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

export async function UpdateMonths(data) {
  const token = await getToken();
  return axios({
    method: "PUT",
    data: data,
    url: `reloads/qlik/updatemonths`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: token,
    },
  });
}

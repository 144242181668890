import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { FilterFormBILayers } from "../BILayers/filterFormBILayers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FilterBILayersRequest = ({
  setData,
  setChipFilters,
  setIsDataLoading,
  setFilterNotFound,
  setNoContent,
  setFormFilters,
}) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(true);

  /**
   * Esquema para validar el filtro de de solicitudes de recarga
   */
  const schema = yup.object().shape({
    action: yup
      .object()
      .shape({ actionName: yup.string(), actionValue: yup.string() })
      .required(t("App.validationMessages.required")),
    initialDate: yup.date().typeError("Fecha no válida").nullable().optional(),
    finalDate: yup.date().typeError("Fecha no válida").nullable().optional(),
    state: yup
      .object()
      .shape({ stateName: yup.string(), stateValue: yup.string() })
      .nullable()
      .optional(),
  });

  /**
   * UseForm
   */
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      action: null,
      finalDate: undefined,
      initialDate: undefined,
      state: null,
    },
  });

  return (
    <Accordion defaultExpanded className="accordion__filter">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />}
      >
        <TuneOutlinedIcon
          sx={{
            fontSize: 20,
            color: "#543ab4",
          }}
        />

        <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
          {" "}
          {t("App.catalogue.filterName")}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <FilterFormBILayers
          control={control}
          errors={errors}
          setValue={setValue}
          reset={reset}
          watch={watch}
          handleSubmit={handleSubmit}
          setData={setData}
          setChipsFilters={setChipFilters}
          setDatatableLoading={setIsDataLoading}
          setFilterNotFound={setFilterNotFound}
          setNoContent={setNoContent}
          setFormFilters={setFormFilters}
        />
      </AccordionDetails>
    </Accordion>
  );
};

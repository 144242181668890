import jwt_decode from "jwt-decode";
import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import { yupResolver } from "@hookform/resolvers/yup";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { CODES } from "../../consts/codes";
import { FilterRole, GetRoleList } from "../../services/admin/roleServices";
import ModalEditRoles from "./modalEditRoles";
import { ModalDeleteRole } from "./modalDeleteRole";
import ModalInfo from "./Modals/modalInfo";
import {
  GetActiveProduct,
  GetMenuLevel1,
} from "../../services/admin/adminServices";
import { getUser } from "../../pages/userSlice";
import { useSelector } from "react-redux";

export const FilterRoles = (props) => {
  const { t } = useTranslation();
  const [roleList, setRoleList] = useState([]);
  const [activeProductsList, setActiveProductsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const userInfo = useSelector(getUser);

  const schema = yup.object().shape({
    ean: yup.string().min(2),
    country: yup.string().min(2),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const handleFilters = async (data) => {
    try {
      props.setFilterNotFound(false);
      const obj = {
        pk: data.role ? data.role.pk : "",
        sk: data.role ? data.role.sk : "",
        state: data.status ? data.status.value : "",
        idMenuOption: data.product ? data.product.value : "",
        clientType: data.clientType ? data.clientType.value : "",
      };

      const roleService = await FilterRole(obj);

      if (roleService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          roleService.data.responseCode === CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          const responseMessage = roleService.data.responseMessage;

          const ProcessRows = responseMessage.map((item) => {
            return {
              product: !item.menuOptionName ? " - " : item.menuOptionName,
              name: !item.nameRole ? " - " : item.nameRole,
              description: !item.descriptionRole ? " - " : item.descriptionRole,
              status: item.state ? (
                <p className="state-colors__primary-color-one">
                  {t("App.userDetail.filter.active")}
                </p>
              ) : (
                <p className="state-colors__tertiary-color-one">
                  {t("App.userDetail.filter.inactive")}
                </p>
              ),
              clientType:
                item.clientType === "SI"
                  ? t("App.listRoles.yes")
                  : t("App.listRoles.no"),
              edit: (
                <IconButton
                  component="span"
                  onClick={() => {
                    props.editRow(item);
                  }}
                >
                  <ModalEditRoles
                    setResponseData={setResponseData}
                    setIsOpenModal={setIsOpenModal}
                    currentRole={item}
                    modified={props.modified}
                    setModified={props.setModified}
                    refreshFilters={props.refreshFilters}
                    setRefreshFilters={props.setRefreshFilters}
                  />
                </IconButton>
              ),
              delete: (
                <ModalDeleteRole
                  modified={props.modified}
                  setModified={props.setModified}
                  role={item}
                  refreshFilters={props.refreshFilters}
                  setRefreshFilters={props.setRefreshFilters}
                />
              ),
            };
          });
          props.setFoundResults(true);
          props.setData(ProcessRows);
        } else {
          props.setData([]);
          props.setFilterNotFound(true);
        }
      }
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  useEffect(() => {
    let mounted = true;
    reset();
    async function fetchData() {
      try {
        if (mounted) {
          const statusService = await GetRoleList();

          if (statusService.status === CODES.COD_RESPONSE_HTTP_OK) {
            const response = statusService.data.responseMessage;
            const roleList = response.map((item) => ({
              label: item.nameRole,
              pk: item.pk,
              sk: item.sk,
            }));

            setRoleList(roleList);
          }

          if (userInfo) {
            const country = userInfo.company.country;
            const eanCompany = userInfo.company.eanCompany;

            const obj = {
              country,
              eanCompany,
            };

            setLoading(true);

            const productService = await GetActiveProduct(obj);

            if (productService.status === CODES.COD_RESPONSE_HTTP_OK) {
              const response = productService.data.responseMessage;
              const activeProductsList = response.map((item) => ({
                label: item.menuOptionName,
                value: item.idMenuOption,
              }));

              setActiveProductsList(activeProductsList);
            }

            setLoading(false);
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [props.refreshFilters, userInfo]);

  const options = [
    { label: t("App.userDetail.filter.active"), value: 1 },
    { label: t("App.userDetail.filter.inactive"), value: 0 },
  ];

  const clientTypeOptions = [
    { label: t("App.listRoles.yes"), value: "SI" },
    { label: t("App.listRoles.no"), value: "NO" },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid
        xl={10}
        lg={8.9}
        md={4}
        sm={9}
        xs={12}
        item
      >
        <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
          <Grid
            container
            direction="column"
            rowSpacing={4}  
            justifyContent={"center"}
            width={"100%"}
            columnSpacing={2}
            sx={{ 
              paddingLeft: { xs: 2, lg: 0 },
             }}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent={"center"}
                rowSpacing={4}  
                spacing={2}
              >
                <Grid item  lg={5.85} md={3} sm={6} xs={12} width={"100%"}>
                  <Controller
                    name="product"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          errors.product
                            ? "select__filters__errors"
                            : "select__filters__full"
                        }
                        options={activeProductsList}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={t("App.listUsers.product")}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                      <CircularProgress
                                        sx={{ color: "#4DCAFA" }}
                                        size={15}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              // onChange={onChange}
                              sx={{
                                label : {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D !important",
                                    
                                  },
                                },
                              }}
                              />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
  
                <Grid item lg={5.85} md={6} sm={6} xs={12} width={"100%"}>
                  <Controller
                    name="status"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          errors.status
                            ? "select__filters__errors"
                            : "select__filters__full"
                        }
                        options={options}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={t("App.userDetail.roleStatus")}
                              {...params}
                              onChange={onChange}
                              sx={{
                                label : {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D !important",
                                  },
                                },
                              }}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                  container
                  direction="row"
                  spacing={2}
                  rowSpacing={4}
                  justifyContent={"center"}
                  fullwidth
              >
                <Grid item lg={5.85} md={6} sm={6} xs={12} width={"100%"}>
                  <Controller
                    name="role"
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          errors.role
                            ? "select__filters__errors"
                            : "select__filters__full"
                        }
                        options={roleList}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={t("App.userDetail.role")}
                              {...params}
                              onChange={onChange}
                              sx={{
                                label : {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D !important",
                                  },
                                },
                              }}
                            />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>

                <Grid item lg={5.85} md={6} sm={6} xs={12} width={"100%"}>
                  <Controller
                    name="clientType"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        noOptionsText={t("App.listUsers.noOptions")}
                        className={
                          errors.clientType
                            ? "select__filters__errors"
                            : "select__filters__full"
                        }
                        options={clientTypeOptions}
                        renderInput={(params) => {
                          return (
                            <TextField
                              label={t("App.listRoles.clientType")}
                              {...params}
                              onChange={onChange}
                              sx={{
                                label : {
                                  fontFamily: "Roboto !important",
                                  fontSize: "14px !important",
                                  translate: "0px -5px",
                                  transformOrigin: "-45px -10px",
                                  "&.Mui-focused": {
                                    color: "#8C8D9D !important",
                                  },
                                },
                              }}
                              />
                          );
                        }}
                        onChange={(event, values, reason) => {
                          onChange(values);
                        }}
                        value={value || null}
                      />
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      
      <Grid className="side-line-contact__lineVertical__two"></Grid>

      <Grid xl={1.8} lg={3} md={2} sm={2.8} xs={12} width={"100%"} item>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent={"center"}
          columnSpacing={2}
          sx={{ rowGap: {sm: 2, xs: 1}}}
        >
          <Grid item className="btn__applyFilter__container">
            <Button
              startIcon={
                <TuneOutlinedIcon
                  sx={{
                    fontSize: 20,
                    color: "white",
                  }}
                />
              }
              disableRipple
              className={`${
                watch("product") ||
                watch("status") ||
                watch("role") ||
                watch("clientType")
                  ? "btn__applyFilter"
                  : "btn__applyFilter-disabled"
              }`}
              type="submit"
              form="hook-form"
              disabled={
                watch("product") ||
                watch("status") ||
                watch("role") ||
                watch("clientType")
                  ? false
                  : true
              }
            >
              {t("App.userDetail.applyFilters")}
            </Button>
          </Grid>

          <Grid item className="btn__applyFilter__container">
            <Button
              onClick={() => {
                setValue("product", null);
                setValue("status", null);
                setValue("role", null);
                setValue("clientType", null);
                props.setData(props.unfilteredData);
                props.setFilterNotFound(false);
              }}
              disableRipple
              className="btn__deleteFilter"
            >
              {t("App.userDetail.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ModalInfo
        title={""}
        responseData={responseData}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
      />
    </Grid>
  );
};

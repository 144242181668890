import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  CardHeader,
  Avatar,
} from "@mui/material";
import { Box } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUser } from "../../../pages/userSlice";
import { CODES } from "../../../consts/codes";
import ModalInfo from "../Modals/modalInfo";
import CardIcons from "./cardIcons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  ApplyFiltersCards,
  EditIndicatorCard,
  ListIndicatorType,
  ListOrderCard,
} from "../../../services/admin/adminServices";
import { REGEXP } from "../../../consts/regexp";
import { ModalDecision } from "../Modals/modalDecision";

const EditCard = ({
  insight,
  indicatorTypeFilter,
  setData,
  dataArray,
  setDataStructureArray,
  setIsNoContentFilter,
  setOpenErrorModal,
}) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 576);
  const [openEditCard, setOpenEditCard] = useState(false);
  const [editCard, setEditCard] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [indicatorTypeList, setIndicatorTypeList] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalDecision, setOpenModalDecision] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const userInfo = useSelector(getUser);
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;
  const [objForm, setObjForm] = useState({});
  const [permit, setPermit] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [typeSelected, setTypeSelected] = useState({
    label: "",
    typeEnglish: "",
  });

  /**
   * t of useTranslation to translate
   */
  const { t, i18n } = useTranslation();

  /**
   * Method to update the state of isDesktop
   */
  const updateMedia = () => {
    setDesktop(window.innerWidth > 490);
  };

  /**
   * useEffect to update the state of isDesktop based on the size of the browser window
   */
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  /**
   * Schema for the edit card form
   */
  const schema = yup.object().shape({
    insightName: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA4,
        t("App.validationMessages.noSpecialCharacters", {
          characters: "< > -- " + '"' + " ' ;",
        })
      ),
    description: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))

      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA4,
        t("App.validationMessages.noSpecialCharacters", {
          characters: "< > -- " + '"' + " ' ;",
        })
      ),
    insightNameEnglish: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))

      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA4,
        t("App.validationMessages.noSpecialCharacters", {
          characters: "< > -- " + '"' + " ' ;",
        })
      ),
    descriptionEnglish: yup
      .string()
      .max(150, t("App.validationMessages.maxCharacters", { number: 150 }))
      .required(t("App.validationMessages.required"))
      .matches(
        REGEXP.SPECIAL_QA3,
        t("App.validationMessages.noSpecialCharacters", {
          characters: "< > -- " + '"' + " ' ;",
        })
      ),
  });

  /**
   * useEffect to set the values of the fields of the form
   */
  useEffect(() => {
    if (insight) {
      infoDefault();
    }
  }, [insight]);

  const infoDefault = () => {
    setValue("insightName", insight.name);
    setValue("description", insight.description);
    setValue("insightNameEnglish", insight.nameEnglish);
    setValue("descriptionEnglish", insight.descriptionEnglish);
    setValue("insightType", insight.indicatorType);
    setValue("order", insight.indicatorOrder);
    setValue("status", insight.state);
    setSelectedIcon(insight.icon);
    if (insight && insight.indicatorType) getListOrder(insight.indicatorType);
  };

  /**
   * useForm to resolver of the schema validation
   */
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  /**
   * Obtener de tipo de indicador seleccionado
   */
  const OnChangeType = (e) => {
    setTypeSelected(
      indicatorTypeList.find((insight) => insight.value === e.target.value)
    );
  };

  /**
   * Method to open the modal
   */
  const handleOpen = () => {
    async function fetchData() {
      try {
        const indicatorsType = await ListIndicatorType();

        const { responseMessage, responseCode } = indicatorsType.data;

        if (indicatorsType.status === CODES.COD_RESPONSE_HTTP_OK) {
          if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
            const indicatorType = responseMessage.map((indicator) => ({
              label: indicatorTypeFilter(indicator),
              value: indicator.indicatorType,
              product: indicator.product,
              typeEnglish: indicator.typeIndicatorEnglish,
              companyType: indicator.companyType
            }));

            setIndicatorTypeList(indicatorType);
          } else {
            setOpenErrorModal(true);
          }
        } else {
          setOpenErrorModal(true);
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    fetchData();
    setOpenEditCard(true);
  };

  /**
   * Obtener el listado de ordenes de las tarjetas segun el tipo de indicador
   */
  const getListOrder = async (indicatorType) => {
    try {
      const orderList = await ListOrderCard(indicatorType);

      const { responseMessage, responseCode } = orderList.data;
      if (orderList.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (responseCode === CODES.COD_RESPONSE_SUCCESS) {
          const orderUpdate = responseMessage.lstOrderUpdate.map((order) => ({
            order,
          }));

          setOrderList(orderUpdate);
        } else {
          setOpenErrorModal(true);
        }
      } else {
        setOpenErrorModal(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Method to close the modal
   */
  const handleClose = () => {
    setOpenEditCard(false);
    infoDefault();
  };

  const handleAgree = async () => {
    setIsLoading(true);
    await handleEdit();
    setObjForm({});
    setOpenModalDecision(false);
  };

  const handleEdit = async (obj = null) => {
    try {
      const editService = await EditIndicatorCard(
        Object.keys(objForm).length > 0 ? objForm : obj
      );
      // console.log(editService);

      if (editService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          Number(editService.data.responseCode) ===
          CODES.COD_RESPONSE_SUCCESS_REQUEST
        ) {
          handleFilters();
          setOpenModalInfo(true);
          setEditCard(editService);
        } else if (
          editService.data.responseCode === CODES.COD_RESPONSE_ERROR_LOGIN
        ) {
          setTitle(t("App.indicatorCards.modals.cantEdit"));
          setOpenModalInfo(true);
          setEditCard(editService);
        } else {
          setTitle(setModalInfoTitle(editService.data.responseCode));
          setOpenModalInfo(true);
          setEditCard(editService);
        }
      } else {
        setOpenErrorModal(true);
      }
      setIsLoading(false);
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  const handleDisagree = () => {
    setPermit(false);
    setObjForm({});
    setOpenEditCard(false);
    setOpenModalDecision(false);
    setIsLoading(false);
    infoDefault();
  };

  /**
   * Method to delete the icon selected
   */
  const handleDelete = () => {
    setSelectedIcon(null);
  };

  /**
   *
   * Method to set the title of te modalInfo by responseCode
   *
   * @param {String} responseCode
   * @returns the title in its respective language
   */
  const setModalInfoTitle = (responseCode) => {
    if (responseCode === 1005) {
      return t("App.indicatorCards.modalInfo.title");
    } else {
      return "";
    }
  };

  /**
   *
   * Method to edit an indicator card
   *
   * @param {Object} data
   */
  const handleEditCard = async (data) => {
    try {
      const obj = {
        pk: insight.pk,
        sk: insight.sk,
        name: data.insightName,
        nameEnglish: data.insightNameEnglish,
        description: data.description,
        descriptionEnglish: data.descriptionEnglish,
        indicatorType: data.insightType,
        indicatorOrder: data.order,
        state: data.status,
        icon: selectedIcon,
        modificationUser: fullNameUser,
        country: userInfo.company.country,
        product: insight.product,
        typeIndicatorEnglish:
          typeSelected.typeEnglish !== ""
            ? typeSelected.typeEnglish
            : insight.typeIndicatorEnglish,
        typeIndicatorName:
          typeSelected.label !== ""
            ? typeSelected.label
            : indicatorTypeFilter(insight),
        companyType:
          typeSelected.companyType !== ""
            ? typeSelected.companyType
            : insight.companyType,
            
      };
      setObjForm(obj);
      if (insight.state === "1" && data.status === 0) {
        setTitleModal(
          t("App.indicatorCards.modals.setStatus", {
            indicator: data.insightName,
          })
        );
        setOpenModalDecision(true);
      } else {
        setIsLoading(true);
        await handleEdit(obj);
      }

      // console.log(obj);
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Method to filter the data of the table by product and/or role
   */
  const handleFilters = async () => {
    // console.log("la data", data);
    try {
      const lstTypeIndicator = dataArray.map((data) => data.indicatorType);
      const obj = {
        lstTypeIndicator,
        state: "",
      };

      // setData([]);
      const filterService = await ApplyFiltersCards(obj);
      // console.log(filterService);

      const { responseCode, responseMessage } = filterService.data;

      if (
        filterService.status === CODES.COD_RESPONSE_HTTP_OK &&
        Number(responseCode) === CODES.COD_RESPONSE_SUCCESS_REQUEST
      ) {
        const formated = setDataStructureArray(responseMessage);
        setData(formated);
        setIsNoContentFilter(false);
      } else {
        setIsNoContentFilter(true);
      }
    } catch (error) {
      setOpenErrorModal(true);
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <EditOutlinedIcon sx={{ fontSize: 22, color: "#543ab4" }} />
      </IconButton>

      <Modal
        className="modalEditRoles__form-box"
        open={openEditCard}
        onClose={handleClose}
      >
        <Box className="scroll-y modal-box-createEditCompany">
          <Grid item container justifyContent="flex-end">
            <IconButton component="span" onClick={handleClose}>
              <CloseIcon sx={{ color: "#543AB4", fontSize: "2rem" }} />
            </IconButton>
          </Grid>

          <Grid
            container
            direction="column"
            spacing={1}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item>
              <h1 className="display-large__primary-one">
                {t("App.indicatorCards.modals.title2")}{" "}
                {i18n.language.includes("es")
                  ? insight.name
                  : insight.nameEnglish}
              </h1>
            </Grid>

            <Grid item>
              <form onSubmit={handleSubmit(handleEditCard)}>
                <Grid container item direction="column">
                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item lg={4} xs={12}>
                      <Controller
                        defaultValue=""
                        name="insightName"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <TextField
                            className={`${
                              errors.insightName
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            helperText={
                              errors.insightName
                                ? errors.insightName.message
                                : ""
                            }
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                color: "#e52900",
                              },
                            }}
                            id={name}
                            name={name}
                            onBlur={onBlur} // notify when input is touched/blur
                            inputRef={ref} // send input ref, so we can focus on input when error appear
                            label={t("App.indicatorCards.modals.insightName")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="description"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <TextField
                            className={`${
                              errors.description
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            helperText={
                              errors.description
                                ? errors.description.message
                                : ""
                            }
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                color: "#e52900",
                              },
                            }}
                            id={name}
                            name={name}
                            onBlur={onBlur} // notify when input is touched/blur
                            inputRef={ref} // send input ref, so we can focus on input when error appear
                            label={t("App.indicatorCards.modals.description")}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <Controller
                        defaultValue=""
                        name="insightNameEnglish"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <TextField
                            className={`${
                              errors.insightNameEnglish
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            helperText={
                              errors.insightNameEnglish
                                ? errors.insightNameEnglish.message
                                : ""
                            }
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                color: "#e52900",
                              },
                            }}
                            id={name}
                            name={name}
                            onBlur={onBlur} // notify when input is touched/blur
                            inputRef={ref} // send input ref, so we can focus on input when error appear
                            label={t(
                              "App.indicatorCards.modals.insightNameEnglish"
                            )}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <Controller
                        defaultValue=""
                        name="descriptionEnglish"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <TextField
                            className={`${
                              errors.descriptionEnglish
                                ? "modalEditRoles__form-input--error"
                                : "modalCreateRoles__form-input"
                            } full-width`}
                            helperText={
                              errors.descriptionEnglish
                                ? errors.descriptionEnglish.message
                                : ""
                            }
                            InputLabelProps={{
                              style: {
                                margin: "-5px 0 0 -10px",
                                padding: "0 0 0 5px",
                                color: "#8c8d9d",
                                fontSize: 13,
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                margin: "-5px 0 0 0",
                                color: "#e52900",
                              },
                            }}
                            id={name}
                            name={name}
                            onBlur={onBlur} // notify when input is touched/blur
                            inputRef={ref} // send input ref, so we can focus on input when error appear
                            label={t(
                              "App.indicatorCards.modals.descriptionEnglish"
                            )}
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.indicatorCards.modals.insightType")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="insightType"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={(newValue) => {
                                onChange(newValue); // Update form control value
                                OnChangeType(newValue);
                              }}
                              value={value}
                              className={`${
                                errors.insightType
                                  ? "modalEditRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.indicatorCards.modals.insightType")}
                              </MenuItem>

                              {indicatorTypeList.map((insight) => (
                                <MenuItem
                                  key={insight.value}
                                  value={insight.value}
                                >
                                  {insight.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.indicatorCards.modals.order")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="order"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.order
                                  ? "modalEditRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.indicatorCards.modals.order")}
                              </MenuItem>
                              {orderList.map((item) => (
                                <MenuItem value={item.order} key={item.order}>
                                  {item.order}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} xs={12}>
                      <FormControl className="full-width">
                        <InputLabel className="modalCreateOptions__form__inputLabel ">
                          {t("App.listUsers.status")}
                        </InputLabel>

                        <Controller
                          defaultValue=""
                          name="status"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              onChange={onChange}
                              value={value}
                              className={`${
                                errors.status
                                  ? "modalEditRoles__form-input--error"
                                  : "modalCreateRoles__form-input"
                              } full-width`}
                            >
                              <MenuItem disabled value="">
                                {t("App.createAndEditCompany.status")}
                              </MenuItem>
                              <MenuItem value={1}>
                                {t("App.createRole.active")}
                              </MenuItem>
                              <MenuItem value={0}>
                                {t("App.createRole.inactive")}
                              </MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <br />

                <Grid item container spacing={2}>
                  <Grid item xs={12} lg={2.5} sm={4}>
                    <CardIcons
                      selectedIcon={selectedIcon}
                      setSelectedIcon={setSelectedIcon}
                    />
                  </Grid>

                  <Grid item>
                    {selectedIcon && (
                      <Chip label={selectedIcon} onDelete={handleDelete} />
                    )}
                  </Grid>
                </Grid>

                <br />

                <Grid
                  item
                  container
                  spacing={2}
                  direction={isDesktop ? "row" : "column-reverse"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={2} lg={1} className="full-width">
                    <Button
                      className="btn__filled__gradient full-width"
                      onClick={handleClose}
                    >
                      {t("App.buttonLabels.cancel")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={5} lg={2.5} className="full-width">
                    <Button
                      disabled={
                        watch("insightName") &&
                        watch("description") &&
                        watch("insightNameEnglish") &&
                        watch("descriptionEnglish") &&
                        watch("status") !== "" &&
                        watch("order") &&
                        watch("insightType") &&
                        selectedIcon
                          ? false
                          : true
                      }
                      className={`${
                        watch("insightName") &&
                        watch("description") &&
                        watch("insightNameEnglish") &&
                        watch("descriptionEnglish") &&
                        watch("status") !== "" &&
                        watch("order") &&
                        watch("insightType") &&
                        selectedIcon
                          ? "btn__applyFilter"
                          : "btn__applyFilter-disabled"
                      } full-width`}
                      type="submit"
                      startIcon={!isLoading ? <CheckIcon /> : <></>}
                    >
                      {!isLoading ? (
                        t("App.buttonLabels.confirmChange")
                      ) : (
                        <CircularProgress size={21} color="inherit" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {openModalInfo && (
        <ModalInfo
          title={title}
          responseData={editCard}
          open={openEditCard}
          onClose={() => {
            setOpenEditCard(false);
            setOpenModalInfo(false);
            infoDefault();
          }}
        />
      )}

      <ModalDecision
        title={titleModal}
        message={""}
        disagreeText={t("App.buttonLabels.cancel")}
        agreeText={t("App.buttonLabels.accept")}
        setIsOpen={setOpenModalDecision}
        isOpen={openModalDecision}
        handleAgree={handleAgree}
        handleDisagree={handleDisagree}
      />
    </>
  );
};

export default EditCard;

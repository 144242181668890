import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  FormControl,
  TextField,
  Tooltip,
  Checkbox,
  Chip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBoxOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  getCurrentCompany,
  getDataKeyFilters,
  setDataKeyFilters,
} from "../../parts/listUsers/listUsersSlice";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  ApplyFiltersProduct,
  GetMenuLevel1,
} from "../../services/admin/adminServices";
import { CODES } from "../../consts/codes";
import {
  GetSuppliersService,
  GetTradersService,
} from "../../services/user/deleteAndReload/deleteAndReload";
import { ApplyFiltersAdminBILayers } from "../../services/user/BILayers/requestBILayers";
import { GetGeographiesList } from "../../services/admin/geographies/geographiesService";
import { getLstCompanies } from "../../parts/businessGroup/businessGroupSlice";
import { Layers } from "@mui/icons-material";

export const FilterAdminBILayers = ({
  setOpenErrorModal,
  setFilters,
  setFiltersInfo,
  setObjfilters,
  objfilters,
  isNavigateToUsers,
  setData,
  setFoundResults,
  companyList,
  setCompanyList,
  setCompanyType,
  setCountryValue,
  setCountry,
  filters,
  data,
  setFilterNotFound,
  setIsLoadingData,
  setFormFilters,
  setExpandedDeploy,
  setExpandedUpdate,
  setLocked,
  messageData,
  setMessageData,
}) => {
  const [unfilteredList, setUnfilteredList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [companiesUnalterd, setCompaniesUnaltered] = useState([]);

  const [eanList, setEanList] = useState([]);
  const [enableTypeCompany, setEnableTypeCompany] = useState(false);
  const [enabledFields, setEnabledFields] = useState(false);
  const [enabledEan, setEnabledEan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoadingFields, setIsLoadingFields] = useState(false);

  const [bussinessList, setBussinessList] = useState([]);
  const [appList, setAppList] = useState([]);
  const dataKeyFilters = useSelector(getDataKeyFilters);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  let formFilters;

  /**
   * Use Dispatch
   */
  const dispatch = useDispatch();

  /**
   * Use Selector
   */

  const infoCompanyFilter = useSelector(getCurrentCompany);

  /**
   * t para la traducción de los textos
   */
  const { t } = useTranslation();

  /**
   * Opciones para el tipo de empresa
   */
  const typeComps = [
    {
      label: t("App.listUsers.retail"),
      value: "C",
    },
    {
      label: t("App.listUsers.supplier"),
      value: "F",
    },
  ];

  /**
   * Lista de opciones para App
   */
  const appOptions = [
    {
      label: t("App.downloadSalesForce.selectAll"),
      value: t("App.downloadSalesForce.selectAll"),
    },
    { label: t("App.BILayers.filters.sales_stock"), value: "sales-stock" },
    { label: t("App.BILayers.filters.transmission"), value: "transmission" },
    { label: t("App.BILayers.filters.budget"), value: "budget" },
    { label: t("App.BILayers.filters.invoice"), value: "invoice" },
    { label: t("App.BILayers.filters.payroll"), value: "payroll" },
  ];

  /**
   * Opciones para la configuración del listado de los autocomplete
   */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 200,
  });

  /**
   * Función que determina si un campo ha sido rellenado o no
   * @param {*} fieldName nombre del campo
   * @returns true si fue rellenado, false si no
   */
  const isFieldFilled = (fieldName) => {
    const currentField = watch(fieldName);
    switch (fieldName) {
      case "country":
        return currentField !== null;
      case "typeCompany":
        return currentField !== null;
      case "bussiness":
        return currentField.length !== 0;
      case "ean":
        return currentField !== null;
      case "app":
        return currentField.length !== 0;
      default:
        return currentField !== null;
    }
  };

  /**
   * Consulta los países registrados en la plataforma si estos no se encuentran en Redux
   */
  useEffect(() => {
    const getGeographies = async () => {
      try {
        const geographiesService = await GetGeographiesList();

        setLoading(true);

        if (
          geographiesService.status === CODES.COD_RESPONSE_HTTP_OK &&
          (geographiesService.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS_REQUEST ||
            geographiesService.data.responseCode === CODES.COD_RESPONSE_SUCCESS)
        ) {
          const responseMessage = geographiesService.data.responseMessage;

          let countries = responseMessage.map((country) => {
            return {
              countryName: country.countryName,
              country: country.country,
            };
          });

          setUnfilteredList(countries);
          setCountryList(countries);

          // setValuesOfTheFields();
        }

        setLoading(false);
      } catch (error) {
        console.log(
          "============== Error FiltersAdminProducts.jsx useEffect getGeographies ======================"
        );
        console.log(error);
        console.log("====================================");
      }
    };

    getGeographies();
  }, []);

  /**
   * Función para desabilitar typeCompany cuando el campo pais no esta seleccionado
   */
  const disableType = () => {
    if (watch("country")) {
      setEnableTypeCompany(true);
      return false;
    } else {
      setEnableTypeCompany(false);
      return true;
    }
  };

  const disableFields = () => {
    if (watch("country") && watch("typeCompany")) {
      setEnabledFields(true);
      return false;
    } else {
      setEnabledFields(false);
      return true;
    }
  };

  const disableEan = () => {
    if (watch("typeCompany") && watch("bussiness")) {
      if (watch("bussiness").length > 1) {
        setEnabledEan(false);
        return true;
      } else {
        setEnabledEan(true);
        return false;
      }
    } else {
      setEnabledEan(false);
      return true;
    }
  };

  const getCompaniesList = async () => {
    let newCompanies = [];
    const country = getValues("country").country;
    if (getValues("typeCompany").value === "C") {
      newCompanies = await fetchSuppliers(country);
    } else {
      newCompanies = await fetchTraders(country);
    }
    const eanList = newCompanies.map((item) => item.value);

    const companyList = newCompanies.map((item) => ({
      label: item.label,
      value: item.value,
    }));

    companyList.unshift({
      label: t("App.downloadSalesForce.selectAll"),
      value: t("App.downloadSalesForce.selectAll"),
    });

    setEanList(eanList);
    setCompanyList(companyList);
    setCompaniesUnaltered(companyList);
  };

  /**
   *
   * Consulta los proveedores de un país específico
   *
   * @param {*} country país a consultar
   * @returns lista de proveedores por país consultado
   */
  const fetchSuppliers = async (country) => {
    try {
      const suppliersService = await GetSuppliersService({ country });
      const responseCode = suppliersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = suppliersService.data.responseMessage;

          let suppliersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          return suppliersList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error FiltersAdminProducts.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para consultar los comercios de un país específico
   * @param {*} country País seleccionado
   * @returns lista de comercios por país consultado
   */
  const fetchTraders = async (country) => {
    try {
      const tradersService = await GetTradersService({ country });
      const responseCode = tradersService.data.responseCode;

      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          const responseMessage = tradersService.data.responseMessage;

          let tradersList = responseMessage.map((item) => {
            return {
              label: item.companyName,
              value: item.eanCompany,
            };
          });

          return tradersList;
        default:
          return [];
      }
    } catch (error) {
      console.log(
        "================= Error FiltersAdminProducts.jsx fetchSuppliers ==================="
      );
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   *
   * Función para reinicar los campos de empresa, eanEmpresa y producto cuando se cambia el país
   *
   */
  const handleChangeCountry = async (values, reason) => {
    setValue("typeCompany", null);
    setValue("bussiness", []);
    setValue("ean", null);
    setValue("app", []);
    setEnableTypeCompany(false);
    setEnabledFields(false);
    // setIsLoadingFields(true);
    setCompanyType("");

    if (reason === "clear") {
      setCountry("");
      setCountryValue("");
    } else {
      setCountry(values.country);
      setCountryValue(values.country);
    }
  };

  /**
   * Handles the input change of type notification field
   * @param {array} values list of selected countries
   */
  const handleCompaniesChange = (values, reason, detail) => {
    setBussinessList(values);

    if (values) {
      const withSelectAll = values.map((item) => item.value);
      const withoutSelectAll = values.filter(
        (item) => item.value !== t("App.downloadSalesForce.selectAll")
      );
      //Revisando si selectAll fue deseleccionado
      if (
        reason === "removeOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("bussiness", []);
      }
      //Revisando si selectAll fue seleccionado
      if (
        reason === "selectOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("bussiness", companyList);
      }
      //Revisando si todos los campos a excepcion de selectAll estan seleccionados
      if (
        values.length >= companyList.length - 1 &&
        reason === "selectOption" &&
        !withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("bussiness", companyList);
      }
      //Revisando si se deselecciono alguna opcion
      if (
        reason === "removeOption" &&
        values.length < companyList.length &&
        withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("bussiness", withoutSelectAll);
      }

      if (values.length === 1) {
        setValue("ean", values[0].value);
      } else {
        setValue("ean", null);
      }
    } else {
      setValue("bussiness", []);
    }
  };

  /**
   * Handles the input change of type notification field
   * @param {array} values list of selected countries
   */
  const handleAppChange = (values, reason, detail) => {
    if (values) {
      const withSelectAll = values.map((item) => item.value);
      const withoutSelectAll = values.filter(
        (item) => item.value !== t("App.downloadSalesForce.selectAll")
      );
      //Revisando si selectAll fue deseleccionado
      if (
        reason === "removeOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("app", []);
      }
      //Revisando si selectAll fue seleccionado
      if (
        reason === "selectOption" &&
        detail.option.value === t("App.downloadSalesForce.selectAll")
      ) {
        setValue("app", appOptions);
      }
      //Revisando si todos los campos a excepcion de selectAll estan seleccionados
      if (
        values.length >= appOptions.length - 1 &&
        reason === "selectOption" &&
        !withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("app", appOptions);
      }
      //Revisando si se deselecciono alguna opcion
      if (
        reason === "removeOption" &&
        values.length < appOptions.length &&
        withSelectAll.includes(t("App.downloadSalesForce.selectAll"))
      ) {
        setValue("app", withoutSelectAll);
      }
    }
  };

  /**
   *
   * Función para colocar el valor correspondiente en el autocomplete de empresa de acuerdo al eanEmpresa escogido
   *
   *  @param {string} values Valor seleccionado
   */
  const handleChangeEan = (values) => {
    if (values) {
      const ean = values;

      //Busca en companyList la compañía que tenga el ean seleccionado
      let companySelected = companyList.find(
        (company) => company.value === ean
      );

      setValue("bussiness", [companySelected]);
      setCompanyList(companyList);
    } else {
      setValue("bussiness", []);
    }
  };

  /**
   * Función para reiniciar los valores del campo de empresa cuando eanEmpresa está vacio y viceversa
   */
  const resetValuesWhenClear = () => {
    setValue("ean", null);
    setValue("bussiness", []);

    const companyList = companiesUnalterd.map((item) => {
      return { companyName: item.companyName, eanCompany: item.eanCompany };
    });

    const eanList = companyList.map((item) => item.eanCompany);

    setCompanyList(companyList);
    setEanList(eanList);
  };

  /**
   *
   * Función para filtrar los resultados a mostrar en la tabla, de acuerdo al país, empresa, eanEmpresa y/o producto
   *
   * @param {Object} data Datos de los filtros
   */
  const handleFilters = async (data) => {
    if (data) {
      formFilters = data;
    }
    let companiesWithoutSelectAll = [];

    if (data.bussiness.length < 1) {
      //cambiar los campos label y value por companyName y eanCompany
      companiesWithoutSelectAll = companyList.map((item) => ({
        companyName: item.label,
        eanCompany: item.value,
      }));
      // companiesWithoutSelectAll = companyList;
    } else {
      companiesWithoutSelectAll = data.bussiness
        .filter((item) => item.value !== t("App.downloadSalesForce.selectAll"))
        .map((item) => ({
          companyName: item.label,
          eanCompany: item.value,
        }));
    }

    let appWithoutSelectAll = [];
    if (data.app.length < 1) {
      appWithoutSelectAll = appOptions
        .filter((item) => item.value !== t("App.downloadSalesForce.selectAll"))
        .map((item) => item.value);
    } else {
      //Tomar el valor de la app seleccionada
      appWithoutSelectAll = data.app
        .filter((item) => item.value !== t("App.downloadSalesForce.selectAll"))
        .map((item) => item.value);
    }

    try {
      setData([]);
      setFoundResults(false);
      setFilterNotFound(true);
      setIsLoadingData(true);
      let obj = {
        country: data.country.country,
        companyType: data.typeCompany.value,
        lstCompanies: companiesWithoutSelectAll,
        lstNameAPP: appWithoutSelectAll,
      };

      const filterLayers = await ApplyFiltersAdminBILayers(obj);
      const responseCode = filterLayers.data.responseCode;
      let responseMessage = filterLayers.data.responseMessage;
      if (responseMessage.length == 0) {
        setFilterNotFound(true);
      } else {
        setFilterNotFound(false);
        setMessageData(responseMessage);
      }
      switch (responseCode) {
        case CODES.COD_RESPONSE_SUCCESS_REQUEST:
          //Setteamos la data de la tabla
          const formattedData = setDataStructure(responseMessage);
          setData(formattedData);
          break;
        case CODES.COD_RESPONSE_ERROR_LOGIN:
          setData([]);
          setFilterNotFound(true);
          break;
        default:
          setData([]);
          setFilterNotFound(true);
          setOpenErrorModal(true);
          break;
      }

      setIsLoadingData(false);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  /**
   * Función para cambiar el tipo de empresa
   * @param {*} values valor seleccionado
   */
  const handleChangeTypeCompany = async (values) => {
    setValue("bussiness", []);
    setValue("ean", null);
    setValue("app", []);
    setCompanyList([]);
    setEanList([]);
    if (values) {
      setCompanyType(values.value);
      setEnabledFields(true);
      await getCompaniesList();
    } else {
      setCompanyType("");
      setEnabledFields(false);
    }
  };

  /**
   * Schema para el formulario de filtrar capas BI
   */
  const schema = yup.object().shape({
    country: yup.object().shape({
      countryName: yup
        .string()
        .required(t("App.validationMessages.required"))
        .min(2),
      country: yup.string(),
    }),
    typeCompany: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .required(t("App.validationMessages.required")),
  });

  /**
   * useForm para resolver el schema de validación
   */
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: null,
      typeCompany: null,
      bussiness: [],
      app: [],
    },
  });

  /**
   * Función para habilitar el botón de filtros
   */
  const disableApplyFiltersBtn = () => {
    if (watch("country") && watch("typeCompany")) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Función para limpiar los filtros
   */
  const cleanFilters = () => {
    setValue("country", null);
    setValue("ean", null);
    setValue("bussiness", []);
    setValue("typeCompany", null);
    setValue("app", []);
    setCompanyList([]);
    setEnableTypeCompany(false);
    setEnabledFields(false);
    setEanList([]);
    setFoundResults(true);
    setData([]);
    setFilterNotFound(false);
  };

  /**
   * Estructura la data entrante en el formato de la tabla
   * @param {Array} data data a formattear
   * @returns data con formato de tabla
   */
  const setDataStructure = (data) => {
    let appContent = "-"; // Valor predeterminado en caso de no haber dato
    let layerName = "-";
    let months = "-";

    // Condicional para asignar el contenido adecuado a 'app'

    const processRows = data.map((item) => {
      // Extraemos la clave y el valor del objeto layer
      if (item.layers && typeof item.layers === "object") {
        const layerKeys = Object.keys(item.layers);
        if (layerKeys.length > 0) {
          //Convertimos layerKeys en un string con todos los valores separados por comas
          layerName = layerKeys.join(", ");
          //Convertimos months en un string con todos los valores separados por comas
          months = Object.values(item.layers).join(", ");
        }
      }
      switch (item.nameApp) {
        case "sales-stock":
          appContent = t("App.BILayers.filters.sales_stock");
          break;
        case "transmission":
          appContent = t("App.BILayers.filters.transmission");
          break;
        case "budget":
          appContent = t("App.BILayers.filters.budget");
          break;
        case "invoice":
          appContent = t("App.BILayers.filters.invoice");
          break;
        case "payroll":
          appContent = t("App.BILayers.filters.payroll");
          break;
        default:
          appContent = "-";
          break;
      }
      return {
        company: !item.companyName ? "-" : item.companyName,
        eanCompany: !item.eanCompany ? "-" : item.eanCompany,
        app: !item.nameApp ? "-" : appContent,
        layer: !item.layers ? "-" : layerName,
        deployTime: !item.layers ? "-" : months,
      };
    });

    return processRows;
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        xs={12}
        sm={8}
        xl={9}
        lg={8.5}
        md={8.5}
        item
        className="side-line-contact__bottomAlign"
        sx={{ padding: "0" }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <form id="hook-form" onSubmit={handleSubmit(handleFilters)}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item className="filter_form-responsive">
                <Grid
                  container
                  rowSpacing={2}
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item width="100%" sx={{ paddingTop: "1rem" }}>
                        <Controller
                          name={"country"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.country
                                  ? "select-contact__filters__errors"
                                  : isFieldFilled("country")
                                  ? "filters"
                                  : "filters__not-filled"
                              }
                              options={countryList}
                              getOptionLabel={(option) => option.countryName}
                              isOptionEqualToValue={(option, value) => {
                                return option.country == value.country;
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    label={t("App.listUsers.country")}
                                    {...params}
                                    sx={{
                                      label: {
                                        height: "100% !important",
                                        width: "100% !important",
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "-5px -7px",
                                        alignItems: "center !important",
                                        "&.Mui-focused": {
                                          color: "#8C8D9D !important",
                                        },
                                      },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loading ? (
                                            <CircularProgress
                                              sx={{ color: "#4DCAFA" }}
                                              size={15}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeCountry(values, reason);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item width="100%" sx={{ paddingTop: "1rem" }}>
                        <Controller
                          name={"typeCompany"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              noOptionsText={t("App.listUsers.noOptions")}
                              disabled={disableType()}
                              className={
                                errors.typeCompany
                                  ? "select-contact__filters__errors"
                                  : enableTypeCompany
                                  ? isFieldFilled("typeCompany")
                                    ? "filters"
                                    : "filters__not-filled"
                                  : "filters__disabled"
                              }
                              options={typeComps}
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) => {
                                return option.value === value.value;
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    label={t("App.listUsers.typeCompany") + "*"}
                                    {...params}
                                    sx={{
                                      label: {
                                        height: "100% !important",
                                        width: "100% !important",
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "-5px -7px",
                                        alignItems: "center !important",
                                        "&.Mui-focused": {
                                          color: "#8C8D9D !important",
                                        },
                                      },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loading ? (
                                            <CircularProgress
                                              sx={{ color: "#4DCAFA" }}
                                              size={15}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeTypeCompany(values);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      width="100%"
                    >
                      <Grid item width="100%" sx={{ paddingTop: "1rem" }}>
                        <Controller
                          control={control}
                          name={"bussiness"}
                          defaultValue={[]}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                              disabled={disableFields()}
                              className={
                                errors.bussiness
                                  ? "select-contact__filters__errors"
                                  : enabledFields
                                  ? isFieldFilled("bussiness")
                                    ? "filters"
                                    : "filters__not-filled"
                                  : "filters__disabled"
                              }
                              disableCloseOnSelect
                              options={companyList}
                              multiple
                              limitTags={2}
                              value={value}
                              isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.value + ""}>
                                  <Checkbox
                                    icon={icon}
                                    className="autoComplete__checkbox"
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />

                                  {`${option.label}`}
                                </li>
                              )}
                              onChange={(event, values, reason, detail) => {
                                onChange(values);
                                handleCompaniesChange(values, reason, detail);
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    label={t("App.listUsers.company")}
                                    sx={{
                                      label: {
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "0px -5px",
                                        transformOrigin: "-45px -10px",
                                        "&.Mui-focused": {
                                          color: "#8C8D9D",
                                        },
                                      },
                                    }}
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoadingFields ? (
                                            <CircularProgress
                                              sx={{ color: "#4DCAFA" }}
                                              size={15}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              renderTags={(value, getTagProps) => {
                                const limitTags = 2;
                                const withoutSelectAll = value.filter(
                                  (item) =>
                                    item.value !==
                                    t("App.downloadSalesForce.selectAll")
                                );
                                const numTags = withoutSelectAll.length;
                                return (
                                  <>
                                    {withoutSelectAll
                                      .slice(0, limitTags)
                                      .map((option, index) => (
                                        <Chip
                                          className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                          sx={{
                                            width: "25%",
                                          }}
                                          key={index}
                                          size="small"
                                          label={`${option.label}`}
                                        />
                                      ))}
                                    {numTags > limitTags &&
                                      ` +${numTags - limitTags}`}
                                  </>
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={4} lg={4} md={3.8} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item width="100%" sx={{ paddingTop: "1rem" }}>
                        <Controller
                          name={"ean"}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              disabled={disableEan()}
                              noOptionsText={t("App.listUsers.noOptions")}
                              className={
                                errors.ean
                                  ? "select-contact__filters__errors"
                                  : enabledEan
                                  ? isFieldFilled("ean")
                                    ? "filters"
                                    : "filters__not-filled"
                                  : "filters__disabled"
                              }
                              filterOptions={(options) => {
                                if (value?.length >= 3) {
                                  const filt = options.filter((ean) => {
                                    if (ean.toString().includes(value)) {
                                      return ean;
                                    }
                                  });
                                  return filt;
                                } else {
                                  return eanList;
                                }
                              }}
                              options={eanList}
                              onInputChange={(event, value, reason) => {
                                if (reason === "clear") {
                                  resetValuesWhenClear();
                                }
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    label={t("App.listUsers.ean")}
                                    sx={{
                                      label: {
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "0px -5px",
                                        transformOrigin: "-45px -10px",
                                        "&.Mui-focused": {
                                          color: "#8C8D9D !important",
                                        },
                                      },
                                    }}
                                    {...params}
                                    onChange={onChange}
                                  />
                                );
                              }}
                              onChange={(event, values, reason) => {
                                onChange(values);
                                handleChangeEan(values);
                              }}
                              value={value || null}
                            />
                          )}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={4} lg={4} md={3.8} sm={12} xs={12}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item width="100%" sx={{ paddingTop: "1rem" }}>
                        <Controller
                          control={control}
                          name={"app"}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                              disabled={disableFields()}
                              className={
                                errors.app
                                  ? "select-contact__filters__errors"
                                  : enabledFields
                                  ? isFieldFilled("app")
                                    ? "filters"
                                    : "filters__not-filled"
                                  : "filters__disabled"
                              }
                              disableCloseOnSelect
                              options={appOptions}
                              multiple
                              limitTags={2}
                              value={value}
                              isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                              }
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.value + ""}>
                                  <Checkbox
                                    icon={icon}
                                    className="autoComplete__checkbox"
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />

                                  {`${option.label}`}
                                </li>
                              )}
                              onChange={(event, values, reason, detail) => {
                                onChange(values);
                                handleAppChange(values, reason, detail);
                              }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    label={t("App.BILayers.filter.app")}
                                    sx={{
                                      label: {
                                        fontFamily: "Roboto !important",
                                        fontSize: "14px !important",
                                        translate: "0px -5px",
                                        transformOrigin: "-45px -10px",
                                        "&.Mui-focused": {
                                          color: "#8C8D9D",
                                        },
                                      },
                                    }}
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoadingFields ? (
                                            <CircularProgress
                                              sx={{ color: "#4DCAFA" }}
                                              size={15}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                );
                              }}
                              renderTags={(value, getTagProps) => {
                                const limitTags = 2;
                                const withoutSelectAll = value.filter(
                                  (item) =>
                                    item.value !==
                                    t("App.downloadSalesForce.selectAll")
                                );
                                const numTags = withoutSelectAll.length;
                                return (
                                  <>
                                    {withoutSelectAll
                                      .slice(0, limitTags)
                                      .map((option, index) => (
                                        <Chip
                                          className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium"
                                          sx={{
                                            width: "25%",
                                          }}
                                          key={index}
                                          size="small"
                                          label={`${option.label}`}
                                        />
                                      ))}
                                    {numTags > limitTags &&
                                      ` +${numTags - limitTags}`}
                                  </>
                                );
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Grid xs={12} xl={3} lg={3.5} md={3.5} sm={4} item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="buttonfilt-container"
          gap={2}
        >
          <Grid item className="dimentions-btn__applyFilter__container">
            <Button
              sx={{
                width: "100%",
              }}
              startIcon={
                <TuneOutlinedIcon
                  className={
                    !disableApplyFiltersBtn()
                      ? "btn__applyFilter-reload__icon"
                      : "btn__applyFilter-reload__icon-disabled"
                  }
                />
              }
              disableRipple
              disabled={disableApplyFiltersBtn()}
              className={`${
                disableApplyFiltersBtn()
                  ? "btn__applyFilter-reload-disabled"
                  : "btn__applyFilter"
              } dimentions-btn__applyFilter`}
              type="submit"
              form="hook-form"
            >
              {t("App.listUsers.applyFilters")}
            </Button>
          </Grid>

          <Grid item className="dimentions-btn__applyFilter__container">
            <Button
              className={"btn__deleteFilter dimentions-btn__cleanFilter"}
              onClick={cleanFilters}
            >
              {t("App.listUsers.cleanFilters")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

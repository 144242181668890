import { Grid } from "@mui/material";
import { t } from "i18next";
import React from "react";

export const NoSelectionComponent = (props) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="u-marginB u-marginT"
      spacing={2}
    >
      <Grid item className="text-center">
        <img
          className={
            props.type === "deleteAndReload" ? "img__noInfoDR" : "img__noInfo"
          }
          src={`/assets/components/noSelection.svg`}
        />
      </Grid>
      <Grid item className="text-center">
        {props.type === "salesForce" ? (
          <p className="display-large__primary-one text-center">
            {t("App.previewSalesForce.noSalesForce")}
          </p>
        ) : props.type === "deleteAndReload" ||
          props.type === "deleteAndReloadRequest" ||
          props.type === "adminCompanies" ||
          props.type === "specialFiles" ||
          props.type === "buesinessRules" ||
          props.type === "contacts" ||
          props.type === "purchaseOrder" ||
          props.type === "dynamic" ||
          props.type === "business-group" ||
          props.type === "BILayersRequests" ||
          props.type === "adminBILayers" ||
          props.type === "editMonths" ? (
          <p className="noSelectionTitle__primary-one text-center">
            {t("App.deleteAndReload.noSelection")}
          </p>
        ) : props.type === "sendAlly" ? (
          <p className="display-large__primary-one text-center">
            {t("App.adminSendAllies.NoSelection")}
          </p>
        ) : (
          <p className="display-large__primary-one text-center">
            {t("App.catalogue.previewModal.noCatalogueTitle")}
          </p>
        )}
      </Grid>

      <Grid item>
        {props.type === "salesForce" ? (
          <p className="subheading__text-grey text-center">
            {t("App.previewSalesForce.noSalesForceTitle")}
          </p>
        ) : props.type === "deleteAndReload" ? (
          <p className="subheading__text-grey text-center">
            {t("App.deleteAndReload.noSelectionText")}
          </p>
        ) : props.type === "deleteAndReloadRequest" ? (
          <p className="subheading__text-grey text-center">
            {t("App.applicationDeleteAndReload.noSelectionText")}
          </p>
        ) : props.type === "adminCompanies" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminCompanies.noSelect")}
          </p>
        ) : props.type === "specialFiles" || props.type === "buesinessRules" ? (
          <p className="subheading__text-grey text-center">
            {t("App.specialFilesCTyS.noSelect")}
          </p>
        ) : props.type === "contacts" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminContacts.noContent")}
          </p>
        ) : props.type === "business-group" ? (
          <p className="subheading__text-grey text-center">
            {t("App.manageBusinessGroup.noContent")}
          </p>
        ) : props.type === "sendAlly" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminSendAllies.noSelection")}
          </p>
        ) : props.type === "purchaseOrder" ? (
          <p className="subheading__text-grey text-center">
            {t("App.adminPurchaseOrder.noSelectionComponent")}
          </p>
        ) : props.type === "dynamic" ? (
          <p className="subheading__text-grey text-center">{props.text}</p>
        ) : props.type === "BILayersRequests" ? (
          <p className="noSelectionText__text-grey text-center">
            {t("App.BILayers.request.noSelectionText")}
          </p>
        ) : props.type === "adminBILayers" ? (
          <p className="noSelectionText__text-grey text-center">
            {t("App.BILayers.noSelectionText")}
          </p>
        ) : props.type === "editMonths" ? (
          <p className="noSelectionText__text-grey text-center">
            {t("App.BILayers.editMonths.filter.noSelection")}
          </p>
        ) : (
          <p className="subheading__text-grey text-center">
            {t("App.catalogue.previewModal.noCatalogueText")}
          </p>
        )}
      </Grid>
    </Grid>
  );
};
